import { toJS } from 'mobx';

enum NotificationStatus {
  READ = 'read',
  FETCHED = 'fetched',
  RECIEVED = 'received',
}

enum Status {
  UNREAD,
  READ,
}

const toggleRecieved = (collection, id) => {
  const isSame = (item, id) => item.notification.notification_id === id;

  return toJS(collection).map(item =>
    isSame ? { ...item, read: Status.READ } : item,
  );
};

export const getNotifications = ({ api, path }) => {
  return api
    .get('/notifications')
    .then(response => path.success({ notifications: response }));
};

export const getLatestNotifications = ({ api, path }) => {
  return api
    .get('/notifications/latest')
    .then(response => path.success({ notifications: response }));
};

export const updateStatus = ({ api, path, props, state }) => {
  const allNotifications = state.get('notifications.data.latest.items');
  const id = allNotifications[props.idx].notification.notification_id;

  return api
    .put(`/user/update-notification-status`, {
      notification_action: NotificationStatus.READ,
      notification_id: id,
    })
    .then(_response =>
      state.set(
        'notifications.data.items',
        toggleRecieved(allNotifications, id),
      ),
    );
};
