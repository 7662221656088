import { ILogConfig, Logger, LogLevel } from '@internal/logger';
import * as Sentry from '@sentry/browser';

import Analytics from './analytics';
import VERSION from './version';

const __DEV__ = process.env.NODE_ENV === 'development';

// NOTE(zvrk): init sentry only in production
if (!__DEV__) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
  });
}

const gaTransport = ({ msg, level, extra }): void => {
  if (level === LogLevel.INFO) {
    Analytics.track(msg, extra);
  }
};

const ravenTransport = ({ msg, level, extra }): void => {
  if (level === LogLevel.ERROR) {
    if (extra) {
      Sentry.withScope(scope => {
        Object.keys(extra).forEach(key => {
          scope.setExtra(key, extra[key]);
        });
      });
    }
    Sentry.captureException(msg);
  }
};

const config: ILogConfig = {
  transports: [ravenTransport, gaTransport],
  globalPrefix: __DEV__ ? '' : VERSION,
  state: {
    console: __DEV__,
    transports: !__DEV__,
  },
};

const logger = Logger(config);

export default logger;
