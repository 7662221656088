import Thumb from '@app/assets/images/ddrop_3.svg';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import cs from 'classnames';
import { Grid, GridProps, Typography } from 'components/atoms';
import ArrowDown from 'mdi-material-ui/ArrowDown';
import ArrowUp from 'mdi-material-ui/ArrowUp';
import * as React from 'react';

const PINK = '#EE7785';

const styles = (theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 'bold',
    },
    icon: {
      margin: `${theme.spacing.unit}px 0`,
    },
    white: {
      color: theme.palette.common.white,
    },
    remove: {
      backgroundColor: PINK,
    },
    add: {
      backgroundColor: theme.palette.primary.main,
    },
    base: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[4],
      pointerEvents: 'none',
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

export enum DragAction {
  ADD,
  REMOVE,
}

interface DragToAction extends StyledComponent {}

type Props = DragToAction & WithStyles<typeof styles> & Partial<GridProps>;

export const DragToAction = (action: DragAction): React.ComponentType => {
  class DragImage extends React.PureComponent<Props> {
    private get isAddAction(): boolean {
      return action === DragAction.ADD;
    }

    private get isRemoveAction(): boolean {
      return action === DragAction.REMOVE;
    }

    private arrow(action: DragAction): JSX.Element {
      const { classes } = this.props;

      switch (action) {
        case DragAction.ADD: {
          return <ArrowUp className={cs(classes.white, classes.icon)} />;
        }
        case DragAction.REMOVE: {
          return <ArrowDown className={cs(classes.white, classes.icon)} />;
        }
        default: {
          throw new Error('Unknown DragAction');
        }
      }
    }

    private get up(): JSX.Element {
      if (this.isAddAction) {
        return this.arrow(DragAction.ADD);
      }

      return null;
    }

    private get down(): JSX.Element {
      if (this.isRemoveAction) {
        return this.arrow(DragAction.REMOVE);
      }

      return null;
    }

    public render(): JSX.Element {
      const { className, style, classes, children, ...rest } = this.props;

      const root = cs(classes.base, {
        [className]: className,
        [classes.add]: this.isAddAction,
        [classes.remove]: this.isRemoveAction,
      });

      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={root}
          style={style}
          {...rest}
        >
          {this.up}
          <Thumb className={cs(classes.icon, classes.text)} />
          <Typography variant="h6" className={classes.white}>
            {children}
          </Typography>
          {this.down}
        </Grid>
      );
    }
  }

  return withStyles(styles)(DragImage);
};
