import LeafIcon from "components/atoms/icon/leaf";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Grid, Typography } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) => {
  const icon = {
    overflow: 'visible',
    height: '1em',
    verticalAlign: '-.125em',
    strokeWidth: 2,
  };

  return createStyles({
    container: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing.unit * 2,
      maxWidth: 40,
    },
    iconLeaf: {
      ...icon,
      fontSize: "12px",
      marginLeft: 4,
    }
  });
};

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}
interface RatingStarProps extends StyledComponent {
  rating: number;
}

type Props = WithStyles<typeof styles> & RatingStarProps;

class RatingStar extends React.Component<Props> {
  public render(): JSX.Element {
    const { classes, className, style, rating } = this.props;

    const container = cs(classes.container, {
      [className]: className,
    });

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        classes={{ container }}
        style={style}
      >
        <Typography>{rating}</Typography>
        <LeafIcon className={classes.iconLeaf} />
      </Grid>
    );
  }
}

export default withStyles(styles)(RatingStar);
