import compose from "ramda/src/compose";
import tap from "ramda/src/tap";
import { FormikActions } from "formik";

/**
 * @private
 * Set `Formik` submitting flag to false.
 * Used after `success` or `fail`.
 */
const stopSubmitting = <T>(bag: FormikActions<T>) => (): void => {
  bag.setSubmitting(false);
};

/**
 * @private
 * Map `API Errors` to `Formik` field errors
 */
const setErrors = <T>(bag: FormikActions<T>) => error => {
  console.log({ error });
  const { data } = error.response;

  const errs = data.reduce((acc, curr) => {
    acc[curr.field] = curr.translationKey;
    return acc;
  }, {});

  bag.setErrors(errs);
  bag.setSubmitting(false);
};

/**
 * Run sideffects with Ramda `tap`. In this case
 * set `Formik` form errors. This can be generalized
 * by accepting function that will tap into error.
 * `(e: error) => void`
 */
export const handleError = <T>(bag: FormikActions<T>, fn: (a: any) => any) =>
  compose(fn, tap(setErrors(bag)));

/**
 * Run sideffects with Ramda `tap`. In this case
 * set `Formik` submit flag to `false`.
 */
export const handleSuccess = <T>(bag: FormikActions<T>, fn: (a: any) => any) =>
  compose(fn, tap(stopSubmitting(bag)));
