import { BranchContext, Context } from '@app/store/fluent';
import { handleError, handleSuccess } from '@gardenize/forms';
import { IUser } from '@gardenize/models';

import * as factories from '../../factories';

import { PropfileBranchProps, UserBranchProps, UserProps } from './';

const toggle = (state, path) => state.set(path, !state.get(path));

export const getProfile = ({
  api,
  state,
  path,
}: BranchContext<PropfileBranchProps>) => {
  const id = state.get('user.id');
  return api
    .get(`/profile/${id}`)
    .then(response => path.success({ profile: response }))
    .catch(error => path.error({ error }));
};

export const uploadCover = ({ api, props, path }) => {
  return api
    .post('/user/upload-cover-image', {
      image: props.file,
    })
    .then(response =>
      path.success({ media: response.media, msg: response.result }),
    )
    .catch(error => path.error({ error }));
};

export const uploadAvatar = ({ api, props, path }) => {
  return api
    .post('/user/upload-profile-image', {
      image: props.file,
    })
    .then(response =>
      path.success({ media: response.media, msg: response.result }),
    )
    .catch(error => path.error({ error }));
};

export const updateUser = ({
  api,
  props,
  state,
  path,
}: BranchContext<UserBranchProps, UserProps>) => {
  const { bag, data } = props;

  const user = state.get('user');
  const updatedProfile = { ...user.profile, ...data };

  return api
    .post('/user/update', { ...user, ...updatedProfile })
    .then(
      handleSuccess(bag, response =>
        path.success({ user: response, profile: response.profile }),
      ),
    )
    .catch(handleError(bag, error => path.error({ error })));
};

export const updatePassword = ({ api, props, path }) => {
  const { data } = props;

  return api
    .post('/user/change-password', data)
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

// NOTE(zvrk): whole store architecture and structure sucks.
// This could be used for generic user update
export const updateCountry = ({ api, props, state, path }) => {
  const user = state.get('user');

  return api
    .post('/user/update', { ...user, ...props })
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

export const updateUserSuccess = ({
  state,
  props,
}: Context<{ user: IUser }>) => {
  state.set('user', props.user);
  factories.notifications(state, 'Profile updated', 'success');
  toggle(state, 'profile.ui.edit');
};

export const updateUserError = ({ state }) => {
  factories.notifications(state, 'Failed', 'error');
  toggle(state, 'profile.ui.edit');
};

export const updateLanguage = ({
  api,
  props,
  state,
}: Context<{ data: { language: string } }>) => {
  const profile = state.get('user.profile');

  return api
    .post('/user/update', { ...profile, language: props.data.language })
    .then(response => {
      state.set('user', response);
    });
};
