import { BranchContext, Context } from '@app/store/fluent';
import { ENTITIES_PER_CARD as per_page } from '@gardenize/config';
import { IEvent } from '@gardenize/models';
import getUnixTime from 'date-fns/getUnixTime';
import { toJS } from 'mobx';
import * as qs from 'qs';

const queryify = params =>
  qs.stringify(params, { arrayFormat: 'brackets', encode: false });

const setter = (path: string) => (val: any) => ({ state }: Context): void => {
  state.set(path, val);
};

export const setEventDialog = setter('events.ui.dialogs.event');
export const setMonths = setter('events.ui.selected.months');
export const setYear = setter('events.ui.selected.year');

export const getUpcomingEvents = ({ api, props, state, path }) => {
  const month = state.get('events.ui.selected.months');
  const year = state.get('events.ui.selected.year');
  const page = state.get('events.ui.page.upcoming');
  const name = props.q || state.get('events.ui.query');

  const params = {
    month,
    year,
    page,
    per_page: props.per_page || per_page,
    ...props.upcoming,
  };

  if (name) {
    params.name = name;
  }

  const query = queryify(params);

  return api
    .get(`/event/upcoming?${query}`)
    .then(response => path.success({ upcoming: response }));
};

export const getPastEvents = ({ api, props, path, state }) => {
  const month = state.get('events.ui.selected.months');
  const year = state.get('events.ui.selected.year');
  const page = state.get('events.ui.page.past');
  const name = props.q || state.get('events.ui.query');

  const params = {
    month,
    year,
    page,
    per_page: props.per_page || per_page,
    ...props.past,
  }

  if (name) {
    params.name = name;
  }

  const query = queryify(params);

  return api
    .get(`/event/past?${query}`)
    .then(response => path.success({ past: response }));
};

export const getEventsPerYear = ({ api, path, props, state }) => {
  const query: any = {};

  const name = props.q || state.get('events.ui.query');

  if (name) {
    query.name = name;
  }

  return api
    .get('/event/yearly-overview', query)
    .then(response => path.success({ years: response }))
    .catch(error => path.error({ error }));
};

export const createEvent = ({
  props,
  api,
  path,
}: BranchContext<Response, { data: Partial<IEvent> }>): Promise<{
  response: IEvent;
}> => {
  return api
    .post('/event', { ...props.data })
    .then(response => path.success({ response }));
};

export const create = ({ api, path, props }) => {
  const name = props.name || 'New Event';
  const data = props.data || {
    date_timestamp: getUnixTime(new Date()),
  };

  return api
    .post('/event', { ...data, name })
    .then(response => path.success({ event: response }));
};

export const navigateToEvent = ({ props, router }) => {
  router.navigate(`/events/${props.event.id}`);
};

export const enter = ({ state }) => {
  const upcoming = toJS(state.get('events.search.upcoming.items'));
  const upcomingMeta = toJS(state.get('events.search.upcoming._meta'));

  const past = toJS(state.get('events.search.past.items'));
  const pastMeta = toJS(state.get('events.search.past._meta'));

  state.set('events.data.upcoming.items', upcoming);
  state.set('events.data.upcoming._meta', upcomingMeta);
  state.set('events.data.past.items', past);
  state.set('events.data.past._meta', pastMeta);
};
