import { ActivityType } from '@gardenize/models';
import { types } from 'mobx-state-tree';

const EditableField = types.model({
  value: types.string,
  editTempValue: types.string,
  isToggled: types.boolean,
});

export default {
  ui: types.model({
    loading: types.boolean,
    new: types.model({
      value: types.string,
      saved: types.boolean,
    }),
    edit: types.map(EditableField),
  }),
  data: types.model({
    items: types.maybeNull(types.array(ActivityType)),
  }),
};
