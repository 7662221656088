import { IPlant } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import * as React from 'react';

import { Overlay } from './';
import { IOverlay } from './types';

const plantAdapter = (plant: IPlant | undefined): IOverlay => {
  if (!plant) {
    return;
  }

  return {
    title: plant.name,
    subtitle: plant.latin_name,
    info: plant.variety,
  };
};

const styles = (_theme: Theme) =>
  createStyles({
    hoverInfoOverlay: {},
  });

interface PlantOverlayProps {
  plant: IPlant;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

type Props = WithStyles<typeof styles> & PlantOverlayProps & StyledComponent;

class PlantOverlay extends React.Component<Props> {
  public render(): JSX.Element {
    const { plant, classes, className } = this.props;

    const root = cs(classes.hoverInfoOverlay, {
      [className]: className,
    });

    return <Overlay className={root} data={plantAdapter(plant)} />;
  }
}

export default withStyles(styles)(PlantOverlay);
