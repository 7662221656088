import { StoreContext } from '@app/store';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'components/atoms';
import { inject } from 'mobx-react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (_theme: Theme) => createStyles({});

export interface PaymentErrorDialogProps {
  open: boolean;
  onClose?: (a: any[] | any) => any;
}

type Props = WithStyles<typeof styles> &
  StoreContext &
  WithTranslation &
  PaymentErrorDialogProps;

class PaymentErrorDialog extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      t,
      open,
      onClose,
      store: {
        ui: { paymentError },
      },
    } = this.props;

    const err = paymentError;
    let transaltedTitle = '';

    if (err) {
      let translationKey: string;
      let message: string;

      if (err.data) {
        translationKey = err.data.translationKey;
      } else if (err.details) {
        translationKey = err.details.translationKey;
        message = err.details.message;
      }

      if (translationKey) {
        transaltedTitle = t(translationKey);
        if (
          transaltedTitle === '' ||
          (transaltedTitle === translationKey && message)
        ) {
          transaltedTitle = message;
        }
      } else {
        transaltedTitle = err.message;
      }
    }

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogContent>
          <Typography>{t('payment_unavailable')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('button_ok')}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const enchance = compose(
  inject('store'),
  withTranslation(),
  withStyles(styles),
);

export default enchance(PaymentErrorDialog);
