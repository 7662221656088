import { parallel } from 'cerebral';
import { sequence } from '@app/store/fluent';
import {
  debounce,
  push,
  set,
  splice,
  toggle,
  wait,
  when,
  unshift
} from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';
import { state as initialState } from './state';

export const fetchPlants = [
  toggle(state`event.ui.loading.plants`),
  actions.getRelatedPlants,
  {
    success: [
      set(state`event.data.plants`, props`response`),
      set(state`event.ui.loading.plants`, false),
    ],
  },
];

export const fetchAreas = [
  toggle(state`event.ui.loading.areas`),
  actions.getRelatedAreas,
  {
    success: [
      set(state`event.data.areas`, props`response`),
      set(state`event.ui.loading.areas`, false),
    ],
  },
];

const fetchEvent = [
  actions.getEvent,
  {
    success: [set(state`event.data.item`, props`response`)],
  },
];

const init = parallel([fetchEvent, fetchPlants, fetchAreas]);

export const load = factories.loadApp([
  init,
  set(state`event.ui.loading.page`, false),
]);

export const unload = [set(state`event`, initialState)];

export const update = [
  set(state`event.ui.submitting`, true),
  actions.update,
  {
    success: [
      // NOTE(zvrk): see `plants.update` for explanation.
      when(state`event.ui.loading.page`),
      {
        true: [],
        false: [
          set(state`event.data.item`, props`response`),
          set(state`event.ui.submitting`, false),
        ],
      },
    ],
  },
];

export const updatePhoto = [
  set(state`event.ui.submitting`, true),
  actions.updatePhoto,
  {
    success: [actions.setPhotoByIndex, set(state`event.ui.submitting`, false)],
  },
];

export const uploadDrawnImage = [
  actions.addFile,
  actions.uploadDrawnImage,
  {
    success: [
      push(state`event.data.item.media`, props`response`),
      actions.setImageLoading,
      set(state`event.ui.activeImage`, props`image`),
    ],
  },
];

export const setActiveImage = [actions.setActiveImage];

export const toggleFavouriteImage = [
  actions.toggleFavouriteImage,
  {
    success: [actions.setImage],
  },
];

export const openDeleteImageDialog = actions.setDeleteImageDialog(true);
export const closeDeleteImageDialog = actions.setDeleteImageDialog(false);

export const openDeleteEntityDialog = actions.setDeleteEntityDialog(true);
export const closeDeleteEntityDialog = actions.setDeleteEntityDialog(false);

export const openUploadDialog = actions.setUploadDialog(true);
export const closeUploadDialog = [
  actions.setUploadDialog(false),
  set(state`plant.ui.files`, []),
];

export const openEventDialog = actions.setEventDialog(true);
export const closeEventDialog = actions.setEventDialog(false);

export const uploadImage = [
  actions.addFile,
  actions.uploadImage,
  {
    success: [
      unshift(state`event.data.item.media`, props`response`),
      actions.setImageLoading,
    ],
  },
];

export const deleteImage = [
  actions.deleteImage,
  {
    success: [
      splice(state`event.data.item.media`, props`index`, 1),
      closeDeleteImageDialog,
    ],
  },
];

export const fetchUnconnectedPlants = [actions.getUnconnectedPlants];
export const searchUnconnectedPlants = [
  set(state`event.ui.loading.unconnected.plants`, true),
  set(state`event.ui.search.plants`, props`query`),
  debounce(300),
  {
    continue: [fetchUnconnectedPlants],
    discard: [],
  },
];
export const fetchUnconnectedAreas = [actions.getUnconnectedAreas];
export const searchUnconnectedAreas = [
  set(state`event.ui.loading.unconnected.areas`, true),
  set(state`event.ui.search.areas`, props`query`),
  debounce(300),
  {
    continue: [fetchUnconnectedAreas],
    discard: [],
  },
];

export const toggleAreaPanel = [
  set(state`event.ui.panel.plant`, false),
  when(state`event.ui.panel.area`),
  {
    true: [
      toggle(state`event.ui.panel.area`),
      wait(300),
      set(state`event.ui.loading.unconnected.areas`, true),
    ],
    false: [toggle(state`event.ui.panel.area`), actions.getUnconnectedAreas],
  },
];

export const togglePlantPanel = [
  set(state`event.ui.panel.area`, false),
  when(state`event.ui.panel.plant`),
  {
    true: [
      toggle(state`event.ui.panel.plant`),
      wait(300),
      set(state`event.ui.loading.unconnected.plants`, true),
    ],
    false: [toggle(state`event.ui.panel.plant`), actions.getUnconnectedPlants],
  },
];

export const dropAreaCardImg = [
  actions.dropAreaCardImg,
  {
    success: [
      set(state`event.data.item`, props`response`),
      actions.addAreaCardHead,
      fetchAreas,
    ],
  },
];

export const dropAreaPanelImg = [
  actions.dropAreaPanelImg,
  {
    success: [
      set(state`event.data.item`, props`response`),
      actions.addAreaPanelHead,
      fetchUnconnectedAreas,
    ],
  },
];

export const dropPlantCardImg = [
  actions.dropPlantCardImg,
  {
    success: [
      set(state`event.data.item`, props`response`),
      actions.addPlantCardHead,
      fetchPlants,
    ],
  },
];

export const dropPlantPanelImg = [
  actions.dropPlantPanelImg,
  {
    success: [
      set(state`event.data.item`, props`response`),
      actions.addPlantPanelHead,
      fetchUnconnectedPlants,
    ],
  },
];

export const deleteEntity = [
  actions.deleteEntity,
  {
    success: [factories.goBack],
  },
];

export const setLightbox = [set(state`event.ui.lightbox`, props`lightbox`)];

export const edit = sequence(s =>
  s.action(({ state }) =>
    state.set('event.ui.edit', !state.get('event.ui.edit')),
  ),
);