import firebase from '@firebase/app';
import * as app from '@firebase/app-types';
import '@firebase/messaging';
import { FirebaseMessaging } from '@firebase/messaging-types';
import { Result } from 'true-myth';

const ERRORS = {
  NOT_SUPPORTED: 'Not supported!',
};

function config(): app.FirebaseOptions {
  const id = process.env.FIREBASE_ID;
  return {
    messagingSenderId: id,
  };
}

function initialize(): Result<FirebaseMessaging, { reason: string }> {
  const supported = firebase.messaging.isSupported();

  if (!supported) {
    return Result.err({ reason: ERRORS.NOT_SUPPORTED });
  }

  try {
    const app = firebase.initializeApp(config());
    navigator.serviceWorker.register('/sw.js').then(registration => {
      messaging.useServiceWorker(registration);
      messaging.usePublicVapidKey(
        'BNcDjgkhcbx_K-mVphjGaGQnC0CMni5PlGVaJ8pfcK0IHz8Pm0S_f9sDMS0a7yQ7CXt-RiYDr33NCDOiOAWaffc',
      );
    });

    const messaging = firebase.messaging(app);

    return Result.ok(messaging);
  } catch (e) {
    Result.err({ reason: e });
  }
}

export default initialize();
