import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Grid } from 'components/atoms';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '100%',
      width: '100%',
      padding: theme.spacing.unit,
      color: theme.palette.common.white,
      cursor: 'pointer',
      zIndex: 2
    },
    hoverEditOverlay: {},
    button: {
      height: 30,
      width: 40,
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface ButtonOverlayProps extends StyledComponent {
  link: string;
}

type Props = WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps &
  ButtonOverlayProps;

class EditOverlay extends React.Component<Props> {
  private handleClick = (_e: React.MouseEvent<HTMLDivElement>): void => {
    const { history, link } = this.props;
    history.push(link);
  };

  public render(): JSX.Element {
    const { t, classes, className } = this.props;

    const root = cs(classes.container, classes.hoverEditOverlay, {
      [className]: className,
    });

    return (
      <Grid
        container
        classes={{ container: root }}
        alignItems="center"
        justify="center"
        onClick={this.handleClick}
      />
    );
  }
}

const enhance = compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
);

export default enhance(EditOverlay);
