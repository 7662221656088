import { sequenceWithProps } from '@app/store/fluent';
import { debounce, set, wait, when } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';
import * as globalActions from '../../actions';

import { state as initialState } from './state';

export const load = factories.loadApp([
  when(props`q`),
  {
    true: [set(state`plants.ui.searchTerm`, props`q`)],
    false: [set(state`plants.ui.searchTerm`, '')],
  },
  // NOTE(zvrk): this flag is true only on initial load,
  // so if it's not an initial load, we clear state first
  // then load new, this is used when user searches something
  // and presses `enter`
  when(state`plants.ui.loading`),
  {
    true: [],
    false: [
      set(state`plants.ui.loading`, true),
      // NOTE(zvrk): for improved visuals
      wait(100),
      set(state`plants.data.items`, {}),
    ],
  },
  actions.plants,
  {
    success: [
      set(state`plants.data.total`, props`total`),
      set(state`plants.data.featured`, props`items`),
    ],
  },
  set(props`query`, { startIndex: 0, stopIndex: 5 }),
  set(props`page`, 1),
  actions.fetchMoreGrid,
  {
    success: [
      set(state`plants.data.items.${props`page`}`, props`items`),
      set(state`plants.data.loaded`, props`page`),
    ],
  },
  wait(200),
  set(state`plants.ui.loading`, false),
]);

export const unload = [set(state`plants`, initialState), actions.unload];

export const setMode = sequenceWithProps<{ mode: number }>(s =>
  s.action(actions.mode),
);

export const fetch = [
  actions.fetchMore,
  {
    success: [
      set(state`plants.data.items.${props`page`}`, props`items`),
      set(state`plants.data.loaded`, props`page`),
    ],
  },
];

export const fetchGrid = [
  actions.fetchMoreGrid,
  {
    success: [
      set(state`plants.data.items.${props`page`}`, props`items`),
      set(state`plants.data.loaded`, props`page`),
    ],
  },
];

export const search = [
  set(state`plants.ui.searching`, true),
  debounce(200),
  {
    continue: [
      actions.plantList,
      {
        success: [set(state`plants.search.items`, props`items`)],
      },
    ],
    discard: [],
  },
  set(state`plants.ui.searching`, false),
];

export const create = [
  when(state`user.premium.active`),
  {
    true: [
      actions.createPlant,
      {
        success: [actions.navigateToPlant],
      },
    ],
    false: [globalActions.redirectToPayments],
  },
];

export const createWithImage = [
  set(state`plants.ui.uploading`, true),
  actions.createPlant,
  {
    success: [
      actions.createImage,
      {
        success: [
          set(state`plants.ui.uploading`, false),
          actions.navigateToPlant,
        ],
      },
    ],
  },
];
