import * as React from 'react';

const Bell = ({ className, color = "#42281F", isOutlined = true  }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="bell-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={isOutlined ? "none" : color}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    id="bell-svg"
  >
    <title id="bell-icon">Bell</title>
    <path d="M12.2 2H11.9C7.9 2 5.4 3.1 4.7 7.3L3 18.5H19" stroke={color} />
    <path d="M5 18.5H21L19.2 7.3C18.5 3.2 16 2 12 2H11.7" stroke={color} />
    <path d="M15 19C15 20.6 13.7 22 12 22C10.3 22 9 20.7 9 19" stroke={color} />
  </svg>
);

export default Bell;
