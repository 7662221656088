import { Module as CerebralModule } from '@cerebral/fluent';

import model from './model';
import * as sequences from './sequences';
import { state, State } from './state';

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  unload: sequences.unload,
  fetch: sequences.fetch,

  cancelSubscription: sequences.cancelSubscription,
};

export const Module = CerebralModule<State, Signals>({
  model,
  state,
  signals,
});
