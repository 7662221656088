import { domain } from "@gardenize/config";
import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required(),
  date_timestamp: yup.date().nullable(true),
  photo_date: yup.date().nullable(true),
  ph: yup.mixed().oneOf(domain.ph),
  soil: yup.mixed().oneOf(domain.soil),
  ligth: yup.mixed().oneOf(domain.light),
  size: yup.number(),
  type: yup.number().nullable(true)
});
