import { withFormik } from "formik";
import getUnixTime from "date-fns/getUnixTime";

import { schema } from "./validators";
import { Props, Values } from "./types";

const initial: Values = {
  cost: "",
  size: "",
  quantity: "",
  weight: "",
  reminder: false,
  activity_id: "",
  reminder_timestamp: null,
  date_timestamp: new Date(),
  time_used: "",
  note: ""
};

/**
 * Converts given timestamp to date object.
 */
const getReminderTimestamp = (timestamp: Date | null) => {
  return timestamp ? getUnixTime(timestamp) : null;
};

export const withAddEvent = withFormik<Props, Values>({
  validationSchema: schema,
  handleSubmit: (
    values: Values,
    { resetForm, setSubmitting, props: { onSubmit } }
  ) => {
    const data = {
      ...values,
      cost: String(values.cost),
      size: String(values.size),
      quantity: String(values.quantity),
      weight: String(values.weight),
      time_used: String(values.time_used),
      date_timestamp: getUnixTime(+values.date_timestamp),
      reminder_timestamp: getReminderTimestamp(values.reminder_timestamp)
    };

    onSubmit({ data });
    setSubmitting(false);
    resetForm();
  },
  mapPropsToValues: (_props: Props): Values => {
    return initial;
  }
});
