import { TERMS_AND_CONDITIONS } from '@gardenize/config';
import { Form, withRegister } from '@gardenize/forms';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import cs from 'classnames';
import { Grid, SubmitButton } from 'components/atoms';
import { observer } from 'mobx-react';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { Checkbox, CountryField, EmailField, PasswordField } from '../elements';

const styles = (theme: Theme) => {
  const {
    breakpoints: { down, up, values },
    spacing: { unit },
  } = theme;

  return createStyles({
    button: {
      height: 56,
      width: '100%',
    },
    input: {
      width: ' 100%',
      margin: `${unit}px 0`,
    },
    checkboxes: {
      margin: `${theme.spacing.unit * 2.5}px 0`,
    },
    autocomplete: {
      marginTop: unit,
    },
    register: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '90%',
      paddingBottom: 70,

      [down(values.md)]: {
        width: '100%',
      },
    },
  });
};

interface StyledComponent {
  className: string;
  style?: React.CSSProperties;
}

interface RegisterFormProps extends StyledComponent {
  lang: string;
}

type Props = WithTranslation & WithStyles<typeof styles> & RegisterFormProps;

class Register extends React.Component<Props> {
  private handleChange = (country: string): void => {
    const { values, setValues } = this.props;

    setValues({ ...values, country });
  };

  /**
   * We have to override default label presentation, since
   * the label should link to external terms and conditions.
   */
  private get termsAndConditions(): { Label: any } {
    const { t, lang } = this.props;

    return {
      Label: {
        label: (
          <a
            href={TERMS_AND_CONDITIONS(lang)}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '11px',
              lineHeight: '12px',
              textTransform: 'uppercase',
              color: '#50B271',
              textDecoration: 'none',
              width: '175%',
              display: 'inline-block',
            }}
          >
            {t('accept_terms_and_conditions')}
          </a>
        ),
      },
    };
  }

  private get newsletter(): { Label: any } {
    const { t, lang } = this.props;

    return {
      Label: {
        label: (
          <a
            href={TERMS_AND_CONDITIONS(lang)}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '11px',
              lineHeight: '12px',
              textTransform: 'uppercase',
              color: '#42281F',
              width: '175%',
              display: 'inline-block',
              textDecoration: 'none',
            }}
          >
            {t('recieve_newsletter')}
          </a>
        ),
      },
    };
  }

  public render(): JSX.Element {
    const { t, i18n, tReady, ...other } = this.props;
    const { classes, className, style } = other;

    const root = cs(classes.register, {
      [className]: className,
    });

    return (
      <Form className={root} style={style}>
        <EmailField
          name="email"
          label={t('profile_email')}
          className={classes.input}
          data-testid="register-email"
        />
        <PasswordField
          name="password"
          label={t('login_password')}
          className={classes.input}
          data-testid="register-password"
        />
        <PasswordField
          name="repeat_password"
          label={t('repeat_password')}
          className={classes.input}
          data-testid="register-password"
        />
        <CountryField
          name="country"
          label={t('profile_country')}
          className={classes.autocomplete}
          onChange={this.handleChange}
        />
        <div
          style={{
            width: '100%',
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Checkbox name="toc" style={{}} {...this.termsAndConditions} />
          <Checkbox name="newsletter" style={{}} {...this.newsletter} />
        </div>
        <SubmitButton className={classes.button} data-testid="register-btn">
          {t('button_sign_up')}
        </SubmitButton>
      </Form>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withRegister,
  withStyles(styles),
  observer,
);

export default enhance(Register);
