import { Module as CerebralModule } from '@cerebral/fluent';

import * as getters from './computed';
import model from './model';
import * as sequences from './sequences';
import { State, state } from './state';

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  unload: sequences.unload,

  setPlan: sequences.setPlan,
  setPlanPeriod: sequences.setPlanPeriod,
  setCoupon: sequences.setCoupon,
  validateCoupon: sequences.validateCoupon,
  setCurrency: sequences.setCurrency,

  charge: sequences.charge,
  changePaymentCard: sequences.changePaymentCard,
};

export const Module = CerebralModule<State, Signals>({
  model,
  getters,
  state,
  signals,
});
