import * as React from "react";
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps
} from "@material-ui/core/Checkbox";
import { FieldProps, getIn } from "formik";
import { Omit } from "./types";

export interface CheckboxProps
  extends FieldProps,
    Omit<
      MuiCheckboxProps,
      "form" | "checked" | "defaultChecked" | "name" | "onChange" | "value"
    > {}

export const fieldToCheckbox = ({
  field,
  form: { isSubmitting, errors, touched },
  disabled = false,
  style,
  ...props
}: CheckboxProps): MuiCheckboxProps => {
  const error = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!error

  const errorify = (error, style: React.CSSProperties): React.CSSProperties => {
    if (error) {
      return { ...style, color: "red" };
    }

    return style;
  };

  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    // TODO handle indeterminate
    checked: field.value ? "checked" : "",
    value: field.value ? "checked" : "",
    style: errorify(showError, style)
  };
};

export const Checkbox: React.ComponentType<CheckboxProps> = (
  props: CheckboxProps
) => <MuiCheckbox {...fieldToCheckbox(props)} />;

Checkbox.displayName = "FormikMaterialUICheckbox";
