import { Computed, ComputedValue } from '@cerebral/fluent';
import { IPaymentOption, ICouponDetails } from '@gardenize/models';

import * as computed from './computed';

export type State = {
  ui: {
    loading: boolean;
    currency: string;
    coupon: string;
    error: string;
    plan: string;
    planPeriod: string;
    couponDetails: ICouponDetails[];
    shouldValidateCoupon: boolean;
  };
  data: {
    options: IPaymentOption[];
  };
  options: ComputedValue<IPaymentOption[]>;
};

export const state: State = {
  ui: {
    loading: false,
    currency: 'eur',
    plan: '',
    planPeriod: 'year',
    coupon: '',
    error: '',
    couponDetails: [],
    shouldValidateCoupon: false,
  },
  data: {
    options: [],
  },
  options: Computed(computed.options),
};
