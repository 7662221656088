export const getOptions = ({ path, api }) => {
  return api.get('/payment/stripe-plus-prices').then(response => {
    return path.success({ items: response });
  });
};

export const validate = ({ path, state, api }) => {
  const { currency, plan, coupon: coupon_code } = state.get('payment.ui');

  return api
    .post('/payment/verify-stripe-coupon-code', {
      coupon_code,
      currency,
      plan,
    })
    .then(response => path.success({ couponDetails: response }))
    .catch(err => path.error({ err: err.response.data.translationKey }));
};

export const charge = async ({ path, state, api, props }) => {
  const { currency, plan, coupon: coupon_code } = state.get('payment.ui');
  console.log({ coupon_code, plan, currency });
  const session = await api.post('/payment/create-checkout-session', {
    // coupon_code,
    price: plan,
    // currency,
    // successUrl: 'http://localhost:9000/garden',
  });

  window.location = session.url;
  return;
};

export const changePaymentCard = ({ path, state, api, props }) => {
  const { stripe_email: stripeEmail } = state.get(`paymentHistory.data.active`);
  const { id: stripeToken } = props.token;

  const params = {
    stripeToken,
    stripeEmail,
  };

  return api
    .post('/payment/update-stripe-source', params)
    .then(response => {
      if (response.status === 200) {
        return path.success({ response });
      }

      return path.error({ err: { ...response } });
    })
    .catch(err => path.error({ err }));
};
