import { types } from "mobx-state-tree";
import { timestamp } from "./common";

export const ActiveSubscription = types.model({
  user_id: types.number,
  active: types.number,
  is_canceled: types.boolean,
  card_data: types.model({
    brand: types.string,
    last4: types.string
  }),
  created_at: types.number,
  current_period_end: types.number,
  id: types.number,
  plan: types.number,
  recurring: types.number,
  stripe_email: types.string,
  stripe_token: types.string,
  subscription_id: types.string,
  ...timestamp
});

export type IActiveSubscription = typeof ActiveSubscription.Type;
