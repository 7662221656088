import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import * as React from 'react';
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd';
import { compose } from 'recompose';

const styles = (_theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface DnDDrop {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  canDrop?: boolean;
}

interface DroppableProps extends DnDDrop, StyledComponent {
  type: string;
  onDrop: (props: Props, monitor: DropTargetMonitor) => void;
  dropHint?: JSX.Element;
}

type Props = WithStyles<typeof styles> & DroppableProps;

class Droppable extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      children,
      classes,
      canDrop,
      dropHint,
      connectDropTarget,
      style,
      className,
    } = this.props;

    const root = cs(classes.container, {
      [className]: className,
    });

    if (canDrop && dropHint) {
      return connectDropTarget(
        <div className={root} style={style}>
          {dropHint}
        </div>,
      );
    }

    return connectDropTarget(
      <div className={root} style={style}>
        {children}
      </div>,
    );
  }
}

/**
 *  Allows us to dynamicly set drop type. Allows for
 *  controll from outside of the component.
 */
const dropType = props => props.type;

/**
 * Implements the drop contract.
 */
const spec = {
  drop(props: Props, monitor: DropTargetMonitor): void {
    if (props.onDrop) {
      props.onDrop(props, monitor);
    }
  },
};

/**
 * Specifies the props to inject into komponent.
 */
const collect = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: !!monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const enhance = compose(
  DropTarget(dropType, spec, collect),
  withStyles(styles),
);

export default enhance(Droppable);
