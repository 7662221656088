import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Grid } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) => {
  const { up, values } = theme.breakpoints;

  return createStyles({
    container: {
      [up(values.md)]: {
        maxWidth: 960,
      },
      flexWrap: 'nowrap',
      margin: '0 auto',
      width: '100%',
      '& > section:last-child': {
        marginBottom: 200,
      },
    },
  });
};

type Props = WithStyles<typeof styles>;

class Preview extends React.Component<Props> {
  public render(): JSX.Element {
    const { classes, children } = this.props;
    return (
      <Grid
        container
        direction="column"
        classes={{ container: classes.container }}
      >
        {children}
      </Grid>
    );
  }
}

export default withStyles(styles)(Preview);
