import { loadApp } from '@app/store/factories';
import { set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as actions from './actions';
import { state as initialState } from './state';

export const load = loadApp([
  set(state`notifications.ui.loading`, true),
  actions.getNotifications,
  {
    success: [set(state`notifications.data.page`, props`notifications`)],
  },
  set(state`notifications.ui.loading`, false),
]);

export const unload = [set(state`notifications`, initialState)];

export const getLatest = [
  actions.getLatestNotifications,
  {
    success: [set(state`notifications.data.latest`, props`notifications`)],
  },
];

export const updateStatus = [actions.updateStatus];
