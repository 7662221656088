import { MAX_NO_TYPES } from '@gardenize/config';

export const getPlantTypes = ({ api, path }) => {
  return api
    .get('/plant-type', { per_page: MAX_NO_TYPES })
    .then(response => path.success({ plantTypes: response }))
    .catch(error => path.error({ error }));
};

export const addPlantTypeItem = ({ state, props }) => {
  state.unshift('plantTypes.data.items', props.response);
};

export const updatePlantTypesList = ({ state, props }) => {
  const plantTypesItems = state.get('plantTypes.data.items').slice();
  const plantTypeIndex = plantTypesItems.findIndex(
    item => item.id === props.id,
  );
  plantTypesItems[plantTypeIndex] = props.response;
  state.set(`plantTypes.data.items`, plantTypesItems);
};

export const createPlantType = ({ api, props, path }) => {
  return api
    .post('/plant-type', {
      name: props.name,
    })
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

export const updatePlantType = ({ api, props, path }) => {
  const { id, name } = props;

  return api
    .put(`/plant-type/${id}`, { id, name })
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

export const removePlantType = ({ api, props, path }) => {
  return api
    .delete(`/plant-type/${props.id}`)
    .then(response => path.success({ msg: response.result }))
    .catch(error => path.error({ error }));
};

export const removePlantTypeById = ({ state, props }) => {
  const newState = state
    .get('plantTypes.data.items')
    .filter(item => item.id !== props.id);
  state.set('plantTypes.data.items', newState);
};
