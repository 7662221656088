import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import * as React from 'react';

interface ValidatedCheckboxProps {
  error?: boolean;
}

type Props = CheckboxProps & ValidatedCheckboxProps;

class ValidatedCheckbox extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { error, ...rest } = this.props;

    if (!error) {
      return <Checkbox {...rest} />;
    }

    const ERROR_STYLE = { color: 'red' };

    return <Checkbox style={ERROR_STYLE} {...rest} />;
  }
}

export { Checkbox, CheckboxProps, ValidatedCheckbox };
