import { Form, FormikFormProps, connect, FormikContext } from "formik";
import * as React from "react";

type Props = FormikFormProps & {
  formik: FormikContext<any>;
};

class ResetForm extends React.Component<Props> {
  public componentWillUnmount(): void {
    const { formik } = this.props;

    formik.resetForm();
  }

  public render(): JSX.Element {
    const { formik, ...rest } = this.props;

    return <Form {...rest} />;
  }
}

export default connect<FormikFormProps, any>(ResetForm);
