import { types } from 'mobx-state-tree';
import { timestamp, timestamp_mobile } from './common';

export const PlantType = types.model({
  id: types.identifierNumber,
  user_id: types.number,
  name: types.string,
  ...timestamp,
  ...timestamp_mobile,
});

export type IPlantType = typeof PlantType.Type;
