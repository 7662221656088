import * as yup from "yup";

export const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("be_error_signup_email_required"),
  password: yup
    .string()
    .min(6, "six_minimum")
    .required("be_error_signup_password_required"),
  repeat_password: yup
    .string()
    .min(6, "six_minimum")
    .oneOf([yup.ref("password"), null], "toast_pass_not_match")
    .required("be_error_signup_password_required"),
  country: yup.string().required("country_required"),
  toc: yup.boolean().oneOf([true]),
  newspaper: yup.boolean()
});
