import { User } from '@gardenize/models';
import { types } from 'mobx-state-tree';

const App = {
  ui: types.model({
    feedbackDialog: types.boolean,
    accountDeletionDialog: types.boolean,
    paymentErrorDialog: types.boolean,
    paymentError: types.maybeNull(
      types.model({
        status: types.maybeNull(types.number),
        message: types.maybeNull(types.string),
        valid: types.maybeNull(types.boolean),
        premium: types.maybeNull(types.boolean),
      }),
    ),
    resetPasswordDialog: types.boolean,
  }),
  jwt: types.maybeNull(types.string),
  isRegistering: types.boolean,
  isAuthenticating: types.boolean,
  language: types.string,
  user: types.maybeNull(User),
  snacks: types.array(
    types.model({
      id: types.number,
      title: types.string,
      variant: types.string,
      endTime: types.number,
    }),
  ),
};

export default App;
