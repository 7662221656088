import { types } from 'mobx-state-tree';
import { Media } from './Media';
import { flag, timestamp, timestamp_mobile } from './common';

export const Area = types.model({
  id: types.identifierNumber,
  user_id: types.number,
  name: types.string,
  area_type_id: types.maybeNull(types.number),
  ...timestamp,
  ...timestamp_mobile,
  soil: types.maybeNull(types.string),
  light: types.maybeNull(types.string),
  ph: types.maybeNull(types.string),
  size: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  status: flag,
  media: types.maybeNull(types.array(Media)),
  // [ "123", "324" ] :/
  plantIds: types.maybeNull(types.array(types.string)),
  area_type_name: types.maybeNull(types.string),
});

export type IArea = typeof Area.Type;
