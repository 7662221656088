import { BranchContext, Context } from '@app/store/fluent';
import { Action, connector } from '@app/utils/entity';
import { ENTITIES_PER_CARD } from '@gardenize/config';
import { IArea, IMedia, IMeta, IPlant } from '@gardenize/models';
import { toJS } from 'mobx';
import getUnixTime from 'date-fns/getUnixTime';
const metaIncr = (state, path: string): IMeta => {
  const meta: IMeta = toJS(state.get(path));
  return { ...meta, totalCount: meta.totalCount + 1 };
};

export const getArea = ({
  api,
  props,
  state,
}: Context<{ areaId: number }>): Promise<void> => {
  return api
    .get(`/area/${props.areaId}`)
    .then(response => state.set('area.data.item', response));
};

export const getRelatedPlants = ({
  api,
  props,
  state,
}: Context<{ areaId: number; page: number }>) => {
  const user = state.get('user.id');
  const area = state.get('area.data.item.id') || props.areaId;
  const page = props.page ? props.page : 1;

  return api
    .get(`/garden/${user}/areas/${area}/plants`, {
      page,
      per_page: 8,
    })
    .then(response => {
      state.set('area.data.plants', response);
      state.set('area.ui.loading.plants', false);
    });
};

export const getRelatedEvents = ({
  api,
  props,
  state,
}: Context<{ areaId: number; page: number }>) => {
  const id = state.get('user.id');
  const page = props.page ? props.page : 1;

  return api
    .get(`/garden/${id}/areas/${props.areaId}/events`, {
      page,
      per_page: 8,
    })
    .then(response => {
      state.set('area.data.events', response);
      state.set('area.ui.loading.events', false);
    });
};

export const setLoading = (isLoading: boolean) => ({
  state,
}: Context): void => {
  state.set('area.ui.loading.page', isLoading);
};

const setter = (item: string) => (val: any) => ({ state }: Context): void => {
  state.set(`area.ui.${item}`, val);
};

export const setDeleteDialog = setter('dialog.del.image');
export const setDeleteEntity = setter('dialog.del.entity');
export const setUploadDialog = setter('dialog.upload');
export const setEventDialog = setter('dialog.event');

export const updateArea = ({
  props,
  path,
  api,
  state,
}: BranchContext<
  { success: { response: IArea } },
  { area: Partial<IArea> }
>): Promise<{ response: IArea }> => {
  const area = state.get('area.data.item');
  return api
    .put(`/area/${area.id}`, { ...area, ...props.area })
    .then(response => path.success({ response }));
};

export const updatePhoto = ({ props, api, state, path }) => {
  const media = state.get('area.data.item.media');
  const img = media[props.idx];

  return api
    .put(`/area/update-image/${img.id}`, {
      ...img,
      ...props.data,
      date: props.date,
    })
    .then(response => path.success({ media: response.media }));
};

export const setPhotoByIndex = ({ props, state }) => {
  const media: IMedia[] = state
    .get('area.data.item.media')
    .map((m, idx) => (idx === props.idx ? props.media : m));

  state.set('area.data.item.media', media);
};

export const getUnconnectedPlants = ({
  props,
  state,
  api,
}: Context<{ page: number; query }>): Promise<void> => {
  const user = state.get('user.id');
  const area = state.get('area.data.item.id');
  // get initial results
  const page = props.page ? props.page : 1;
  const per_page = 6; // as per design

  const params = {
    page,
    per_page,
  };

  const search = state.get('area.ui.search');

  if (search) {
    params.name = search;
  }

  return api
    .get(`/garden/${user}/areas/${area}/unrelated-plants`, params)
    .then(response => {
      state.set('area.data.unconnected', response);
      state.set('area.ui.loading.unconnected', false);
    });
};

export const dropCardImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<{ success: { response: IArea } }, { id: number }>): Promise<{
  response: IArea;
}> => {
  const area = state.get('area.data.item');
  const plantIds = area.plantIds.filter((pid: string) => +pid !== props.id);

  // NOTE(zvrk): to unlink entities you need to send -1; Go figure ¯\_(ツ)_/¯
  const newArea = { ...area, plantIds: plantIds.length ? plantIds : -1 };

  return api
    .put(`/area/${area.id}`, newArea)
    .then(response => path.success({ response }));
};

export const dropPanelImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<
  { success: { response: IArea } },
  { id: number }
>): Promise<{}> => {
  const area = state.get('area.data.item');
  const ids = area.plantIds ? area.plantIds : [];

  const plantIds = [String(props.id), ...ids];

  return api
    .put(`/area/${area.id}`, { ...area, plantIds })
    .then(response => path.success({ response }));
};

export const addPanelHead = ({ props, state }) => {
  const plants = toJS(state.get('area.data.plants.items'));
  const uplants = toJS(state.get('area.data.unconnected.items'));
  const plant = uplants.find((p: IPlant) => p.id === props.id);

  const connect = connector<IArea>(Action.CONNECT, plants);
  const disconnect = connector<IArea>(Action.DISCONNECT, uplants);

  state.set('area.data.unconnected.items', disconnect(plant));
  state.set('area.data.plants', {
    items: connect(plant),
    _meta: metaIncr(state, 'area.data.plants._meta'),
  });
};

export const addCardHead = ({ props, state }) => {
  const plants = toJS(state.get('area.data.plants.items'));
  const uplants = toJS(state.get('area.data.unconnected.items'));
  const plant = plants.find((p: IPlant) => p.id === props.id);

  const connect = connector<IPlant>(Action.CONNECT, uplants, 6);
  const disconnect = connector<IArea>(Action.DISCONNECT, plants);

  state.set('area.data.plants.items', disconnect(plant));
  state.set('area.data.unconnected', {
    items: connect(plant),
    _meta: metaIncr(state, 'area.data.unconnected._meta'),
  });
};

export const deleteAreaImage = ({
  props,
  state,
  api,
  path,
}: BranchContext<
  { success: { message: string } },
  { index: number }
>): Promise<{ message: string }> => {
  const images = state.get('area.data.item.media');
  return api
    .delete(`/area/remove-image/${images[props.index].id}`)
    .then(response => path.success({ message: response.message }));
};

interface Values {
  cost: string;
  size: string;
  quantity: string;
  weight: string;
  event_date: Date | null;
  photo_date: Date | null;
  reminder: boolean;
  reminder_time: Date | null;
  note: string;
}

type Response = {
  success: {
    response: string;
  };
};

export const createEvent = ({
  props,
  state,
  api,
  path,
}: BranchContext<Response, { data: Values }>): Promise<{
  response: string;
}> => {
  const areaId = state.get('area.data.item.id');
  return api
    .post('/event', { ...props.data, areaIds: [areaId] })
    .then(response => path.success({ response }));
};

export const addEvent = ({
  props,
  state,
}: Context<{ response: string }>): void => {
  const events = toJS(state.get('area.data.events'));

  const empty = events.items.length === 0;
  const maxEvents = events.items.length >= ENTITIES_PER_CARD;
  const zeroMeta = {
    currentPage: 1,
    pageCount: 1,
    perPage: 8,
    totalCount: 1,
  };

  // There is still place in card
  if (!maxEvents) {
    state.set('area.data.events', {
      items: [props.response, ...events.items],
      _meta: empty ? zeroMeta : metaIncr(state, 'area.data.events._meta'),
    });
    return;
  }

  // remove last item from card
  state.set('area.data.events', {
    items: [props.response, ...events.items.slice(0, -1)],
    _meta: metaIncr(state, 'area.data.events._meta'),
  });
};

export const toggleFavouriteImage = ({
  props,
  api,
  state,
  path,
}: BranchContext<{ success: { image: IMedia } }, { id: number }>): Promise<{
  image: IMedia;
}> => {
  const media = state.get('area.data.item.media');
  const img = media.find(m => m.id === props.id);
  return api
    .put(`/area/update-image/${props.id}`, {
      ...img,
      favorite: img.favorite === 1 ? 0 : 1,
    })
    .then(response => path.success({ image: response.media }));
};

export const setImage = ({
  props,
  state,
}: Context<{ id: number; image: IMedia }>): void => {
  const media = state
    .get('area.data.item.media')
    .map(m =>
      m.id === props.id
        ? { ...m, favorite: props.image.favorite }
        : { ...m, favorite: 0 },
    );
  state.set('area.data.item.media', media);
};

type A = { success: { response: IMedia } };
type B = { file: string };

export const uploadImage = ({
  props,
  state,
  path,
  api,
}: BranchContext<A, B>): Promise<{ response: IMedia }> => {
  const id = state.get('area.data.item.id');
  return api
    .post(`/area/upload-image/${id}`, {
      image: props.file,
      date: getUnixTime(new Date()),
    })
    .then(response => path.success({ response: response.media }));
};

export const addFile = ({
  props,
  state,
}: Context<{ image: string; name: string }>): void => {
  state.push('area.ui.files', {
    name: props.name,
    loading: true,
  });
};

export const uploadDrawnImage = ({
  props,
  state,
  path,
  api,
}: BranchContext<A, B>): Promise<{ response: IMedia }> => {
  const id = state.get('area.data.item.id');
  const images = state.get('area.data.item.media');
  const { file, ...rest } = props;
  return api
    .post(`/area/upload-image/${id}`, {
      image: file,
      drawn: 1,
      date: getUnixTime(new Date()),
      ...rest,
    })
    .then(response =>
      path.success({ response: response.media, image: images.length }),
    );
};

export const setActiveImage = ({ props, state }) => {
  state.set('area.ui.activeImage', props.idx);
};

export const setImageLoading = ({
  props,
  state,
}: Context<{ name: string }>): void => {
  const files = state.get('area.ui.files');

  const mapper = file =>
    file.name === props.name ? { ...file, loading: false } : file;

  state.set('area.ui.files', files.map(mapper));
};

export const deleteEntity = ({ api, state, path }) => {
  const id = state.get('area.data.item.id');
  return api.delete(`/area/${id}`).then(response => path.success({ response }));
};
