import { CerebralError } from 'cerebral';

export class AuthenticationError extends CerebralError {
  constructor(message) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export class HttpError extends CerebralError {
  constructor(message) {
    super(message);
    this.name = 'HttpError';
  }
}

export class InternalServerError extends CerebralError {
  constructor(data) {
    super(data.message);
    this.name = 'InternalsServerError';
    if (data) {
      this.details = {
        translationKey: data.translationKey,
        message: data.message,
      };
    }
  }
}

export class ValidationError extends CerebralError {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}
