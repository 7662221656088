import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { ListItemProps, ListItemText } from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import LinkListItem from './link-list-item';

const styles = (theme: Theme) =>
  createStyles({
    text: {
      textAlign: 'center',
    },
  });

type Path = string;

interface SeeAllProps extends ListItemProps {
  at: Path
}

type Props = WithStyles<typeof styles> & WithTranslation & SeeAllProps;

class SeeAll extends React.Component<Props> {
  static defaultProps = {
    at: '/notifications'
  }

  public render(): JSX.Element {
    const { t, at, close, classes } = this.props;

    return (
      <LinkListItem to={at} button={true} onClick={close}>
        <ListItemText classes={{ primary: classes.text }}>
          {t('see_all_notifications')}
        </ListItemText>
      </LinkListItem>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(styles),
);

export default enhance(SeeAll);
