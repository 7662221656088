import { StateContext } from '@app/store';
import Utils from '@date-io/date-fns';
import MuiPickersUtilsProvider, {
  MuiPickersUtilsProviderProps,
} from 'material-ui-pickers/MuiPickersUtilsProvider';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { compose } from 'recompose';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import itLocale from 'date-fns/locale/it';
import nbLocale from 'date-fns/locale/nb';
import svLocale from 'date-fns/locale/sv';

interface MuiPickerProviderProps extends MuiPickersUtilsProviderProps {}
type Props = MuiPickerProviderProps & StateContext;

const locale = {
  'en-US': enLocale,
  'sv-SE': svLocale,
  'de-DE': deLocale,
  'da-DK': enLocale,
  'it-IT': itLocale,
  'no-NO': nbLocale,
};

class MuiPickerProvider extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      store: { language },
      children,
      ...rest
    } = this.props;

    return (
      <MuiPickersUtilsProvider
        utils={Utils}
        locale={locale[language]}
        {...rest}
      >
        {children}
      </MuiPickersUtilsProvider>
    );
  }
}

const enhance = compose(
  inject('store'),
  observer,
);

export default enhance(MuiPickerProvider);
