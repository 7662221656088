import { IMedia } from "@gardenize/models";
import { withFormik } from "formik";
import pick from "lodash-es/pick";

import { schema } from "./validators";
import { Props, Values } from "./types";

const initial: Values = {
  name: "",
  date_timestamp: null,
  photo_date: null,
  photo_note: "",
  variety: "",
  latin_name: "",
  note: "",
  rating: "0",
  plant_type_id: ""
};

const getUnixTime = (value: number | null): Date | null => {
  if (!value) {
    return null;
  }

  return new Date(value * 1000);
};

const createDate = (media: IMedia): Date | null => {
  // media array is empty
  if (!media) {
    return null;
  }

  const date = media.date || media.created_at || media.created_at_mobile;

  // miliseconds to seconds
  return date ? new Date(date * 1000) : null;
};

const AVAILABLE_COLROS = [
  "black",
  "blue",
  "green",
  "orange",
  "pink",
  "red",
  "yellow",
  "white"
];

export const withPlantInfo = withFormik<Props, Values>({
  validationSchema: schema,
  enableReinitialize: true,
  handleSubmit: () => {},
  mapPropsToValues: ({ plant, picture }) => {
    if (!plant) {
      return initial;
    }

    const selectedPicture = plant.media[picture];
    const color_notes = {};
    let photo_note = "";
    if (selectedPicture) {
      AVAILABLE_COLROS.forEach(color => {
        const note = selectedPicture[`note_${color}`];
        if (note && note !== "") {
          color_notes[`note_${color}`] = note;
        }
      });
      photo_note = selectedPicture.photo_note;
    }
    return {
      ...initial,
      ...pick(plant, Object.keys(initial)),
      date_timestamp: getUnixTime(plant.date_timestamp),
      photo_date: createDate(selectedPicture),
      photo_note: photo_note || "",
      hasPicture: !!selectedPicture,
      ...color_notes
    };
  }
});
