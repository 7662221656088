import { Field } from '@gardenize/forms';
import { TextField } from '@gardenize/forms/mui';
import InputAdornment from '@material-ui/core/InputAdornment';
import cs from 'classnames';
import * as React from 'react';

import { Grid, TextFieldProps } from '../';

interface FieldProps {
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  value?: any;
  name: string;
}

export interface StandardTextFieldProps extends FieldProps {
  adornment?: string;
  long?: boolean;
}
type Props = TextFieldProps & StandardTextFieldProps;

export class StandardTextField extends React.Component<Props> {
  private adornmentProps(adornment: string): {} {
    if (!adornment) {
      return {};
    }

    return {
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">{adornment}</InputAdornment>
        ),
      },
    };
  }

  public render(): JSX.Element {
    const { adornment, long, className, ...rest } = this.props;
    const ERROR_STYLE = {
      position: 'absolute',
      top: '3rem',
      left: 0,
    };

    const root = cs({
      [className]: className,
    });

    return (
      <Grid item xs={12} md={long ? 12 : 6} className={root} container>
        <Field
          type="text"
          fullWidth={true}
          component={TextField}
          FormHelperTextProps={{ style: ERROR_STYLE }}
          {...this.adornmentProps(adornment)}
          {...rest}
        />
      </Grid>
    );
  }
}
