import Analytics from '@app/utils/analytics';
import logger from '@app/utils/logger';
import { parallel, sequence } from 'cerebral';
import { set, wait, when } from 'cerebral/operators';
import { props, state, Tag } from 'cerebral/tags';
import path from 'ramda/src/path';

import * as actions from './actions';
import * as settingsActions from './modules/settings/actions';
import { findLanguage } from '@app/utils/languages';
const log = logger('app');

interface IError {
  message: string;
  translation_key: string;
}

export const redirector = (to, withRedirect = false) => {
  return function redirector({ router }): void {
    if (withRedirect) {
      const toWithRedirect = `${to}?redirectTo=${encodeURIComponent(
        window.location.pathname,
      )}`;
      router.redirect(toWithRedirect);
    } else {
      router.redirect(to);
    }
  };
};

export const goBack = ({ router }) => router.goBack();

export const setLanguage = (conf: 'state' | 'props', path?: string) => ({
  props,
  state,
  i18n,
}): void => {
  switch (conf) {
    case 'props': {
      if (!path) {
        return i18n.changeLanguage(findLanguage(props.data.language));
      }

      return i18n.changeLanguage(findLanguage(props[path]));
    }
    case 'state': {
      if (!path) {
        log.error('SetLanguage::Path is required when using state');
      }

      const lang = state.get(path).language;
      // When user registers `language` property is `null`. Sigh...
      // Set to default.
      if (!lang) {
        log.info('Setting language to default');
        return i18n.changeLanguage('EN');
      }

      return i18n.changeLanguage(findLanguage(state.get(path).language));
    }
    default: {
      log.error('SetLanguage::Unknown path');
    }
  }
};

export const notifications = (state, title, variant, ttl = 2) => {
  const now = Date.now();
  state.push('snacks', {
    title,
    variant,
    id: now,
    endTime: now + ttl * 1000,
  });
};

const debug = (dpath: string[]) => ({ props }) => log.debug(path(dpath, props));

export const getLatestNotifications = [
  actions.getLatestNotifications,
  {
    success: [set(state`notifications.data.latest`, props`notifications`)],
  },
];

export const addNotification = (variant, title: string, intl = false) => {
  const ttl = 2;
  return function addNotification({ state, resolve, i18n }): void {
    const now = Date.now();
    state.push('snacks', {
      variant,
      id: now,
      endTime: now + ttl * 1000,
      title: intl ? i18n.trans(resolve.value(title)) : resolve.value(title),
    });
  };
};

export function track(e: string, args?: any): () => void {
  return () => {
    Analytics.track(e, args);
  };
}

const identifyUser = ({ props }) => Analytics.identify(props.user);

export const showError = (error: Tag<IError>) => {
  const ttl = 5;

  return function addNotification({ state, resolve, i18n }): void {
    const err = resolve.value(error);
    const now = Date.now();

    let translationKey: string;
    let message: string;

    if (err.data) {
      translationKey = err.data.translationKey;
    } else if (err.details) {
      translationKey = err.details.translationKey;
      message = err.details.message;
    }

    let transaltedTitle;
    if (translationKey) {
      transaltedTitle = i18n.trans(translationKey);
      if (
        transaltedTitle === '' ||
        (transaltedTitle === translationKey && message)
      ) {
        transaltedTitle = message;
      }
    } else {
      transaltedTitle = err.message;
    }

    state.push('snacks', {
      id: now,
      title: transaltedTitle,
      variant: 'error',
      endTime: now + ttl * 1000,
    });
  };
};

export const initUser = [
  actions.getUser,
  {
    success: [
      set(state`user`, props`user`),
      set(state`profile.data`, props`user.profile`),
      identifyUser,
      setLanguage('state', 'user.profile'),
    ],
  },
];

export const initSettings = [
  settingsActions.getSettings,
  {
    success: [set(state`settings.data`, props`settings`)],
  },
];

export const loadApp = continuation => {
  return parallel('loadApp', [
    when(state`user`),
    {
      true: [parallel([continuation, getLatestNotifications])],
      false: [
        actions.initNotifications,
        actions.listenLanguage,
        parallel([initUser, initSettings, getLatestNotifications]),
        actions.handleTabVisiblity,
        continuation,
      ],
    },
  ]);
};

const langSetter = ({ i18n, state }) => {
  state.set('language', i18n.language());
};

export const withLanguage = continuation => [langSetter, continuation];

export const social = network => {
  return sequence('social', [
    actions.social(network),
    {
      success: [
        actions.setJwtFromProps,
        actions.getUser,
        {
          success: [set(state`user`, props`user`), actions.redirectAfterLogin],
          error: addNotification(props`error`, 'error'),
        },
      ],
      error: addNotification(props`error`, 'error'),
    },
  ]);
};
