import { PaymentOption, CouponDetails } from '@gardenize/models';
import { types } from 'mobx-state-tree';

export default {
  ui: types.model({
    loading: types.boolean,
    currency: types.string,
    error: types.string,
    plan: types.string,
    planPeriod: types.string,
    coupon: types.string,
    couponDetails: types.array(CouponDetails),
    shouldValidateCoupon: types.boolean,
  }),
  data: types.model({
    options: types.optional(types.array(PaymentOption), []),
  }),
};
