import * as React from 'react';

const Leaf = ({ className, color = "#42281F", isOutlined = true  }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="leaf-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={isOutlined ? "none" : color}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    id="leaf-svg"
  >
    <title id="leaf-icon">Leaf</title>
    <path d="M4.61816 15.5V8.22732C4.61816 6.29847 5.38439 4.44862 6.74829 3.08472C8.1122 1.72082 9.96204 0.95459 11.8909 0.95459H19.1636V8.27277C19.1708 9.23242 18.988 10.184 18.6257 11.0727C18.2635 11.9614 17.7289 12.7695 17.0529 13.4507C16.3768 14.1318 15.5726 14.6724 14.6867 15.0413C13.8008 15.4102 12.8506 15.6001 11.8909 15.6H4.61816" />
    <path d="M4.80918 15.1819L0.90918 19.091" />
  </svg>
);

export default Leaf;
