import {
  IActiveSubscription,
  IPaymentHistoryItem,
  IPaymentCard,
} from '@gardenize/models';

export type State = {
  ui: {
    loading: boolean;
    infiniteListLoading: boolean;
  };
  data: {
    items: IPaymentHistoryItem[];
    card: IPaymentCard | null;
    active: IActiveSubscription | null;
  };
};

export const state: State = {
  ui: {
    loading: true,
    infiniteListLoading: false,
  },
  data: {
    active: null,
    card: null,
    items: [],
  },
};
