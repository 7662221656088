import { equals, set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as actions from './actions';
import * as factories from './factories';
import { updateLanguage } from './modules/profile/actions';
import { state as initialState } from './state';

// prettier-ignore
export const openAccountDeletionDialog = [ set(state`ui.accountDeletionDialog`, true) ];
export const closeAccountDeletionDialog = [
  set(state`ui.accountDeletionDialog`, false)
];

export const openFeedbackDialog = [ factories.track('OpenFeedback'), set(state`ui.feedbackDialog`, true) ];
export const closeFeedbackDialog = [
  factories.track('CloseFeedback'),
  set(state`ui.feedbackDialog`, false),
];

export const closePaymentErrorDialog = [
  set(state`ui.paymentError`, null),
  set(state`ui.paymentErrorDialog`, false),
];

export const createDeleteAccountFeedback=[
  actions.createFeedback,
  {
    success:[
      openAccountDeletionDialog
    ]
  }
]

export const createFeedback = [
  actions.createFeedback,
  {
    success: [
      closeFeedbackDialog,
      factories.addNotification('success', 'feedback_success', true),
    ],
    error: [closeFeedbackDialog],
  },
];

export const signIn = [
  set(state`isAuthenticating`, true),
  actions.logIn,
  {
    success: [
      actions.setJwtFromProps,
      factories.loadApp([actions.redirectAfterLogin]),
    ],
    error: [factories.showError(props`error.translationKey`)],
  },
  set(state`isAuthenticating`, false),
];

export const signUp = [
  set(state`isRegistering`, true),
  actions.signUp,
  {
    success: [
      actions.setJwtFromProps,
      actions.getUser,
      {
        success: [
          set(state`user`, props`user`),
          actions.mapLanguage,
          updateLanguage,
          actions.redirectAfterLogin,
        ],
        error: [],
      },
    ],
    error: [],
  },
  set(state`isRegistering`, false),
];

export const facebook = [
  actions.social('facebook'),
  {
    success: [
      actions.setJwtFromProps,
      actions.getUser,
      {
        success: [set(state`user`, props`user`), actions.redirectAfterLogin],
        error: factories.showError(props`error`),
      },
    ],
    error: factories.showError(props`error`),
  },
];

export const google = [
  actions.social('google'),
  {
    success: [
      actions.setJwtFromProps,
      actions.redirectAfterLogin,
      actions.getUser,
      {
        success: [set(state`user`, props`user`), actions.redirectAfterLogin],
        error: factories.addNotification('error', props`error`),
      },
    ],
    error: factories.addNotification('error', props`error`),
  },
];

export const apple = [
  actions.social('apple'),
  {
    success: [
      actions.setJwtFromProps,
      actions.redirectAfterLogin,
      actions.getUser,
      {
        success: [set(state`user`, props`user`), actions.redirectAfterLogin],
        error: factories.addNotification('error', props`error`),
      },
    ],
    error: factories.addNotification('error', props`error`),
  },
];

export const socialError = [
  factories.addNotification('error', 'toast_error_msg', true),
];

export const openResetPasswordDialog = [
  factories.track('OpenResetPassword'),
  set(state`ui.resetPasswordDialog`, true),
];

export const closeResetPasswordDialog = [
  factories.track('CloseResetPassword'),
  set(state`ui.resetPasswordDialog`, false),
];

export const resetPassword = [
  actions.resetPassword,
  {
    success: [
      factories.addNotification('success', props`message`),
      closeResetPasswordDialog,
    ],
    error: [factories.showError(props`error`), closeResetPasswordDialog],
  },
];

export const resetGlobalState = [
  set(state`user`, initialState.user),
  set(state`jwt`, initialState.jwt),
  set(state`snacs`, initialState.snacks),
];

export const handleHttpError = [
  actions.logError,
  equals(props`error.status`),
  {
    401: [factories.redirector('/login'), resetGlobalState],
    otherwise: [factories.redirector('/login'), resetGlobalState],
  },
];

export const logOut = [
  actions.logOut,
  {
    success: [factories.redirector('/login'), resetGlobalState],
    error: [factories.redirector('/login'), resetGlobalState],
  },
];

export const changeLanguage = [
  actions.changeLanguage,
  set(state`language`, props`lang`),
];

export const loginLoad = factories.withLanguage([]);

export const loadDeleteAccount = factories.loadApp([]);
