import { domain } from "@gardenize/config";
import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required(),
  note: yup.string().nullable(true),
  photo_date: yup.date(),
  ph: yup.mixed().oneOf(domain.ph),
  soil: yup.mixed().oneOf(domain.soil),
  ligth: yup.mixed().oneOf(domain.light),
  size: yup.number(),
  area_type_id: yup.number().nullable(true)
});
