import { types } from "mobx-state-tree";

import { flag, timestamp } from "./common";

export const Settings = types.model({
  id: types.identifierNumber,
  user_id: types.number,
  temperature: types.string,
  currency: types.string,
  measuring_unit: types.enumeration(["metric", "imperial"]),
  date_format: types.enumeration(["dd/MM/yyyy", "yyyy-MM-dd", "MM/dd/yyyy"]),
  mail_newsletter: flag,
  weight: types.string,
  ...timestamp
});

export type ISettings = typeof Settings.Type;
