import { Profile } from '@gardenize/models';
import { types } from 'mobx-state-tree';

export default {
  ui: types.model({
    loading: types.boolean,
    uploading: types.boolean,
    edit: types.boolean,
    dialog: types.boolean,
    // image submited by user
    img: types.maybeNull(types.string),
  }),
  data: types.maybeNull(Profile),
};
