import { State } from './state';

export function hasPictures(this: State): boolean {
  const plant = this.data.item;

  return plant ? plant.media.length > 0 : false;
}

export function totalConnectedAreas(this: State): number {
  const areas = this.data.areas._meta;
  return areas ? areas.totalCount : 0;
}

export function totalConnectedEvents(this: State): number {
  const events = this.data.events._meta;
  return events ? events.totalCount : 0;
}

export function totalUnconnected(this: State): number {
  const unconnected = this.data.unconnected._meta;
  return unconnected ? unconnected.totalCount : 0;
}

export function unconnectedPages(this: State): number {
  const PER_PAGE = 6;
  return Math.ceil(this.totalUnconnected / PER_PAGE);
}
