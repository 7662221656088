import * as factories from '@app/store/factories';
import { concat, set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as actions from './actions';
import { state as initialState } from './state';

// prettier-ignore
export const load = factories.loadApp(
  [
    set(state`paymentHistory.ui.loading`, true),
    actions.getPaymentHistory,
    {
      success: [
        set(state`paymentHistory.data.items`, props`invoices`),
        set(state`paymentHistory.data.active`, props`active`),
        set(state`paymentHistory.data.card`, props`card`),
        set(state`paymentHistory.ui.loading`, false)
      ]
    }
  ]
);

export const unload = [set(state`paymentHistory`, initialState)];

export const fetch = [
  set(state`paymentHistory.ui.infiniteListLoading`, true),

  actions.getPaymentHistory,
  {
    success: [
      concat(state`paymentHistory.data.items`, props`items`),
      set(state`paymentHistory.ui.infiniteListLoading`, false),
    ],
  },
];

export const cancelSubscription = [
  actions.cancelSubscription,
  {
    success: [
      actions.getPaymentHistory,
      {
        success: [
          set(state`paymentHistory.data.items`, props`invoices`),
          set(state`paymentHistory.data.active`, props`active`),
          set(state`paymentHistory.data.card`, props`card`),
          set(state`paymentHistory.ui.loading`, false),
          factories.addNotification('success', 'subscription_canceled', true),
        ],
      },
    ],
    error: [factories.showError(props`err`)],
  },
];
