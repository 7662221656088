import { IUser } from '@gardenize/models';
import when from 'ramda/src/when';

import VERSION from './version';

declare global {
  interface Window {
    ga: any;
  }
}

const __DEV__ = process.env.NODE_ENV === 'development' && !process.env.PRODUCTION;

export const DNT =
  typeof window !== 'undefined' &&
  !!(window.doNotTrack === '1' || window.navigator.doNotTrack === '1');

const SHOULD_TRACK = () => DNT && !__DEV__ && window.ga;

const identify = (user: IUser) => {
  try {
    window.ga('set', 'userId', user.id);
  } catch (e) {
    when(__DEV__, () => console.error(e));
  }
};

const track = (name, ...rest) => {
  try {
    window.ga('send', 'event', {
      ...rest,
      hitType: 'event',
      eventCategory: 'App',
      eventLabel: name,
      version: VERSION,
      path: location.pathname + location.search,
    });
  } catch (e) {
    when(__DEV__, () => console.error(e));
  }
};

export default {
  identify: when(SHOULD_TRACK, identify),
  track: when(SHOULD_TRACK, track),
};
