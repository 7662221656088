import { Detector } from '@internal/detector';

export default function requirePolyfills(): Promise<Function[]> {
  const promises = [];

  const detect = Detector(window.navigator.userAgent);

  if (detect.isOld) {
    promises.push(
      import(/* webpackChunkName: 'polyfills' */ '@babel/polyfill'),
    );
  }

  if (detect.isEdge) {
    promises.push(
      import(
        /* webpackChunkName: 'smooth-polyfill' */ 'smoothscroll-polyfill'
      ).then(lib => lib.polyfill()),
    );
  }

  return Promise.all(promises);
}
