import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';

const styles = (_theme: Theme) =>
  createStyles({
    button: {
      position: 'absolute',
      outline: 0,
      right: 8,
      top: 30,
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
  });

type Props = WithStyles<typeof styles>;

const ControllerButton: React.SFC<Props> = ({ children, classes, ...rest }) => {
  return (
    <button className={classes.button} {...rest}>
      {children}
    </button>
  );
};

export default withStyles(styles)(ControllerButton);
