import loadable from 'loadable-components';

export const Shell = loadable(() =>
  import(/* webpackChunkName: "shell" */ /* webpackPrefetch: true */ './Shell'),
);

export * from './split';
export * from './Application';
export * from './Preview';
export * from './Details';
export * from './dynamic-layout';
export { default as Container } from './Container';
