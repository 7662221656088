import {
  ActiveSubscription,
  PaymentHistoryItem,
  PaymentCard,
} from '@gardenize/models';
import { types } from 'mobx-state-tree';

export default {
  ui: types.model({
    loading: types.boolean,
    infiniteListLoading: types.boolean,
  }),
  data: types.model({
    items: types.optional(types.array(PaymentHistoryItem), []),
    card: types.maybeNull(PaymentCard),
    active: types.maybeNull(ActiveSubscription),
  }),
};
