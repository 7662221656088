import { parallel } from 'cerebral';
import { sequence } from '@app/store/fluent';
import {
  debounce,
  push,
  set,
  splice,
  toggle,
  wait,
  when,
  unshift
} from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';
import * as globalActions from '../../actions';
import { state as initialState } from './state';

const init = parallel([
  actions.getPlant,
  actions.getRelatedAreas,
  actions.getRelatedEvents,
]);

export const load = factories.loadApp([
  when(state`user.premium.active`),
  {
    true: [init, set(state`plant.ui.loading.page`, false)],
    false: [globalActions.redirectToPayments],
  },
]);

export const unload = [set(state`plant`, initialState)];

export const update = [
  set(state`plant.ui.submitting`, true),
  actions.update,
  {
    success: [
      // NOTE(zvrk): there is a race condition. `update` sequence is called
      // when top navigation is clicked ex `Plants`. After page naviation,
      // we clear state, but there is still in flight `PUT` request, which
      // overwirtes state when it returns.
      // Temporary solution is to check if we are in `initialState` (we check
      // for loading flag). If it's in loading state (true), it means we are
      // in `reset` state and should discard results.
      when(state`plant.ui.loading.page`),
      {
        true: [],
        false: [
          set(state`plant.data.item`, props`response`),
          set(state`plant.ui.submitting`, false),
        ],
      },
    ],
  },
];

export const toggleFavouriteImage = [
  actions.toggleFavouriteImage,
  {
    success: [actions.setImage],
  },
];

export const openDeleteDialog = actions.setDeleteDialog(true);
export const closeDeleteDialog = actions.setDeleteDialog(false);

export const openDeleteEntityDialog = actions.setDeleteEntityDialog(true);
export const closeDeleteEntityDialog = actions.setDeleteEntityDialog(false);

export const openCloneDialog = actions.setCloneDialog(true);
export const closeCloneDialog = actions.setCloneDialog(false);

export const openUploadDialog = actions.setUploadDialog(true);
export const closeUploadDialog = [
  actions.setUploadDialog(false),
  set(state`plant.ui.files`, []),
];

export const openEventDialog = actions.setEventDialog(true);
export const closeEventDialog = actions.setEventDialog(false);

export const uploadImage = [
  actions.addFile,
  actions.uploadImage,
  {
    success: [
      unshift(state`plant.data.item.media`, props`response`),
      actions.setImageLoading,
    ],
  },
];

export const uploadDrawnImage = [
  toggle(state`area.ui.loading.page`),
  set(state`plant.ui.submitting`, true),
  actions.addFile,
  actions.uploadDrawnImage,
  {
    success: [
      toggle(state`area.ui.loading.page`),
      set(state`plant.ui.submitting`, false),
      push(state`plant.data.item.media`, props`response`),
      actions.setImageLoading,
      set(state`plant.ui.activeImage`, props`image`),
    ],
  },
];

export const setActiveImage = [actions.setActiveImage];

export const deleteImage = [
  actions.deleteImage,
  {
    success: [
      splice(state`plant.data.item.media`, props`index`, 1),
      closeDeleteDialog,
    ],
  },
];

export const deleteEntity = [
  actions.deleteEntity,
  {
    success: [factories.goBack],
  },
];

export const copyEntity = [
  toggle(state`area.ui.loading.page`),
  actions.copyEntity,
  {
    success: [
      set(state`plant.data.item`, props`response`),
      closeCloneDialog,
      toggle(state`area.ui.loading.page`),
      actions.navigateToCopiedPlant,
    ],
  },
];

export const fetchAreas = [
  toggle(state`area.ui.loading.plants`),
  actions.getRelatedAreas,
];

export const fetchEvents = [
  toggle(state`area.ui.loading.events`),
  actions.getRelatedEvents,
];

export const createEvent = [
  actions.createEvent,
  {
    success: [closeEventDialog, actions.addEvent],
  },
];

export const fetchUnconnected = [actions.getUnconnectedAreas];

export const setSearch = set(state`plant.ui.search`, props`query`);

export const searchUnconnected = [
  set(state`plant.ui.loading.unconnected`, true),
  setSearch,
  debounce(300),
  {
    continue: [fetchUnconnected],
    discard: [],
  },
];
export const togglePanel = [
  when(state`plant.ui.panel`),
  {
    true: [
      toggle(state`plant.ui.panel`),
      wait(300),
      set(state`plant.ui.loading.unconnected`, true),
    ],
    false: [toggle(state`plant.ui.panel`), actions.getUnconnectedAreas],
  },
];

export const dropCardImg = [
  actions.dropCardImg,
  {
    success: [
      set(state`plant.data.item`, props`response`),
      actions.addCardHead,
      fetchAreas,
    ],
  },
];

export const dropPanelImg = [
  actions.dropPanelImg,
  {
    success: [
      set(state`plant.data.item`, props`response`),
      actions.addPanelHead,
      fetchUnconnected,
    ],
  },
];

export const updatePhoto = [
  set(state`plant.ui.submitting`, true),
  actions.updatePhoto,
  {
    success: [actions.setPhotoByIndex, set(state`plant.ui.submitting`, false)],
  },
];

export const setLightbox = [set(state`plant.ui.lightbox`, props`lightbox`)];

export const edit = sequence(s =>
  s.action(({ state }) =>
    state.set('plant.ui.edit', !state.get('plant.ui.edit')),
  ),
);
