import { withFormik } from "formik";

import { schema } from "./validators";
import { Props, Values } from "./types";

const initial: Values = {
  source: "feedback",
  title: "",
  message: "",
  email: ""
};

export const withFeedback = withFormik<Props, Values>({
  validationSchema: schema,
  handleSubmit: (
    values: Values,
    { resetForm, setSubmitting, props: { onSubmit } }
  ) => {
    const data = {
      source: String(values.source),
      title: String(values.title),
      message: String(values.message),
      email: String(values.email)
    };

    onSubmit({ data });
    setSubmitting(false);
    resetForm();
  },
  mapPropsToValues: () => {
    return initial;
  }
});
