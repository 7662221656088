import { SignalsContext, StateContext, StoreContext } from '@app/store';
import history from '@app/utils/history';
import capitalize from 'lodash-es/capitalize';
import * as qs from 'qs';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IParams {
  [key: string]: string;
}

/**
 * It is a common occurance to `load` and `dispose` data in `componentDidMount`
 * and `componentWillUnmount`. This is extracted and abstrated here. Each route
 * that needs to use this HOC, needs to implement `load` and optional `unload`
 * method.
 *
 * This HOC makes downstream routes aware of what I call `query reload`. When the
 * route changes it will parse query params and reload state. This should be separated
 * to it's own HOC in the future.
 *
 * HOC is a superset of `withRouter`, it spreads `withRouter` props to children props
 */
export const withDisposableRoute = <T extends StoreContext>(
  route: string,
  onChange?: (
    params: IParams,
    signals: SignalsContext,
    store: StateContext,
  ) => void,
) => (
  Component: React.ComponentType<T>,
): React.ComponentType<T & RouteComponentProps<any>> => {
  class DisposableRoute extends React.Component<T & RouteComponentProps<any>> {
    static displayName = `Disposable${capitalize(route)}Route`;

    private unlisten: () => void;

    private parse = (search: string): IParams => {
      return qs.parse(search, { ignoreQueryPrefix: true });
    };

    public componentDidMount(): void {
      const { signals, store, location } = this.props;

      const params: IParams = this.parse(location.search);

      if (onChange) {
        onChange(params, signals, store);
      }

      signals[route].load(params);

      this.unlisten = history.listen(location => {
        this.onRouteChange(this.parse(location.search));
      });
    }

    public componentWillUnmount(): void {
      const { signals } = this.props;

      if (signals[route].unload) {
        signals[route].unload();
      }

      this.unlisten();
    }

    private onRouteChange = (params: IParams): void => {
      const { signals, store } = this.props;

      if (onChange) {
        onChange(params, signals, store);
      }

      signals[route].load(params);
    };

    public render(): JSX.Element {
      return <Component {...this.props} />;
    }
  }

  return withRouter(DisposableRoute);
};
