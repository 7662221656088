import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'mobx-react';
import * as React from 'react';
import { Router } from 'react-router-dom';

import { ScrollToTop } from './components/atoms';
import { MuiPickerProvider } from './components/hoc';
import controller from './controller';
import { theme } from './theme';
import history from './utils/history';

export const AppProviders: React.FunctionComponent = ({
  children,
}): JSX.Element => {
  return (
    <Provider {...controller.provide()}>
      <MuiThemeProvider theme={theme}>
        <MuiPickerProvider>
          <Router history={history}>
            <ScrollToTop>{children}</ScrollToTop>
          </Router>
        </MuiPickerProvider>
      </MuiThemeProvider>
    </Provider>
  );
};
