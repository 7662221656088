import { Module as CerebralModule } from '@cerebral/fluent';
import { IProfile, IUser } from '@gardenize/models';

import { location } from './getters';
import model from './model';
import * as sequences from './sequences';
import { state, State } from './state';

interface Error {
  error: any;
}

export interface PropfileBranchProps extends Error {
  success: { profile: IProfile };
}

export interface UserBranchProps extends Error {
  success: { user: IUser };
}

export interface LocationProps {
  longitude: string;
  latitude: string;
}

export interface UserProps {
  values: {
    copy_data_settings: 'allowed' | 'forbidden';
    visibility: 'public' | 'private';
    location_visibility: 'public' | 'private';
    global_hardiness_zone: string;
    latitude: string;
    longitude: string;
  };
}

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  unload: sequences.unload,
  edit: sequences.edit,
  setLocation: sequences.setLocation,

  updatePassword: sequences.updatePassword,
  updateUser: sequences.updateUser,
  updateCountry: sequences.updateCountry,
  uploadCover: sequences.uploadCover,
  uploadAvatar: sequences.uploadAvatar,
  updateUserState: sequences.updateUserState,
};

export const Module = CerebralModule<State, Signals>({
  model,
  state,
  signals,
  getters: {
    location,
  },
});
