import { MAX_NO_TYPES } from '@gardenize/config';

export const getActivityTypes = ({ api, path }) => {
  return api
    .get('/activity-type', { per_page: MAX_NO_TYPES })
    .then(response => path.success({ activityTypes: response }))
    .catch(error => path.error({ error }));
};

export const addActivityTypeItem = ({ state, props }) => {
  state.unshift('activityTypes.data.items', props.response);
};

export const updateActivityList = ({ state, props }) => {
  const activityTypesItems = state.get('activityTypes.data.items').slice();
  const activityTypeIndex = activityTypesItems.findIndex(
    item => item.id === props.id,
  );
  activityTypesItems[activityTypeIndex] = props.response;
  state.set(`activityTypes.data.items`, activityTypesItems);
};

export const createActivityType = ({ api, props, path }) => {
  return api
    .post('/activity-type', {
      name: props.name,
    })
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

export const updateActivityType = ({ api, props, path }) => {
  const { id, name } = props;

  return api
    .put(`/activity-type/${id}`, { id, name })
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

export const removeActivityType = ({ api, props, path }) => {
  return api
    .delete(`/activity-type/${props.id}`)
    .then(response => path.success({ msg: response.result }))
    .catch(error => path.error({ error }));
};

export const removeActivityTypeById = ({ state, props }) => {
  const newState = state
    .get('activityTypes.data.items')
    .filter(item => item.id !== props.id);
  state.set('activityTypes.data.items', newState);
};
