import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button, ButtonProps } from './';

export interface LinkButtonProps extends ButtonProps {
  to: string;
}

type Props = RouteComponentProps & LinkButtonProps;

class LinkButton extends React.PureComponent<Props> {
  private onClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const { history, to, onClick } = this.props;

    if (onClick) {
      onClick(event);
    }

    history.push(to);
  };

  public render(): JSX.Element {
    const {
      to,
      history,
      location,
      match,
      staticContext,
      onClick,
      ...rest
    } = this.props;

    return <Button {...rest} onClick={this.onClick} />;
  }
}

export default withRouter(LinkButton);
