import { Module as CerebralModule } from '@cerebral/fluent';

import * as getters from './getters';
import model from './model';
import * as sequences from './sequences';
import { state, State } from './state';

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  edit: sequences.edit,
  unload: sequences.unload,

  update: sequences.update,
  updatePhoto: sequences.updatePhoto,

  deleteEntity: sequences.deleteEntity,
  copyEntity: sequences.copyEntity,
  deleteImage: sequences.deleteImage,
  uploadImage: sequences.uploadImage,
  uploadDrawnImage: sequences.uploadDrawnImage,
  setActiveImage: sequences.setActiveImage,

  relatedAreas: sequences.fetchAreas,
  relatedEvents: sequences.fetchEvents,

  toggleFavouriteImage: sequences.toggleFavouriteImage,
  openDeleteDialog: sequences.openDeleteDialog,
  closeDeleteDialog: sequences.closeDeleteDialog,
  openDeleteEntityDialog: sequences.openDeleteEntityDialog,
  closeDeleteEntityDialog: sequences.closeDeleteEntityDialog,
  openUploadDialog: sequences.openUploadDialog,
  closeUploadDialog: sequences.closeUploadDialog,
  openCloneDialog: sequences.openCloneDialog,
  closeCloneDialog: sequences.closeCloneDialog,
  setLightbox: sequences.setLightbox,

  openEventDialog: sequences.openEventDialog,
  closeEventDialog: sequences.closeEventDialog,
  createEvent: sequences.createEvent,

  togglePanel: sequences.togglePanel,
  unconnectedAreas: sequences.fetchUnconnected,
  searchUnconnected: sequences.searchUnconnected,
  setSearch: sequences.setSearch,

  dropCardImg: sequences.dropCardImg,
  dropPanelImg: sequences.dropPanelImg,
};

export const Module = CerebralModule<State, Signals>({
  model,
  state,
  signals,
  getters,
});
