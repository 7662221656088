import { Event, Meta } from '@gardenize/models';
import { types } from 'mobx-state-tree';

export default {
  ui: types.model({
    loading: types.boolean,
    searching: types.boolean,
    query: types.string,
    dialogs: types.model({
      event: types.boolean,
    }),
    selected: types.model({
      months: types.array(types.number),
      year: types.number,
    }),
    page: types.model({
      upcoming: types.number,
      past: types.number,
    }),
  }),
  data: types.model({
    upcoming: types.model({
      items: types.optional(types.array(Event), []),
      _meta: types.maybeNull(Meta),
    }),
    past: types.model({
      items: types.optional(types.array(Event), []),
      _meta: types.maybeNull(Meta),
    }),
  }),
  search: types.model({
    upcoming: types.model({
      items: types.optional(types.array(Event), []),
      _meta: types.maybeNull(Meta),
    }),
    past: types.model({
      items: types.optional(types.array(Event), []),
      _meta: types.maybeNull(Meta),
    }),
  }),
};
