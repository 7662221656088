import { StandardTextField } from 'components/atoms';
import { Autoselect, AutoselectProps } from 'components/molecules';
import * as React from 'react';

const StandardiezedTextField = ({ style, children, ...rest }) => {
  return (
    <StandardTextField long={true} {...rest}>
      {children}
    </StandardTextField>
  );
};

interface CountryFieldProps extends AutoselectProps {
  label: string;
}

/**
 * @todo delete this. this is used in register form
 *
 * This component had a purpose, after refactor it doens't .
 */
class CountryField extends React.Component<CountryFieldProps> {
  public render(): JSX.Element {
    return <Autoselect Component={StandardiezedTextField} {...this.props} />;
  }
}

export default CountryField;
