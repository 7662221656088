import { MAX_NO_TYPES } from '@gardenize/config';

export const getAreaTypes = ({ api, path }) => {
  return api
    .get('/area-type', { per_page: MAX_NO_TYPES })
    .then(response => path.success({ areaTypes: response }))
    .catch(error => path.error({ error }));
};

export const addAreaTypeItem = ({ state, props }) => {
  state.unshift('areaTypes.data.items', props.response);
};

export const updateAreaList = ({ state, props }) => {
  const areaTypesItems = state.get('areaTypes.data.items').slice();
  const areaTypeIndex = areaTypesItems.findIndex(item => item.id === props.id);
  areaTypesItems[areaTypeIndex] = props.response;
  state.set(`areaTypes.data.items`, areaTypesItems);
};

export const createAreaType = ({ api, props, path }) => {
  return api
    .post('/area-type', {
      name: props.name,
    })
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

export const updateAreaType = ({ api, props, path }) => {
  const { id, name } = props;

  return api
    .put(`/area-type/${id}`, { id, name })
    .then(response => path.success({ response }))
    .catch(error => path.error({ error }));
};

export const removeAreaType = ({ api, props, path }) => {
  return api
    .delete(`/area-type/${props.id}`)
    .then(response => path.success({ msg: response.result }))
    .catch(error => path.error({ error }));
};

export const removeAreaTypeById = ({ state, props }) => {
  const newState = state
    .get('areaTypes.data.items')
    .filter(item => item.id !== props.id);
  state.set('areaTypes.data.items', newState);
};
