import { Module as CerebralModule } from '@cerebral/fluent';

import model from './model';
import * as sequences from './sequences';
import { state, State } from './state';

export enum ViewMode {
  LIST,
  GRID,
}

export type FetchProps = {
  query: { startIndex: number; stopIndex: number };
};

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  unload: sequences.unload,
  setMode: sequences.setMode,

  fetch: sequences.fetch,
  fetchGrid: sequences.fetchGrid,

  search: sequences.search,

  create: sequences.create,
  createWithImage: sequences.createWithImage,
};

export const Module = CerebralModule<State, Signals>({
  model,
  state,
  signals,
});
