import { types } from "mobx-state-tree";
import { timestamp, timestamp_mobile } from "./common";
import { Profile } from "./Profile";

const Social = types.enumeration([
  "email_signup",
  "facebook",
  "googleplus",
  "apple"
]);

export const User = types.model({
  id: types.identifierNumber,
  username: types.string,
  email: types.string,
  social_network_type: Social,
  profile: Profile,
  phone_model: types.maybeNull(types.string),
  google_play_store_version: types.maybeNull(types.string),
  apple_app_store_version: types.maybeNull(types.string),
  last_login: types.number,
  firebase_token: types.maybeNull(types.string),
  last_open: types.maybeNull(types.number),
  share_counter: types.number,
  login_counter: types.number,
  update_counter: types.number,
  open_counter: types.number,
  count_plants: types.number,
  count_plants_photos: types.number,
  count_areas: types.number,
  count_areas_photos: types.number,
  count_events: types.number,
  count_events_photos: types.number,
  count_activity_types: types.number,
  is_premium: types.maybeNull(types.boolean),
  is_premium_until: types.maybeNull(types.number),
  premium: types.maybeNull(
    types.model({
      number_of_exports: types.number,
      subscription_source: types.maybeNull(types.string),
      active: types.boolean,
      package: types.maybeNull(
        types.model({
          reference_name: types.string
        })
      ),
      stripeCurrency: types.string
    })
  ),
  hash: types.string,
  os_version: types.maybeNull(types.string),
  multiple_emails_issue: types.boolean,
  email_not_set_issue: types.boolean,
  ...timestamp,
  ...timestamp_mobile
});

export type IUser = typeof User.Type;
