import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Grid } from 'components/atoms';
import * as React from 'react';

const styles = (_theme: Theme) =>
  createStyles({
    basic: {
      height: 450,
      width: '100%',
    },
    five: {
      padding: '4px 4px 4px 0px',
    },
  });

type Props = WithStyles<typeof styles>;

/**
 * DynamicLayout component is used to change layout of
 * the card depending on the number of children that it
 * renders.
 *
 * This is done by applying different classes to the container
 * and targeting children components.
 *
 * Children are expected not to define `width` and `height`.
 *
 * This is not truly dynamic as we support only 5 different layouts.
 * For dynamic layout check out:
 *  https://medium.com/google-design/google-photos-45b714dfbed1
 *
 */
class DynamicLayout extends React.Component<Props> {
  public render(): JSX.Element {
    const { classes, children } = this.props;

    return (
      <Grid
        container
        justify="space-between"
        spacing={8}
        classes={{ container: classes.basic }}
      >
        {layout(children)}
      </Grid>
    );
  }
}

const four = children => {
  const kids = React.Children.toArray(children);

  return (
    <>
      {kids.map((datum, index) => (
        <Grid item key={index} xs={12} md={6} style={{ height: 225 }}>
          {datum}
        </Grid>
      ))}
    </>
  );
};

const five = children => {
  const [featured, ...rest] = React.Children.toArray(children);

  // NOTE(zvrk): correction to look better on grid overview pages
  const padding = '4px 4px 4px 0px';

  return (
    <>
      <Grid item xs={12} md={6}>
        {featured}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justify="space-between"
        spacing={8}
        style={{ padding }}
      >
        {rest.map((datum, index) => (
          <Grid item key={index} xs={6} style={{ height: 225 }}>
            {datum}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const two = children => {
  const [first, second] = React.Children.toArray(children);
  return (
    <>
      <Grid item xs={12} md={6}>
        {first}
      </Grid>
      <Grid item xs={12} md={6}>
        {second}
      </Grid>
    </>
  );
};

const three = children => {
  const [featured, ...rest] = React.Children.toArray(children);

  return (
    <>
      <Grid item xs={12} md={6}>
        {featured}
      </Grid>
      <Grid item xs={12} md={6} container spacing={8}>
        {rest.map((datum, index) => (
          <Grid item key={index} xs={12} style={{ height: 225 }}>
            {datum}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const one = children => {
  return <>{children}</>;
};

/**
 * Catamorphosis of children to JSX.Element.
 * Transform based on children count
 *
 */
const layout = (children: React.ReactNode): JSX.Element => {
  const count = React.Children.count(children);

  switch (count) {
    case 1: {
      return one(children);
    }
    case 2: {
      return two(children);
    }
    case 3: {
      return three(children);
    }
    case 4: {
      return four(children);
    }
    case 5: {
      return five(children);
    }
    default:
      return five(children);
  }
};

export default withStyles(styles)(DynamicLayout);
