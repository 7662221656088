import { BranchContext, Context } from '@app/store/fluent';
import { ENTITIES_PER_SEARCH as per_page } from '@gardenize/config';
import { mapper } from '@gardenize/infinite';
import { IPlant } from '@gardenize/models';
import getUnixTime from 'date-fns/getUnixTime';
import pMemoize from 'p-memoize';

import { ViewMode } from './';

export function unload({ http }): void {
  pMemoize.clear(http.mget);
}

export const plantList = ({
  api,
  props,
  state,
  path,
}: BranchContext<
  { success: { total: number; items: IPlant[] } },
  { q?: string }
>): Promise<{ total: number; items: IPlant[] }> => {
  const query: Record<string, any> = {
    per_page,
  };

  const name = props.q || state.get('plants.ui.searchTerm');

  if (name) {
    query.name = name;
  }

  return api
    .get('/plant', query)
    .then(response =>
      path.success({ total: response._meta.totalCount, items: response.items }),
    );
};

export const plants = ({
  api,
  props,
  state,
  path,
}: BranchContext<
  { success: { total: number; items: IPlant[] } },
  { q?: string }
>): Promise<{ total: number; items: IPlant[] }> => {
  const query: Record<string, any> = {
    per_page: 5,
  };

  if (props.q) {
    query.name = props.q || state.get('plants.ui.searchTerm');
  }

  return api
    .get('/plant', query)
    .then(response =>
      path.success({ total: response._meta.totalCount, items: response.items }),
    );
};

export const loading = (isLoading: boolean) => ({ state }: Context): void => {
  state.set('plants.ui.loading', isLoading);
};

export const fetchMore = ({ api, props, state, path }) => {
  const { startIndex, stopIndex } = props.query;

  const items = state.get('plants.data.items');
  const mode = state.get('plants.ui.mode');

  const page = mapper(mode).page(v => !items.has(v), {
    start: startIndex,
    stop: stopIndex,
  });

  return api
    .get('/plant', { page })
    .then(response => path.success({ page, items: response.items }));
};

export const fetchMoreGrid = ({ api, props, state, path }) => {
  const { startIndex, stopIndex } = props.query;

  const query: any = {};

  const name = props.q || state.get('plants.ui.searchTerm');

  if (name) {
    query.name = name;
  }

  const items = state.get('plants.data.items');
  const mode = state.get('plants.ui.mode');

  const page = mapper(mode).page(v => !items.has(v), {
    start: startIndex,
    stop: stopIndex,
  });

  query.page = page;

  return api
    .mget('/plant/grid', query)
    .then(response => path.success({ page, items: response.items }));
};

export const mode = ({ state, props }: Context<{ mode: ViewMode }>): void => {
  state.set('plants.ui.mode', props.mode);
};

// NOTE(zvrk): All props get sent over the wire. This is BAD.
// Solve it with proper model handling.
export const createPlant = ({ api, props, path, i18n }) => {
  if (!props.name) {
    props.name = i18n.trans('new_plant_generic_name');
  }
  if (!props.date_timestamp) {
    props.date_timestamp = getUnixTime(new Date());
  }

  return api
    .post('/plant', { ...props })
    .then(response => path.success({ plant: response }));
};

export const navigateToPlant = ({ props, router }) => {
  router.navigate(`/plants/${props.plant.id}`);
};

export const createImage = ({ api, props, path }) => {
  return api
    .post(`/plant/upload-image/${props.plant.id}`, {
      image: props.image,
      date: getUnixTime(new Date()),
    })
    .then(response => path.success({ image: response.media }));
};
