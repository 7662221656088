import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (_theme: Theme) => createStyles({});

export interface DeleteDialogProps {
  open: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  onClose?: (a: any[] | any) => any;
  onConfirm?: (a: any[] | any) => any;
}

type Props = WithStyles<typeof styles> & WithTranslation & DeleteDialogProps;

class DeleteDialog extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      t,
      open,
      title,
      onConfirm,
      onClose,
      children,
      cancelLabel,
      confirmLabel,
    } = this.props;

    const cancelLabelText = cancelLabel || t('button_cancel');
    const confirmLabelText = confirmLabel || t('button_delete');

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <Typography>{children}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{cancelLabelText}</Button>
          <Button onClick={onConfirm} color="secondary" autoFocus>
            {confirmLabelText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const enchance = compose(withTranslation(), withStyles(styles));

export default enchance(DeleteDialog);
