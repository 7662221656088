import { types } from 'mobx-state-tree';
import { timestamp } from './common';

export const GlobalType = types.model({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  ...timestamp,
});

export type IGlobalType = typeof GlobalType.Type;
