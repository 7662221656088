import { loadApp } from '@app/store/factories';
import { debounce, set, wait, when } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as actions from './actions';
import * as globalActions from '../../actions';

export const load = loadApp([
  when(props`q`),
  {
    true: [set(state`areas.ui.searchTerm`, props`q`)],
    false: [set(state`areas.ui.searchTerm`, '')],
  },
  when(state`areas.ui.loading`),
  {
    true: [],
    false: [
      // NOTE(zvrk): {@see plant.load}
      set(state`areas.ui.loading`, true),
      // NOTE(zvrk): improved visuals
      wait(100),
      set(state`areas.data.items`, {}),
    ],
  },
  actions.areas,
  {
    success: [
      set(state`areas.data.total`, props`total`),
      set(state`areas.data.featured`, props`items`),
    ],
  },
  set(props`query`, { startIndex: 0, stopIndex: 5 }),
  set(props`page`, 1),
  actions.fetchMoreGrid,
  {
    success: [
      set(state`areas.data.items.${props`page`}`, props`items`),
      set(state`areas.data.loaded`, props`page`),
    ],
  },
  wait(200),
  set(state`areas.ui.loading`, false),
]);

export const unload = [
  set(state`areas.ui.loading`, true),
  set(state`areas.data.items`, {}),
  set(state`areas.data.total`, 0),
  set(state`areas.data.loaded`, null),
  actions.unload,
];

export const setMode = [set(state`areas.ui.mode`, props`mode`)];

export const fetch = [
  actions.fetchMore,
  {
    success: [
      set(state`areas.data.items.${props`page`}`, props`items`),
      set(state`areas.data.loaded`, props`page`),
    ],
  },
];

export const fetchGrid = [
  actions.fetchMoreGrid,
  {
    success: [
      set(state`areas.data.items.${props`page`}`, props`items`),
      set(state`areas.data.loaded`, props`page`),
    ],
  },
];

export const search = [
  set(state`areas.ui.searching`, true),
  debounce(200),
  {
    continue: [
      actions.areaList,
      {
        success: [set(state`areas.search.items`, props`items`)],
      },
    ],
    discard: [],
  },
  set(state`areas.ui.searching`, false),
];

export const create = [
  when(state`user.premium.active`),
  {
    true: [
      actions.createArea,
      {
        success: [actions.navigateToArea],
      },
    ],
    false: [globalActions.redirectToPayments],
  },
];

export const createWithImage = [
  set(state`areas.ui.uploading`, true),

  actions.createArea,
  {
    success: [
      actions.createImage,
      {
        success: [
          set(state`areas.ui.uploading`, false),
          ,
          actions.navigateToArea,
        ],
      },
    ],
  },
];
