import { Field } from '@gardenize/forms';
import { CheckboxWithLabel } from '@gardenize/forms/mui';
import * as React from 'react';

import { CheckboxProps, Grid } from '../';

interface FieldProps {
  value?: any;
  name: string;
}

export interface StandardCheckboxProps extends FieldProps {
  long?: boolean;
}
type Props = Partial<CheckboxProps> & StandardCheckboxProps;

export class StandardCheckbox extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { long, ...rest } = this.props;

    return (
      <Grid item xs={12} md={long ? 12 : 6} container>
        <Field component={CheckboxWithLabel} color="primary" {...rest} />
      </Grid>
    );
  }
}
