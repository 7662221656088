import { Signals, State } from '@app/store';
import { Controller } from '@cerebral/mobx-state-tree';

import store from './store';

let Devtools = null;

if (process.env.NODE_ENV !== 'production') {
  Devtools = require('cerebral/devtools').default;
}

export default Controller<State, Signals>(store, {
  devtools: Devtools && Devtools({ host: 'localhost:8383', reconnect: false }),
});
