import { State } from './state';

export function totalUnconnected(this: State): number {
  const unconnected = this.data.unconnected._meta;
  return unconnected ? unconnected.totalCount : 0;
}

export function numOfunconnectedPages(this: State): number {
  const PER_PAGE = 6;
  return Math.ceil(this.totalUnconnected / PER_PAGE);
}

export function totalConnectedPlants(this: State): number {
  const plants = this.data.plants._meta;
  return plants ? plants.totalCount : 0;
}

export function totalConnectedEvents(this: State): number {
  const events = this.data.events._meta;
  return events ? events.totalCount : 0;
}

export function hasPictures(this: State): boolean {
  const area = this.data.item;

  return area ? area.media.length > 0 : false;
}
