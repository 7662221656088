import messaging from '@app/utils/firebase';
import { FirebaseMessaging } from '@firebase/messaging-types';
import { Provider } from 'cerebral';
import constant from 'lodash-es/constant';
import { Result } from 'true-myth';

interface INotificationService {
  permission: () => Promise<boolean>;
  connect: () => Promise<string>;
  listen: (fpath: string) => void;
}

const ERRORS = {
  PERMISSION: 'No permission granted.',
  CONNECTION: 'Firebase connection error.',
};

// Extend native `Event` interface to support events
// recieved from service worker
interface Event {
  data: any;
}

const Service: INotificationService = {
  permission(): Promise<boolean> {
    if (Notification.permission === 'granted') return Promise.resolve(true);
    if (Notification.permission === 'denied') return Promise.resolve(false);
    if (!Notification.permission || Notification.permission === 'default') {
      messaging.value.requestPermission();
    }

    return Promise.resolve(false);
  },

  connect(): Promise<string> {
    return Promise.resolve(messaging.value.getToken());
  },

  listen(this: { context: any }): void {
    const verifyTag = (message: Event): boolean => {
      return message.data.tag === 'BG';
    };

    const handler = (messaging: FirebaseMessaging): void => {
      const HANDLERS = {
        FOREGROUND: 'push.foreground',
        BACKGROUND: 'push.background',
      };

      const foreground = this.context.controller.getSignal(HANDLERS.FOREGROUND);
      const background = this.context.controller.getSignal(HANDLERS.BACKGROUND);

      messaging.onMessage(foreground);
      // Firebase is triggering this handler as well. So when our tab is
      // in foreground notifications get shown twice. This is solved in
      // a simple way. All messages that we send from service worker are
      // tagged `{ tag: 'BG' }`.
      navigator.serviceWorker.addEventListener('message', event => {
        if (!verifyTag(event)) {
          return;
        }
        console.log({ event });
        background({ data: event.data });
      });
    };

    const loge = e => console.error(e);

    Result.match(
      {
        Ok: handler,
        Err: loge,
      },
      messaging,
    );
  },
};

export type Type = keyof INotificationService;
export default Provider(Service);
