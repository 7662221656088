import { IArea } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { TranslationFunction } from 'i18next';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { Overlay } from './';
import { IOverlay } from './types';

const areasAdapter = (
  t: TranslationFunction,
  area: IArea | undefined,
): IOverlay => {
  if (!area) {
    return;
  }

  return {
    title: area.name,
    subtitle: area.plantIds
      ? `${area.plantIds.length} ${t('plants')}`
      : `0 ${t('plants')}`,
    info: area.area_type_name,
  };
};

const styles = (theme: Theme) =>
  createStyles({
    hoverInfoOverlay: {},
  });

interface AreaOverlayProps {
  area: IArea;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}
type Props = WithStyles<typeof styles> &
  StyledComponent &
  WithTranslation &
  AreaOverlayProps;

class AreaOverlay extends React.Component<Props> {
  render() {
    const { t, area, classes, className } = this.props;
    const root = cs(classes.hoverInfoOverlay, {
      [className]: className,
    });

    return <Overlay className={root} data={areasAdapter(t, area)} />;
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(styles),
);

export default enhance(AreaOverlay);
