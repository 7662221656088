import { Grid, GridProps } from '@app/components/atoms';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';

const styles = (theme: Theme) => {
  const {
    breakpoints: { values, down },
  } = theme;

  return createStyles({
    container: {
      minWidth: '100vw',
      minHeight: '100vh',
      [down(values.md)]: {
        flexDirection: 'column',
      },
    },
  });
};

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}
type Props = WithStyles<typeof styles>;

const CenteredColumnContainer: React.SFC<GridProps> = ({
  children,
  ...rest
}) => {
  return (
    <Grid
      item
      container
      direction="column"
      justify="center"
      alignItems="center"
      {...rest}
    >
      {children}
    </Grid>
  );
};

class Split extends React.PureComponent<Props> {
  static Left: React.SFC<StyledComponent & GridProps> = ({
    children,
    ...rest
  }) => {
    return (
      <CenteredColumnContainer {...rest}>{children}</CenteredColumnContainer>
    );
  };

  static Right: React.SFC<StyledComponent & GridProps> = ({
    children,
    ...rest
  }) => {
    return (
      <CenteredColumnContainer {...rest}>{children}</CenteredColumnContainer>
    );
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        classes={{ container: classes.container }}
      >
        {this.props.children}
      </Grid>
    );
  }
}

const enhance = compose(withStyles(styles));

export default enhance(Split);
