import {
  FormLabelProps,
  StandardCheckbox,
  StandardCheckboxProps,
  Typography,
} from 'components/atoms';
import * as React from 'react';

interface CheckboxProps extends StandardCheckboxProps {
  label: string;
}

class Checkbox extends React.Component<CheckboxProps> {
  private labelConfig(text: string): FormLabelProps {
    return {
      Label: {
        label: <Typography variant="caption">{text}</Typography>,
      },
    };
  }

  public render(): JSX.Element {
    const { label, ...rest } = this.props;

    return (
      <StandardCheckbox
        {...this.labelConfig(label)}
        {...rest}
      />
    );
  }
}

export default Checkbox;
