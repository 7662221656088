import { Module as CerebralModule } from '@cerebral/fluent';

import * as getters from './getters';
import model from './model';
import * as sequences from './sequences';
import { state, State } from './state';

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  unload: sequences.unload,

  getUpcomingEvents: sequences.getUpcomingEvents,
  getPastEvents: sequences.getPastEvents,
  getEventsPerYear: sequences.getEventsPerYear,
  getAllEvents: sequences.getAllEvents,

  changeYear: sequences.changeYear,
  changeMonths: sequences.changeMonths,

  openEventDialog: sequences.openEventDialog,
  closeEventDialog: sequences.closeEventDialog,
  create: sequences.create,
  createEvent: sequences.createEvent,

  paginateUpcoming: sequences.paginateUpcoming,
  paginatePast: sequences.paginatePast,

  search: sequences.search,
  enter: sequences.enter,
};

export const Module = CerebralModule<State, Signals>({
  model,
  getters,
  state,
  signals,
});
