import { IProfile } from "@gardenize/models";
import { withFormik } from "formik";
import pick from "lodash-es/pick";
import { schema } from "./validators";
import { Props, Values, IPassword } from "./types";
import evolve from "ramda/src/evolve";
import equals from "ramda/src/equals";
import merge from "ramda/src/merge";

const initial: Values = {
  description: "",
  webpage: "",
  global_hardiness_zone: "1a",
  local_hardiness_zone: "",
  contact_email: "",
  phone_number: "",
  address: "",
  location_visibility: false,
  visibility: false,
  first_name: "",
  country: "",
  copy_data_settings: false
};

type CompleteForm = Values & IPassword;

enum CopyDataSettings {
  FORBIDDEN = "forbidden",
  ALLOWED = "allowed"
}

enum Visibility {
  PRIVATE = "private",
  PUBLIC = "public"
}

const to = (data: Values) => {
  const transform = {
    copy_data_settings: (val: boolean) =>
      val ? CopyDataSettings.ALLOWED : CopyDataSettings.FORBIDDEN,
    visibility: (val: boolean) =>
      val ? Visibility.PUBLIC : Visibility.PRIVATE,
    location_visibility: (val: boolean) =>
      val ? Visibility.PRIVATE : Visibility.PRIVATE
  };

  return merge(evolve(transform, data), {
    name: data.first_name
  });
};

const from = (data: IProfile): Values => {
  const transform = {
    copy_data_settings: equals("allowed"),
    location_visibility: equals("public"),
    visibility: equals("public")
  };

  return evolve(transform, data);
};

const resetPasswordFields = (resetter: Function): void => {
  const PASSWORD_FIELDS = ["old_password", "new_password", "confirm_password"];

  PASSWORD_FIELDS.forEach((field: string) => resetter(field, ""));
};

export const withProfile = withFormik<Props, Values>({
  enableReinitialize: true,
  validateOnBlur: true,
  validationSchema: schema,
  handleSubmit: (
    values: CompleteForm,
    { props: { onSubmit, onResetPassword }, ...rest }
  ) => {
    const { old_password, new_password, confirm_password, ...data } = values;

    onSubmit({ data: to(data), bag: rest });

    resetPasswordFields(rest.setFieldValue);
    onResetPassword({ data: { old_password, new_password }, bag: rest });
  },
  mapPropsToValues: (props): Values => {
    const { profile } = props;

    if (!profile.data) {
      return initial;
    }

    const data = pick(from(profile.data), Object.keys(initial));
    return { ...initial, ...data };
  }
});
