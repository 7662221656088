import { types } from 'mobx-state-tree';
import { flag, timestamp, timestamp_mobile } from './common';

export const Media = types.model({
  id: types.identifierNumber,
  name: types.string,
  type: types.string,
  size: types.number,
  ...timestamp,
  ...timestamp_mobile,
  note_red: types.maybeNull(types.string),
  note_blue: types.maybeNull(types.string),
  note_black: types.maybeNull(types.string),
  note_yellow: types.maybeNull(types.string),
  note_orange: types.maybeNull(types.string),
  note_white: types.maybeNull(types.string),
  note_green: types.maybeNull(types.string),
  note_pink: types.maybeNull(types.string),
  drawn: flag,
  // unix timestamp
  date: types.maybeNull(types.number),
  photo_note: types.maybeNull(types.string),
  favorite: flag,
  url: types.string,
  thumb: types.string,
  small_thumb: types.string,
  medium_thumb: types.string,
  large_thumb: types.string,
});

export type IMedia = typeof Media.Type;
