import { hashring } from '@app/utils/hashring';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Grid, Typography } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      left: theme.spacing.unit,
      top: theme.spacing.unit,
      borderRadius: theme.spacing.unit * 2,
      maxWidth: theme.spacing.unit * 15,
    },
    text: {
      color: 'white',
      padding: '0 4px',
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}
interface TypeTagProps extends StyledComponent {
  tag: string;
}

type Props = WithStyles<typeof styles> & TypeTagProps;

class TypeTag extends React.Component<Props> {
  public render(): JSX.Element {
    const { classes, className, style, tag } = this.props;

    const container = cs(classes.container, {
      [className]: className,
    });

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        zeroMinWidth
        classes={{ container }}
        style={{ backgroundColor: hashring(tag), ...style }}
      >
        <Typography noWrap title={tag} classes={{ root: classes.text }}>
          {tag}
        </Typography>
      </Grid>
    );
  }
}

export default withStyles(styles)(TypeTag);
