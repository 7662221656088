import { apple as APPLE } from '@gardenize/config';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Button } from 'components/atoms';
import Apple from 'mdi-material-ui/Apple';
import * as React from 'react';

const __DEV__ =
  process.env.NODE_ENV === 'development' && !process.env.PRODUCTION;

const styles = (theme: Theme) => {
  const {
    breakpoints: { down, values },
  } = theme;

  return createStyles({
    button: {
      width: '100%',
      height: 56,
      cursor: 'pointer',
      color: theme.palette.text.primary,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '15px',
      textAlign: 'center',
      textTransform: 'uppercase',
      backgroundColor: theme.palette.common.white,
    },
  });
};

interface Clickable {
  onClick?: (e: any) => void;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface AppleButtonProps extends Clickable, StyledComponent {
  onSuccess: (args: any[]) => void;
  onError: (args: any[]) => void;
}
type Props = WithStyles<typeof styles> & AppleButtonProps;

class AppleButton extends React.Component<Props> {
  constructor(props) {
    super(props);
    window.AppleID.auth.init({
      clientId: 'se.gardenize.web',
      scope: 'name email',
      redirectURI: __DEV__
        ? 'https://web-dev.gardenize.se'
        : 'https://web.gardenize.se',
      state: 'origin:web',
      usePopup: true,
    });
  }

  private singInApple = async () => {
    const { onSuccess, onError } = this.props;

    try {
      const response = await window.AppleID.auth.signIn();
      onSuccess({ accessToken: response.authorization.id_token });
    } catch (e) {
      onError(e);
      return;
    }
  };

  public render(): JSX.Element {
    return (
      <Button
        variant="outlined"
        onClick={this.singInApple}
        classes={{ root: this.props.classes.button }}
        data-testid="apple-btn"
      >
        <Apple style={{ marginRight: 8, color: APPLE.COLOR }} />
        Apple
      </Button>
    );
  }
}

export default withStyles(styles)(AppleButton);
