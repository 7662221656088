import * as yup from "yup";

export const schema = yup.object().shape({
  cost: yup.string(),
  size: yup.number(),
  quantity: yup.string(),
  event_date: yup.date().nullable(true),
  reminder_time: yup.date().nullable(true),
  activity_id: yup.number().required(),
  weight: yup.string(),
  reminder: yup.boolean(),
  note: yup.string(),
  time_used: yup.string()
});
