import { Module as CerebralModule } from '@cerebral/fluent';

import model from './model';
import * as sequences from './sequences';

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };
export type State = {};

const state: State = {};

const signals = {
  foreground: sequences.foreground,
  background: sequences.background,
  click: sequences.click,
};

export const Module = CerebralModule<State, Signals>({
  model,
  state,
  signals,
});
