import history from '@app/utils/history';
import { Provider } from 'cerebral';

interface IRouterService {
  redirect: (url: string) => void;
  navigate: (url: string) => void;
  goBack: () => void;
}

const Service: IRouterService = {
  redirect: url => {
    history.push(url);
  },
  navigate: url => {
    history.push(url);
  },
  goBack: () => {
    history.goBack();
  },
};

export type Type = keyof IRouterService;

export default Provider(Service);
