import { types } from 'mobx-state-tree';
import { timestamp } from './common';
import { Media } from './Media';

export const Supplier = types.model({
  id: types.identifierNumber,
  name: types.string,
  note: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  supplier_logo_id: types.maybeNull(types.number),
  link: types.maybeNull(types.string),
  media: types.maybeNull(types.array(Media)),
  ...timestamp,
});

export type ISupplier = typeof Supplier.Type;
