import * as React from 'react';

const Arrow = ({
  className,
  color = "#42281F",
  isOutlined = true,
  isOpen = false
}) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="arrow-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 8"
    fill={isOutlined ? "none" : color}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    id="arrow-svg"
    transform={isOpen ? 'rotate(180)' : null}
  >
    <title id="arrow-icon">Arrow</title>
    <path d="M1 1L7 7L13 1" />
  </svg>
);

export default Arrow;
