import * as yup from "yup";

export const schema = yup.object().shape({
  source: yup.string().required(),
  title: yup.string().required("delete_account_title_required_message"),
  message: yup.string().required("delete_account_reason_required_message"),
  email: yup
    .string()
    .email()
    .required("be_error_signup_email_required")
});