import { mobileRouteMap } from '@gardenize/config';

enum PushActions {
  NEW_PLANT = 'new_plant',
  NEW_AREA = 'new_area',
  NEW_EVENT = 'new_event',
  MY_GARDEN = 'my_garden',
  SET_LOCATION = 'set_location',
  PROFILE = 'profile',
}

/**
 * Branch out `sequence` based on `category`. Handling of different
 * categories is handled in a downstream sequence.
 *
 * NOTE(zvrk): extract this to config when possible
 * @const SUPPORTED_TYPES contains currently supported types of notification
 *
 */
export const handler = (path, category) => {
  const SUPPORTED_TYPES = ['link', 'standard'];

  if (SUPPORTED_TYPES.indexOf(category) !== -1) {
    return path[category]();
  }

  return path.default();
};

/**
 * Show `notification` and register click on it to
 * trigger sequence branching.
 */
export const show = ({ props, path }) => {
  const {
    data: { category, title, sender_photo, body },
  } = props;

  return notify(title, { body, icon: sender_photo }).then(() =>
    handler(path, category),
  );
};

/**
 * Handle background message.
 */
export const background = ({ props, path }) => {
  const {
    data: { category },
  } = props;

  return handler(path, category);
};

/**
 * Set current `badge` number.
 */
export const setBadge = ({ props, state }) => {
  const {
    data: { badge },
  } = props;

  state.set('notifications.ui.badge', +badge);
};

/**
 * Main path switch point. Once we determine that event is
 * in `SUPPORTED_TYPES`, we check link field and case sequence
 * branch on it, or emit default path.
 */
export const checkStandardLink = ({ props, path }) => {
  switch (props.data.single_link) {
    case PushActions.NEW_PLANT: {
      return path.createPlant();
    }
    case PushActions.NEW_AREA: {
      return path.createArea();
    }
    case PushActions.NEW_EVENT: {
      return path.createEvent();
    }
    default:
      return path.default();
  }
};

export const openInNewWindow = ({ props }) => {
  window.open(props.data.link, '_blank', 'noopener');
};

/**
 * If we determine that link is in our domain (in our map),
 * determine where to go. `mobileRouteMap` has that mapping.
 */
export const openInApp = ({ props, router }) => {
  const path = mobileRouteMap[props.data.single_link];
  router.navigate(path);
};

/**
 * Handle user clicking on notification in APP ( latest | notification page )
 */
export const click = ({ state, path, props }) => {
  const notification = findNotification(state, props.idx, props.latest);

  props.data = notification;

  return handler(path, notification.category);
};

/**
 * @private
 * Given state, and index find right notification from list of latest
 * notificationss.
 */
const findNotification = (state, idx, latest = true): any => {
  const notifications = latest
    ? state.get('notifications.data.latest.items')
    : state.get('notifications.data.page.items');

  return notifications[idx].notification;
};

/**
 * @private
 * Create new notification and return promise that resolves
 * when user clicks on it. It is used for showing native notifications
 * in `foreground` mode of `firebase`
 */
const notify = (
  title: string,
  options?: NotificationOptions,
): Promise<Event> => {
  const notification = new Notification(title, options);

  return new Promise(resolve => (notification.onclick = resolve));
};
