import { Field } from '@gardenize/forms';
import { Switch, SwitchProps } from '@gardenize/forms/mui';
import * as React from 'react';

import { Grid } from '../';

interface FieldProps {
  value?: any;
  name: string;
}

export interface StandardSwitchProps extends FieldProps, SwitchProps {
  long?: boolean;
}
type Props = StandardSwitchProps;

export class StandardSwitch extends React.Component<Props> {
  public render(): JSX.Element {
    const { adornment, long, justify, ...rest } = this.props;

    return (
      <Grid item xs={12} md={long ? 12 : 6} justify={justify} container>
        <Field component={Switch} color="primary" {...rest} />
      </Grid>
    );
  }
}
