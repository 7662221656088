import { withFormik } from "formik";

import { schema } from "./validators";
import { Props, Values } from "./types";

const initial: Values = {
  email: "",
  password: ""
};

export const withLogin = withFormik<Props, Values>({
  validationSchema: schema,
  validateOnBlur: false,
  handleSubmit: (values: Values, { props: { onSubmit }, ...rest }) => {
    onSubmit({ data: values, bag: rest });
  },
  mapPropsToValues: (_props: Props): Values => {
    return initial;
  }
});
