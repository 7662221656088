import requrePolyfills from '@app/utils/polyfills';
import * as React from 'react';
import { render } from 'react-dom';
import GoogleTagManager, { TagManagerArgs } from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './components';
import { PageError } from './components/pages';
import { AppProviders } from './providers';
import './utils/i18n';
import { google as GOOGLE } from '@gardenize/config';
if (process.env.GTM_ID) {
  GoogleTagManager.initialize({ gtmId: process.env.GTM_ID });
}

const renderApp = (el: HTMLElement) =>
  render(
    <GoogleOAuthProvider clientId={GOOGLE.ID}>
    <PageError>
      <AppProviders>
        <App />
      </AppProviders>
    </PageError>
    </GoogleOAuthProvider>,
    el,
  );

const boot = (el: HTMLElement) => {
  requrePolyfills().then(() => {
    try {
      renderApp(el);
    } catch (e) {
      console.error({ error: e });
    }
  });
};

if ((module as any).hot) {
  (module as any).hot.accept(['./components'], () => {
    renderApp(document.getElementById('app'));
  });
}

boot(document.getElementById('app'));
