import { Provider } from 'cerebral';

interface IStorageService {
  get(path: string): any;
  set(path: string, item: any): void;
  remove(path: string): void;
  clear(): void;
}

const Service: IStorageService = {
  get(path): string | null {
    return localStorage.getItem(path);
  },

  set(path, item): void {
    localStorage.setItem(path, item);
  },

  remove(path): void {
    localStorage.removeItem(path);
  },

  clear(): void {
    localStorage.clear();
  },
};

export type Type = keyof IStorageService;
export default Provider(Service);
