const isClient = typeof window === 'object';

const getSize = () => {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
};

const isMobile = (): boolean => {
  const size = getSize();

  return size.width < 960;
};

export { isMobile };
