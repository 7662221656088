import { types } from 'mobx-state-tree';
import { Media } from './Media';

const Relationship = types.model({
  follow_requested: types.boolean,
  following: types.boolean,
  pinned: types.maybeNull(types.boolean),
  requested_to_follow_you: types.boolean,
  following_you: types.boolean,
});

export const Profile = types.model({
  user_id: types.identifierNumber,
  visibility: types.enumeration(['private', 'public']),
  first_name: types.string,
  email: types.string,
  country: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  webpage: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  profileImage: types.maybeNull(Media),
  coverImage: types.maybeNull(Media),
  last_updated_data: types.maybeNull(types.Date),
  address: types.maybeNull(types.string),
  phone_number: types.maybeNull(types.string),
  contact_email: types.maybeNull(types.string),
  count_plants: types.maybeNull(types.number),
  count_areas: types.maybeNull(types.number),
  count_events: types.maybeNull(types.number),
  latitude: types.maybeNull(types.string),
  longitude: types.maybeNull(types.string),
  location_visibility: types.enumeration(['public', 'private']),
  restricted: types.boolean,
  relationship_status: Relationship,
  // maybeNull random string
  local_hardiness_zone: types.maybeNull(types.string),
  // maybeNull "1a" ... "13a"
  global_hardiness_zone: types.maybeNull(types.string),
  copy_data_settings: types.enumeration(['allowed', 'forbidden']),
  followers_counter: types.number,
  following_counter: types.number,
});

export type IProfile = typeof Profile.Type;
