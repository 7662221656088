import { LanguageOrigin } from '@app/store';
import { parallel } from 'cerebral';
import { set, toggle } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';
import * as profileActions from '../profile/actions';

import * as actions from './actions';
import { state as initialState } from './state';

// NOTE(zvrk): currently we are loading settings as part of
// `loadApp`, so there is no need to it here. We only set flip
// flag to notify UI that ui is finished loading
export const load = factories.loadApp([set(state`settings.ui.loading`, false)]);

export const unload = [set(state`settings`, initialState)];

export const edit = [
  factories.setLanguage('props'),
  parallel([
    profileActions.updateLanguage,
    actions.updateSettings,
    {
      success: [set(state`settings.data`, props`response`)],
    },
  ]),
];
