import axios from 'axios';
import { Provider } from 'cerebral';
import pMemoize from 'p-memoize';

const http = axios.create({ baseURL: process.env.API });

const HttpService = {
  get: http.get,
  mget: pMemoize(http.get, { maxAge: 2000 }),
  post: http.post,
  put: http.put,
  patch: http.patch,
  delete: http.delete,
};

export default Provider(HttpService);
