import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHRBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
const __DEV__ = process.env.NODE_ENV === 'development';

const instance = i18n
  .use(XHRBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'en-GB': ['en-US'],
      en: ['en-US'],
      sv: ['sv-SE', 'en-US'],
      de: ['de-DE', 'en-US'],
      da: ['da-DK', 'en-US'],
      it: ['it-IT', 'en-US'],
      no: ['no-NO', 'en-US'],
      es: ['es-ES', 'en-US'],
      fr: ['fr-FR', 'en-US'],
      dev: __DEV__ ? [] : ['en-US'],
      default: ['en-US'],
    },

    load: 'currentOnly',

    backend: {
      parse: data => {
        try {
          return JSON.parse(data);
        } catch (e) {}
      },
      loadPath: '/assets/locales/{{lng}}/gardenize-{{lng}}.json',
    },

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['navigator', 'localStorage'],
      caches: ['localStorage'],
    },
  });

export default instance;
