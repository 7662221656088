import * as React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const Style = {
  WIDTH: 500,
  HEIGHT: 40,

  COLOR: {
    PRIMARY: '#f3f3f3',
    SECONDARY: '#ecebeb',
  },
};

export const SwitchLoader: React.FunctionComponent<
  IContentLoaderProps
> = props => {
  return (
    <ContentLoader
      height={Style.HEIGHT}
      width={Style.WIDTH}
      speed={2}
      primaryColor={Style.COLOR.PRIMARY}
      secondaryColor={Style.COLOR.SECONDARY}
      {...props}
    >
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width={Style.WIDTH}
        height={Style.HEIGHT}
      />
    </ContentLoader>
  );
};
