import { TypeWidgetProps } from '@app/components/pages/details/elements';
import { Field } from '@gardenize/forms';
import * as React from 'react';

import { Grid, TextFieldProps } from '../';

interface FieldProps {
  onBlur: (e: any) => void;
  value: number;
  name: string;
}

interface StandardTypeProps extends FieldProps, TypeWidgetProps {
  long?: boolean;
}

type Props = Partial<TextFieldProps> & StandardTypeProps;

export class StandardTypeField extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { long, ...rest } = this.props;

    return (
      <Grid item xs={12} md={long ? 12 : 6}>
        <Field
          fullWidth={true}
          FormControlProps={{
            fullWidth: true,
          }}
          {...rest}
        />
      </Grid>
    );
  }
}
