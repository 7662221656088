import { IMedia } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { compose } from 'recompose';

const Loader = props => {
  const { rest } = props;

  const loaderStyle = { width: '100%', height: '100%' };
  const PRIMARY = '#f3f3f3';
  const SECONDARY = '#ecebeb';

  return (
    <ContentLoader
      speed={2}
      width={100}
      height={100}
      primaryColor={PRIMARY}
      secondaryColor={SECONDARY}
      style={loaderStyle}
      {...rest}
    >
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width={'100%'}
        height={'100%'}
        data-testid="image.loader"
      />
    </ContentLoader>
  );
};

const styles = (_theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      maxHeight: 442, // firefox && edge fix
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      maxHeight: 442, // firefox && edge fix
    },
  });

interface LoadableComponent {
  loading?: boolean;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

export interface ImageProps extends StyledComponent, LoadableComponent {
  media: IMedia[];
  featured?: boolean;
}

type Props = WithStyles<typeof styles> & ImageProps;
type State = {
  hover: boolean;
};

class Image extends React.Component<Props, State> {
  static displayName = 'Gardenize.Image';
  static defaultProps = {
    media: [],
    loading: false,
    featured: false,
  };

  readonly state = {
    hover: false,
  };

  private get img(): string {
    const { media } = this.props;

    if (!media.length) {
      return '/assets/images/placeholder_image.svg';
    }

    // take first image
    const [image] = media;

    if (!image) {
      return '/assets/images/placeholder_image.svg';
    }

    // take large thumb if exists
    if (image.large_thumb) {
      return image.large_thumb;
    }

    // fallback to url if exits
    if (image.url) {
      return image.url;
    }

    // return fallback in none of the above works
    return '/assets/images/placeholder_image.svg';
  }

  private onMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  private onMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  public render(): JSX.Element {
    const { loading, classes, className, style, children } = this.props;

    const root = cs(classes.container, {
      [className]: className,
    });

    // TODO(alex): props to change size; maybe classes
    if (loading) {
      return (
        <div
          className={root}
          style={{
            width: '100%',
            height: '100%',
            ...style,
          }}
        >
          <Loader />
        </div>
      );
    }

    return (
      <div
        className={root}
        style={{
          width: '100%',
          height: '100%',
          ...style,
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <img src={this.img} className={classes.image} />
        {children instanceof Function
          ? (children as Function)({ hover: this.state.hover })
          : children}
      </div>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  observer,
);

export default enhance(Image);
