import {
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import * as cs from 'classnames';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (theme: Theme) => {
  const {
    breakpoints: { values, down },
  } = theme;

  return createStyles({
    container: {
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 11,
      [down(values.md)]: {
        padding: `0 ${theme.spacing.unit * 3}px`,
        width: '100%',
        margin: 0,
      },
    },
    message: {
      marginTop: theme.spacing.unit * 2,
    },
  });
};

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface OtherSubscriptionWarning extends StyledComponent {}

type Props = WithStyles<typeof styles> &
  WithTranslation &
  OtherSubscriptionWarning;

class OtherSubscriptionWarning extends React.Component<Props> {
  public render(): JSX.Element {
    const { classes, className, style, subscription_source, t } = this.props;

    const SUBSCRIPTION_SOURCES = {
      google: {
        mainText: t('subscription_activated_using_different_platform').replace(
          '%@',
          t('subscription_source_google'),
        ),
      },
      apple: {
        mainText: t('subscription_activated_using_different_platform').replace(
          '%@',
          t('subscription_source_apple'),
        ),
      },
      gardenize: {
        mainText: t('subscription_activated_using_different_platform').replace(
          '%@',
          t('subscription_source_gardenize_gift'),
        ),
      },
    };

    const container = cs(classes.container, {
      [className]: className,
    });
    const root = cs(classes.message, {
      [className]: className,
    });

    return (
      <Grid container classes={{ container }} style={style}>
        <Grid item>
          {SUBSCRIPTION_SOURCES[subscription_source].mainText !== '' ? (
            <Typography classes={{ root }} variant="subtitle1" color="error">
              {SUBSCRIPTION_SOURCES[subscription_source].mainText}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

const enhance = compose(withTranslation(), withStyles(styles));

export default enhance(OtherSubscriptionWarning);
