import { AxiosError } from 'axios';
import { Provider } from 'cerebral';

import { AuthenticationError, InternalServerError } from '../errors';

interface IApiService {
  get: (
    path: string,
    query?: Record<string, any>,
    options?: any,
  ) => Promise<any>;

  post: (
    path: string,
    query?: Record<string, any>,
    options?: any,
  ) => Promise<any>;

  put: (
    path: string,
    query?: Record<string, any>,
    options?: any,
  ) => Promise<any>;

  delete: (
    path: string,
    query?: Record<string, any>,
    options?: any,
  ) => Promise<any>;

  patch: (
    path: string,
    query?: Record<string, any>,
    options?: any,
  ) => Promise<any>;

  request: (options?: any) => Promise<any>;
}
const API = '/v1';

export type Type = IApiService;

interface IAuthHeader {
  Authorization: string;
}

function createHeaders({ session }): Partial<IAuthHeader> {
  if (!session.exists()) {
    return {};
  }

  return { Authorization: `Bearer ${session.token()}` };
}

const handleError = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    throw new AuthenticationError(error);
  }

  switch (error.response.status) {
    case 0: {
      throw new AuthenticationError(error);
    }
    case 401: {
      throw new AuthenticationError(error);
    }
    case 500: {
      throw new InternalServerError(error.response.data);
    }
    default: {
      throw error;
    }
  }
};

const Service: IApiService = {
  get<T = any>(path, params): Promise<T> {
    return this.context.http
      .get(API + path, {
        params,
        headers: createHeaders(this.context),
      })
      .then(response => response.data)
      .catch(handleError);
  },

  mget<T = any>(path, params): Promise<T> {
    return this.context.http
      .mget(API + path, {
        params,
        headers: createHeaders(this.context),
      })
      .then(response => response.data)
      .catch(handleError);
  },

  post<T = any>(path, data, params): Promise<T> {
    return this.context.http
      .post(API + path, data, {
        params,
        headers: createHeaders(this.context),
      })
      .then(response => response.data)
      .catch(handleError);
  },

  put<T = any>(path, data, params, options): Promise<T> {
    return this.context.http
      .put(API + path, data, {
        params,
        headers: createHeaders(this.context),
      })
      .then(response => response.data)
      .catch(handleError);
  },

  patch<T = any>(path, data, params): Promise<T> {
    return this.context.http
      .patch(API + path, data, {
        params,
        headers: createHeaders(this.context),
      })
      .then(response => response.data)
      .catch(handleError);
  },

  delete<T = any>(path, params): Promise<T> {
    return this.context.http
      .delete(API + path, {
        params,
        headers: createHeaders(this.context),
      })
      .then(response => response.data)
      .catch(handleError);
  },

  request<T = any>(options): Promise<T> {
    return this.context.http
      .request({
        ...options,
        url: API + options.url,
        headers: createHeaders(this.context),
      })
      .then(response => response.data)
      .catch(handleError);
  },
};

export default Provider(Service);
