import { ENTITIES_PER_SEARCH as per_page } from '@gardenize/config';
import { mapper } from '@gardenize/infinite';
import pMemoize from 'p-memoize';
import getUnixTime from 'date-fns/getUnixTime';
export function unload({ http }): void {
  pMemoize.clear(http.mget);
}

export const areaList = ({ api, props, path }) => {
  const query: Record<string, any> = {
    per_page,
  };

  if (props.q) {
    query.name = props.q;
  }

  return api
    .get('/area', query)
    .then(response =>
      path.success({ total: response._meta.totalCount, items: response.items }),
    );
};

export const areas = ({ api, props, state, path }) => {
  const query: Record<string, any> = {
    per_page: 5,
  };

  if (props.q) {
    query.name = props.q || state.get('areas.ui.searchTerm');
  }

  return api
    .get('/area', query)
    .then(response =>
      path.success({ total: response._meta.totalCount, items: response.items }),
    );
};

export const fetchMore = ({ api, props, path, state }) => {
  const { startIndex, stopIndex } = props.query;

  const items = state.get('areas.data.items');
  const mode = state.get('areas.ui.mode');

  const page = mapper(mode).page(v => !items.has(v), {
    start: startIndex,
    stop: stopIndex,
  });

  return api
    .mget('/area', { page })
    .then(response => path.success({ page, items: response.items }));
};

export const fetchMoreGrid = ({ api, props, path, state }) => {
  const { startIndex, stopIndex } = props.query;

  const query: any = {};

  const name = props.q || state.get('areas.ui.searchTerm');

  if (name) {
    query.name = name;
  }

  const items = state.get('areas.data.items');
  const mode = state.get('areas.ui.mode');

  const page = mapper(mode).page(v => !items.has(v), {
    start: startIndex,
    stop: stopIndex,
  });

  query.page = page;

  return api
    .mget('/area/grid', query)
    .then(response => path.success({ page, items: response.items }));
};

export const createArea = ({ api, props, path, i18n }) => {
  if (!props.name) {
    props.name = i18n.trans('new_area_generic_name');
  }

  return api
    .post('/area', { ...props })
    .then(response => path.success({ area: response }));
};

export const navigateToArea = ({ props, router }) => {
  router.navigate(`/areas/${props.area.id}`);
};

export const createImage = ({ api, props, path }) => {
  return api
    .post(`/area/upload-image/${props.area.id}`, {
      image: props.image,
      date: getUnixTime(new Date()),
    })
    .then(response => path.success({ image: response.media }));
};
