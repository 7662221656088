import {
  StandardSelectField,
  StandardSelectFieldProps,
} from 'components/atoms';
import * as React from 'react';

import { SelectLoader } from './loaders';

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface LoadableComponent {
  loading?: boolean;
}

export interface LoadableSelectProps
  extends StyledComponent,
    LoadableComponent,
    StandardSelectFieldProps {
  loader?: JSX.Element;
}

type Props = LoadableSelectProps;

class LoadableSelect extends React.Component<Props> {
  private get loader(): JSX.Element {
    const { loader, className, style } = this.props;

    if (loader) {
      return loader;
    }

    return (
      <SelectLoader
        className={className}
        style={{ width: '100%', paddingBottom: 16, ...style }}
      />
    );
  }

  public render(): JSX.Element {
    const { loading, loader, items, children, ...rest } = this.props;

    if (loading) {
      return this.loader;
    }

    return <StandardSelectField {...rest}>{children}</StandardSelectField>;
  }
}

export default LoadableSelect;
