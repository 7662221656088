import { IArea, IEvent, IMedia, IPlant } from '@gardenize/models';
import { Result } from 'true-myth';

type Resource = IEvent | IPlant | IArea;

export enum ImageQuality {
  LOW = 'small_thumb',
  MEDIUM = 'medium_thumb',
  HIGH = 'large_thumb',
  SOURCE = 'url',
}

export const imgSrc = (
  entity: Resource,
  quality = ImageQuality.LOW,
): string => {
  const image = getImageObject(entity);
  return Result.match(
    {
      Ok: image => mediaSrc(image, quality),
      Err: e => e.fallback,
    },
    image,
  );
};

export const mediaSrc = (
  image: IMedia | null,
  quality = ImageQuality.LOW,
  fallback = '/assets/images/drop_here_regular.svg',
): string => {
  if (!image) {
    return fallback
  }

  // try to get desired quality image
  if (image[quality]) {
    return image[quality];
  }

  // fallback to url if exits
  if (image.url) {
    return image.url;
  }

  // return fallback in none of the above works
  return fallback;
};

interface IPresentable {
  media: IMedia | IMedia[];
}

// @private
// Handle model that has an image at `media` path and array of media.
const getImageObject = (
  subject: IPresentable,
): Result<IMedia, { fallback: string }> => {
  const FALLBACK = '/assets/images/drop_here_regular.svg';
  const { media } = subject;

  if (!media) {
    return Result.err({ fallback: FALLBACK });
  }

  if (Array.isArray(media)) {
    if (!media.length) {
      return Result.err({ fallback: FALLBACK });
    }
    return Result.ok(media[0]);
  }

  return Result.ok(media);
};
