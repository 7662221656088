import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Grid } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) => {
  const { up, values } = theme.breakpoints;

  return createStyles({
    container: {
      [up(values.lg)]: {
        maxWidth: 960,
      },
      margin: '0 auto',
      width: '100%',
    },
  });
};
type Props = WithStyles<typeof styles>;

class Details extends React.Component<Props> {
  render() {
    const { classes, children } = this.props;
    return (
      <Grid container direction="column">
        {children}
      </Grid>
    );
  }
}

export default withStyles(styles)(Details);
