import { Computed, ComputedValue } from '@cerebral/fluent';
import { IArea, IEvent, IMeta, IPlant } from '@gardenize/models';

import * as getters from './getters';

type File = {
  name: string;
  loading: boolean;
};

export type State = {
  ui: {
    activeImage: number;
    lightbox: boolean;
    search: string;
    loading: {
      page: boolean;
      areas: boolean;
      events: boolean;
      unconnected: boolean;
    };
    files: File[];
    submitting: boolean;
    panel: boolean;
    dialog: {
      del: {
        image: boolean;
        entity: boolean;
      };
      upload: boolean;
      event: boolean;
      clone: boolean;
    };
    edit: boolean;
  };
  data: {
    item: IPlant | null;
    unconnected: {
      items: IArea[];
      _meta: IMeta;
    };
    areas: {
      items: IArea[];
      _meta: IMeta;
    };
    events: {
      items: IEvent[];
      _meta: IMeta;
    };
  };
  totalUnconnected: ComputedValue<number>;
  totalConnectedAreas: ComputedValue<number>;
  totalConnectedEvents: ComputedValue<number>;
  unconnectedPages: ComputedValue<number>;
};

export const state: State = {
  ui: {
    activeImage: 0,
    lightbox: false,
    search: '',
    loading: {
      page: true,
      areas: true,
      events: true,
      unconnected: true,
    },
    files: [],
    submitting: false,
    panel: false,
    dialog: {
      del: {
        image: false,
        entity: false,
      },
      upload: false,
      event: false,
      clone: false,
    },
    edit: false,
  },
  data: {
    item: null,
    unconnected: {
      items: [],
      _meta: null,
    },
    areas: {
      items: [],
      _meta: null,
    },
    events: {
      items: [],
      _meta: null,
    },
  },
  totalUnconnected: Computed(getters.totalUnconnected),
  totalConnectedAreas: Computed(getters.totalConnectedAreas),
  totalConnectedEvents: Computed(getters.totalConnectedEvents),
  unconnectedPages: Computed(getters.unconnectedPages),
};
