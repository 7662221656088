import { createMuiTheme } from '@material-ui/core/styles';

/**
 * Main gardenize theme. Extend here.
 */
const GARDENIZE_LIGHT = {
  PRIMARY: {
    LIGHT: '#7986cb',
    MAIN: '#3A844F',
    DARK: '#2E683E',
    CONTRAST_TEXT: '#F4F1ED',
  },
  COMMON: {
    WHITE: '#F4F1ED',
  },
  TEXT: {
    PRIMARY: '#42281F',
  },
  BUTTON: {
    BORDER_RADIUS: '4px',
  },
  SECONDARY: {
    MAIN: "#614C45"
  }
};

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Inter'],
  },
  palette: {
    primary: {
      light: GARDENIZE_LIGHT.PRIMARY.LIGHT,
      main: GARDENIZE_LIGHT.PRIMARY.MAIN,
      dark: GARDENIZE_LIGHT.PRIMARY.DARK,
      contrastText: GARDENIZE_LIGHT.PRIMARY.CONTRAST_TEXT,
    },
    common: {
      white: GARDENIZE_LIGHT.COMMON.WHITE,
    },
    secondary: {
      light: GARDENIZE_LIGHT.PRIMARY.LIGHT,
      main: GARDENIZE_LIGHT.SECONDARY.MAIN,
      dark: GARDENIZE_LIGHT.PRIMARY.DARK,
      contrastText: GARDENIZE_LIGHT.PRIMARY.CONTRAST_TEXT,
    },
    text: {
      primary: GARDENIZE_LIGHT.TEXT.PRIMARY
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: GARDENIZE_LIGHT.BUTTON.BORDER_RADIUS,
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: GARDENIZE_LIGHT.TEXT.PRIMARY,
      },
      selected: {
        color: GARDENIZE_LIGHT.PRIMARY.DARK,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: GARDENIZE_LIGHT.PRIMARY.CONTRAST_TEXT,
      },
    },
    MuiTypography: {
      h5: {
        fontFamily: 'Courier Prime',
      }
    },
    MuiInputBase: {
      root: {
        color: GARDENIZE_LIGHT.TEXT.PRIMARY,
      },
    },
    MuiInputLabel: {
      animated: {
        color: GARDENIZE_LIGHT.TEXT.PRIMARY,
      }
    }
  }
});
