/* tslint:disable */
import { State } from './';

function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

enum Unit {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

const DEFAULT_TIME_FORMAT = 'dd/MM/yyy';

type Converter = (value: number | null) => number;

export interface Data {
  converter: {
    to: Converter;
    from: Converter;
  };
  adornment: string;
}

const metersToCentimeters: Converter = (meter: number | null): number => {
  if (!meter) {
    return 0;
  }

  return meter * 100;
};

const centimetersToMeters: Converter = (centimeter: number | null): number => {
  if (!centimeter) {
    return 0;
  }

  return centimeter / 100;
};

const centimeterToInch: Converter = (centimeter: number | null): number => {
  if (!centimeter) {
    return 0;
  }

  return toFixed((centimeter * 100) / 2.54, 9);
};

const inchToCentimeter: Converter = (inch: number | null): number => {
  if (!inch) {
    return 0;
  }
  return parseFloat(toFixed((inch * 2.54) / 100, 9));
};

const squareMeterToSquareFeet: Converter = (meter: number | null): number => {
  if (!meter) {
    return 0;
  }
  const ft = meter * 10.7639;

  // round to 2 decimals
  return toFixed(ft, 9);
};

const squareFeetToSquareMeter: Converter = (ft: number | null): number => {
  if (!ft) {
    return 0;
  }
  const meter = ft / 10.7639; // conversion formula
  return meter;
};

const lbToKg: Converter = (lb: number | null): number => {
  if (!lb) {
    return 0;
  }
  const kg = lb * 0.45359237;

  return +kg.toFixed(4);
};

const kgToLb: Converter = (kg: number | null): number => {
  if (!kg) {
    return 0;
  }
  const lb = kg / 0.45359237;

  return +lb.toFixed(4);
};

const id = (val: number | null): number => val;

export function currency(this: State): string {
  return this.data ? this.data.currency : '$';
}

export function weight(this: State): Data {
  if (!this.data) {
    return {
      converter: {
        to: id,
        from: id,
      },
      adornment: 'Kg',
    };
  }

  switch (this.data.measuring_unit) {
    case Unit.METRIC: {
      return {
        converter: {
          to: id,
          from: id,
        },
        adornment: 'Kg',
      };
    }
    case Unit.IMPERIAL: {
      return {
        converter: {
          to: kgToLb,
          from: lbToKg,
        },
        adornment: 'Lb',
      };
    }
    default: {
      throw new Error('Unknown weigth type');
    }
  }
}

export function measurement(this: State): Data {
  if (!this.data) {
    return {
      converter: {
        to: metersToCentimeters,
        from: centimetersToMeters,
      },
      adornment: 'cm',
    };
  }

  switch (this.data.measuring_unit) {
    case Unit.METRIC: {
      return {
        converter: {
          to: metersToCentimeters,
          from: centimetersToMeters,
        },
        adornment: 'cm',
      };
    }
    case Unit.IMPERIAL: {
      return {
        converter: {
          to: centimeterToInch,
          from: inchToCentimeter,
        },
        adornment: 'in',
      };
    }
    default: {
      throw new Error('Unkown measuring unit');
    }
  }
}

export function areaMeasurement(this: State): Data {
  if (!this.data) {
    return {
      converter: {
        to: id,
        from: id,
      },
      adornment: 'm²',
    };
  }
  switch (this.data.measuring_unit) {
    case Unit.METRIC: {
      return {
        converter: {
          to: id,
          from: id,
        },
        adornment: 'm²',
      };
    }
    case Unit.IMPERIAL: {
      return {
        converter: {
          to: squareMeterToSquareFeet,
          from: squareFeetToSquareMeter,
        },
        adornment: 'ft²',
      };
    }
    default: {
      throw new Error('Unkown measuring unit');
    }
  }
}

export function dateFormat(this: State): string {
  if (!this.data) {
    return DEFAULT_TIME_FORMAT;
  }

  return this.data.date_format;
}
