import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (_theme: Theme) => createStyles({});

export interface DuplicateDialogProps {
  open: boolean;
  onClose?: (a: any[] | any) => any;
  onConfirm?: (a: any[] | any) => any;
}

type Props = WithStyles<typeof styles> & WithTranslation & DuplicateDialogProps;

class DuplicateDialog extends React.Component<Props> {
  public render(): JSX.Element {
    const { t, open, title, onConfirm, onClose, children } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <Typography>{children}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('button_cancel')}</Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            {t('button_clone')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const enchance = compose(withTranslation(), withStyles(styles));

export default enchance(DuplicateDialog);
