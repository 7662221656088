import { IMedia } from "@gardenize/models";
import { withFormik } from "formik";
import pick from "lodash-es/pick";

import { schema } from "./validators";
import { Props, Values, IStore } from "./types";

const initial: Values = {
  cost: "",
  size: "",
  quantity: "",
  weight: "",
  activity_id: "",
  date_timestamp: null,
  photo_date: null,
  reminder: false,
  future_event: 0,
  reminder_timestamp: null,
  time_used: "",
  note: ""
};

const createDate = (media: IMedia): Date | null => {
  // media array is empty
  if (!media) {
    return null;
  }

  const date = media.date || media.created_at || media.created_at_mobile;

  // miliseconds to seconds
  return date ? new Date(date * 1000) : null;
};

/**
 * Converts given timestamp to date object.
 */
const getReminderTimestamp = (timestamp: number | null) => {
  return timestamp ? new Date(timestamp * 1000) : new Date();
};

/**
 * NOTE(zvrk): Due to legacy and my fuckup there are values
 * form `quantity` and `time_used` on event that are non
 * integer ("null").
 */
const handleDirty = (value: string): string => {
  return value === "null" ? "0" : value;
};

const AVAILABLE_COLROS = [
  "black",
  "blue",
  "green",
  "orange",
  "pink",
  "red",
  "yellow",
  "white"
];

export const withEventInfo = withFormik<Props & IStore, Values>({
  validationSchema: schema,
  enableReinitialize: true,
  handleSubmit: () => {},
  mapPropsToValues: ({ event, picture, store: { settings } }): Values => {
    if (!event) {
      return initial;
    }

    const selectedPicture = event.media[picture];
    const converter = settings.weight.converter.to;

    const color_notes = {};
    let photo_note = "";
    if (selectedPicture) {
      AVAILABLE_COLROS.forEach(color => {
        const note = selectedPicture[`note_${color}`];
        if (note && note !== "") {
          color_notes[`note_${color}`] = note;
        }
      });
      photo_note = selectedPicture.photo_note;
    }

    return {
      ...initial,
      ...pick(event, Object.keys(initial)),
      reminder: !!event.reminder_timestamp,
      future_event: event.future_event,
      reminder_timestamp: getReminderTimestamp(event.reminder_timestamp),
      date_timestamp: new Date(event.date_timestamp * 1000),
      quantity: handleDirty(event.quantity),
      time_used: handleDirty(event.time_used),
      photo_date: createDate(selectedPicture),
      weight: converter(+event.weight) || 0,
      photo_note: photo_note || "",
      hasPicture: !!selectedPicture,
      ...color_notes
    };
  }
});
