import { types } from 'mobx-state-tree';
import { timestamp, timestamp_mobile } from './common';

export const ActivityType = types.model({
  id: types.identifierNumber,
  user_id: types.number,
  name: types.string,
  is_available_for_admin: types.boolean,
  ...timestamp,
  ...timestamp_mobile,
});

export type IActivityType = typeof ActivityType.Type;
