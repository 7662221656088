import loadable from 'loadable-components';

export const Profile = loadable(() =>
  import(/* webpackChunkName: "profile" */ './user/profile'),
);

export const Settings = loadable(() =>
  import(/* webpackChunkName: "settings" */ './user/settings'),
);

export const Payment = loadable(() =>
  import(/* webpackChunkName: "payment" */ './payment'),
);

export const PaymentHistory = loadable(() =>
  import(/* webpackChunkName: "payment" */ './payment-history'),
);

export const DeleteAccount = loadable(() =>
  import(/* webpackChunkName: "payment" */ './delete-account'),
);

export const Login = loadable(() =>
  import(/* webpackChunkName: "login" */ './login'),
);

export const CookiePolicy = loadable(() =>
  import(/* webpackChunkName: "login" */ './cookie-policy'),
);

export const Register = loadable(() =>
  import(/* webpackChunkName: "register" */ './register'),
);

export const Garden = loadable(() =>
  import(
    /* webpackChunkName: "garden" */ /* webpackPrefetch: true */ './garden'
  ),
);

export const Areas = loadable(() =>
  import(/* webpackChunkName: "areas" */ './overview/infinite/areas'),
);

export const Plants = loadable(() =>
  import(/* webpackChunkName: "plants" */ './overview/infinite/plants'),
);

export const Events = loadable(() =>
  import(/* webpackChunkName: "events" */ './overview/events'),
);

export const PlantDetail = loadable(() =>
  import(/* webpackChunkName: "plant-detail"*/ './details/plant'),
);

export const AreaDetail = loadable(() =>
  import(/* webpackChunkName: "area-detail"*/ './details/area'),
);

export const EventDetail = loadable(() =>
  import(/* webpackChunkName: "event-detail"*/ './details/event'),
);

export const Notifications = loadable(() =>
  import(/* webpackChunkName: "notifications" */ './notifications'),
);

export const UnsupportedScreenSize = loadable(() =>
  import(
    /* webpackChunkName: "unsupported-screen" */ './unsupported-screen-size'
  ),
);

export * from './error';
