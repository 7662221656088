import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import cs from 'classnames';
import { LinearProgress, StandardTextFieldProps } from 'components/atoms';
import Downshift from 'downshift';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import AutoselectButton from './autoselect-button';
import AutoselectField from './autoselect-field';

const Menu = React.lazy(() =>
  import(/* webpackChunkName: 'profile_country-menu' */ './menu'),
);

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      position: 'relative',
      width: '100%',
    },
    arrow: {
      position: 'absolute',
      right: 0,
      top: -theme.spacing.unit,
    },
    paper: {
      position: 'absolute',
      willChange: 'transform',
      transform: 'translateZ(0)',
      maxHeight: 200,
      overflowY: 'auto',
      zIndex: 1,
      marginTop: 5,
      left: 0,
      right: 0,
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
  });

interface State {
  isOpen: boolean;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

export interface AutoselectProps
  extends StyledComponent,
    StandardTextFieldProps {
  onChange?: (item: string) => void;
}

type Props = AutoselectProps & WithStyles<typeof styles>;

export class Autoselect extends React.PureComponent<Props, State> {
  readonly state: State = {
    isOpen: false,
  };

  private handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  /**
   * Propagate country choice if `onChange` is defined
   */
  private handleChange = (country: string) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(country);
    }
  };

  public render(): JSX.Element {
    const { t, i18n, tReady, ...other } = this.props;
    const { classes, className, style, value, ...rest } = other;

    const root = cs(classes.container, {
      [className]: className,
    });

    return (
      <Downshift onChange={this.handleChange}>
        {({
          isOpen,
          selectedItem,
          highlightedIndex,
          getInputProps,
          getMenuProps,
          getItemProps,
          getToggleButtonProps,
          inputValue,
        }) => (
          <div className={root} style={style}>
            <AutoselectField
              open={isOpen}
              value={value}
              InputProps={getInputProps()}
              button={
                <AutoselectButton
                  open={isOpen}
                  {...getToggleButtonProps({ onClick: this.handleClick })}
                />
              }
              {...rest}
            >
              <React.Suspense fallback={<LinearProgress />}>
                <Menu
                  input={typeof value === 'undefined' ? inputValue : value}
                  Item={{
                    selectedItem,
                    highlightedIndex,
                    itemProps: getItemProps,
                  }}
                  {...getMenuProps({ square: true, className: classes.paper })}
                />
              </React.Suspense>
            </AutoselectField>
          </div>
        )}
      </Downshift>
    );
  }
}
const enchance = compose(
  withStyles(styles),
  withTranslation(),
);
export default enchance(Autoselect);
