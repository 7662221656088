import { connect, FieldProps } from '@gardenize/forms';
import * as React from 'react';

import { CircularProgress } from '../';

import { Button, ButtonProps } from './';

export interface SubmitButtonProps extends ButtonProps {
  to: string;
}

type Props = FieldProps & SubmitButtonProps;

class SubmitButton extends React.Component<Props> {
  public render(): JSX.Element {
    const { formik, children, ...rest } = this.props;

    const submitting = formik.isSubmitting;

    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={submitting}
        {...rest}
      >
        {submitting ? <CircularProgress size={20} /> : children}
      </Button>
    );
  }
}

export default connect(SubmitButton);
