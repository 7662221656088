import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import SearchIcon from "components/atoms/icon/search";
import * as cs from 'classnames';
import { IconButton, InputBase, Paper } from 'components/atoms';
import Downshift from 'downshift';
import { observer } from 'mobx-react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (theme: Theme) => {
  const {
    breakpoints: { up, values },
    spacing: { unit },
  } = theme;

  const icon = {
    overflow: 'visible',
    height: '1em',
    verticalAlign: '-.125em',
    strokeWidth: 2,
  };

  return createStyles({
    searchIcon: {
      ...icon,
      fontSize: "16px",
    },
    inputRoot: {
      margin: `${unit * 4}px auto`,
      width: '95%',
      position: 'relative',
      [up(values.lg)]: {
        width: '100%',
      },
    },
    textField: {
      padding: `0 ${unit * 2}px 0 ${unit * 3}px`,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      borderRadius: "2px",
      height: unit * 6,
    },
    input: {
      height: '100%',
      width: '94%',
    },
    button: {
      float: 'right',
      height: "48px",
      width: "48px",
    },
  });
};

type EventHandler = (event: React.KeyboardEvent<HTMLInputElement>) => void;
type Key = string;

type CloseFn = () => void;

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface SearchProps extends StyledComponent {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: Record<Key, EventHandler>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, value: string) => void;
}
type Props = WithStyles<typeof styles> & WithTranslation & SearchProps;

class Search extends React.Component<Props> {
  private handleKeyDown = (close: CloseFn) => (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const { onKeyDown } = this.props;
    if (!onKeyDown || !onKeyDown[event.key]) {
      return;
    }

    (event.nativeEvent as any).preventDownshiftDefault = true;

    // NOTE(zvrk): we need to persist event so we can check
    // value in component that uses it.
    event.persist();

    onKeyDown[event.key](event);

    close();
  };

  private handleSearchClick = (close: CloseFn, value: string) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const { onClick } = this.props;

    if (!onClick) {
      return;
    }

    // NOTE(zvrk): we need to persist event so we can check
    // value in component that uses it.
    event.persist();

    onClick(event, value);

    close();
  };

  public render(): JSX.Element {
    const { t, i18n, tReady, ...other } = this.props;
    const {
      value,
      classes,
      onChange,
      className,
      style,
      children,
      ...rest
    } = other;

    const root = cs(classes.textField, {
      [className]: className,
    });

    return (
      <Downshift {...rest}>
        {({ inputValue, getInputProps, isOpen, closeMenu }) => (
          <div className={classes.inputRoot}>
            <Paper className={root}>
              <InputBase
                style={style}
                className={classes.input}
                placeholder={t('search')}
                {...getInputProps({
                  onChange,
                  onKeyDown: this.handleKeyDown(closeMenu),
                })}
              />
              <IconButton
                className={classes.button}
                onClick={this.handleSearchClick(closeMenu, inputValue)}
                aria-label="search button"
              >
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            </Paper>
            {isOpen ? children : null}
          </div>
        )}
      </Downshift>
    );
  }
}

const enchance = compose(
  withTranslation(),
  withStyles(styles),
  observer,
);

export default enchance(Search);
