import { IEvent } from '@gardenize/models';

export type State = {
  ui: {
    loading: boolean;
    new: {
      value: string;
      saved: boolean;
    };
  };
  data: {
    items: IEvent[];
  };
};

export const state: State = {
  ui: {
    loading: false,
    new: {
      value: '',
      saved: false,
    },
  },
  data: {
    items: [],
  },
};

