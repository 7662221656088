import { IArea, IEvent, IPlant } from '@gardenize/models';

export type State = {
  ui: {
    loading: boolean;
    searching: boolean;
    dialog: {
      event: boolean;
    };
  };
  data: {
    plants: IPlant[];
    areas: IArea[];
    events: IEvent[];
    count_plants: number;
    count_areas: number;
    count_events: number;
  };
  search: {
    plants: IPlant[];
    areas: IArea[];
    events: IEvent[];
    count_plants: number;
    count_areas: number;
    count_events: number;
  };
};

export const state: State = {
  ui: {
    loading: true,
    searching: false,
    dialog: {
      event: false,
    },
  },
  data: {
    plants: [],
    areas: [],
    events: [],
    count_plants: 0,
    count_areas: 0,
    count_events: 0,
  },
  search: {
    plants: [],
    areas: [],
    events: [],
    count_plants: 0,
    count_areas: 0,
    count_events: 0,
  },
};
