import { types } from "mobx-state-tree";

const Coupon = types.model({
  id: types.string,
  object: types.string,
  amount_off: types.maybeNull(types.number),
  created: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  duration: types.string,
  duration_in_months: types.maybeNull(types.number),
  livemode: types.boolean,
  max_redemptions: types.maybeNull(types.number),
  name: types.string,
  percent_off: types.maybeNull(types.number),
  redeem_by: types.maybeNull(types.number),
  times_redeemed: types.number,
  valid: types.boolean
});

const Discount = types.model({
  object: types.string,
  customer: types.string,
  end: types.maybeNull(types.number),
  start: types.maybeNull(types.number),
  subscription: types.string,
  coupon: Coupon
});

const Plan = types.model({
  id: types.string,
  object: types.string,
  active: types.boolean,
  amount: types.number,
  billing_scheme: types.string,
  created: types.number,
  currency: types.string,
  interval: types.string,
  interval_count: types.number,
  interval_name: types.string,
  livemode: types.boolean,
  nickname: types.maybeNull(types.string),
  product: types.string,
  usage_type: types.string
});

export const PaymentHistoryItem = types.model({
  id: types.string,
  card_data: types.union(
    types.array(types.maybeNull(types.string)),
    types.maybeNull(
      types.model({
        last4: types.string,
        brand: types.string
      })
    )
  ),
  object: types.string,
  amount_due: types.number,
  amount_paid: types.number,
  amount_remaining: types.number,
  application_fee: types.maybeNull(types.number),
  attempt_count: types.number,
  attempted: types.boolean,
  auto_advance: types.boolean,
  billing: types.string,
  billing_reason: types.string,
  charge: types.maybeNull(types.string),
  closed: types.boolean,
  currency: types.string,
  customer: types.string,
  date: types.number,
  description: types.maybeNull(types.string),
  discount: types.maybeNull(Discount),
  due_date: types.maybeNull(types.number),
  ending_balance: types.maybeNull(types.number),
  forgiven: types.boolean,
  hosted_invoice_url: types.maybeNull(types.string),
  invoice_pdf: types.maybeNull(types.string),
  lines: types.model({
    object: types.string,
    data: types.array(
      types.model({
        id: types.string,
        object: types.string,
        amount: types.number,
        currency: types.string,
        description: types.maybeNull(types.string),
        discountable: types.boolean,
        livemode: types.boolean,
        period: types.model({
          end: types.number,
          start: types.number
        }),
        plan: Plan,
        proration: types.boolean,
        quantity: types.number,
        subscription: types.maybeNull(types.string),
        subscription_item: types.string,
        type: types.string
      })
    ),
    has_more: types.boolean,
    total_count: types.number,
    url: types.string
  }),
  livemode: types.boolean,
  number: types.string,
  paid: types.boolean,
  period_end: types.number,
  period_start: types.number,
  starting_balance: types.number,
  statement_descriptor: types.maybeNull(types.string),
  subscription: types.string,
  status: types.string,
  subtotal: types.number,
  tax: types.maybeNull(types.number),
  tax_percent: types.maybeNull(types.number),
  total: types.number,
  webhooks_delivered_at: types.maybeNull(types.number)
});

export const PaymentCard = types.model({
  last4: types.string,
  brand: types.string
});

export type IPaymentCard = typeof PaymentCard.Type;

export type IPaymentHistoryItem = typeof PaymentHistoryItem.Type;
