import { ListItem, ListItemProps } from 'components/atoms';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface LinkListItem extends ListItemProps {
  to: string;
}

const LinkListItem: React.SFC<LinkListItem> = ({ to, children, ...rest }) => {
  const Linker = props => <Link to={to} {...props} />;
  return (
    <ListItem component={Linker} {...rest}>
      {children}
    </ListItem>
  );
};

export default LinkListItem;
