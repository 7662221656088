import { ENTITIES_PER_CARD } from '@gardenize/config';
import { IArea, IEvent, IPlant } from '@gardenize/models';
import remove from 'ramda/src/remove';

export enum Action {
  CONNECT,
  DISCONNECT,
}

type Entity = IPlant | IEvent | IArea;

export const connector = <T extends Entity>(
  action: Action,
  entities: T[],
  maxEntities = ENTITIES_PER_CARD,
) => (entity: T): T[] => {
  switch (action) {
    case Action.CONNECT: {
      return entities.length >= maxEntities
        ? [entity, ...entities.slice(0, -1)]
        : [entity, ...entities];
    }
    case Action.DISCONNECT: {
      const idx = entities.findIndex(e => e.id === entity.id);
      return remove(idx, 1, entities);
    }
    default:
      throw new Error('Unkown connector action');
  }
};
