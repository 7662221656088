import * as yup from "yup";

export const schema = yup.object().shape({
  cost: yup.string(),
  size: yup.number(),
  quantity: yup.string(),
  event_date: yup.date(),
  photo_date: yup.date(),
  weight: yup.string(),
  reminder: yup.boolean(),
  reminder_time: yup.date(),
  note: yup.string(),
});

