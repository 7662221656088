import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, IconButton, Portal } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    lightbox: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1100,
      background:
        'linear-gradient(left, rgba(1,1,1,0.7) 0%,rgba(0,0,0,1) 50%,rgba(1,1, 1,.7) 100%)',
    },
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: theme.spacing.unit * 3,
      zIndex: 1,
      color: 'white',
    },
  });

interface LightboxProps {
  /**
   * Boolean indicator whether image should be in fullscreen mode or not.
   */
  open: boolean;
  /**
   * On click event handler.
   */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

type Props = LightboxProps & WithStyles<typeof styles>;

const Lightbox: React.FunctionComponent<Props> = props => {
  const { open, classes, children, onClick, ...rest } = props;

  if (!open) {
    return null;
  }

  return (
    <Portal {...rest}>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.lightbox}
      >
        <IconButton className={classes.closeIcon} onClick={onClick}>
          <CloseIcon />
        </IconButton>
        {children}
      </Grid>
    </Portal>
  );
};

export default withStyles(styles)(Lightbox);
