import { BranchContext, Context } from '@app/store/fluent';
import { IEvent } from '@gardenize/models';

const setter = (item: string) => (val: any) => ({ state }: Context): void => {
  state.set(`garden.ui.${item}`, val);
};

type IGardenFilters = {
  name?: string;
};

export const getGarden = ({ api, path, props }) => {
  const query: IGardenFilters = {};

  if (props.q) {
    query.name = props.q;
  }

  return api
    .get('/web/home', query)
    .then(response => path.success({ garden: response }));
};

export const createEvent = ({
  props,
  api,
  path,
}: BranchContext<
  { success: { response: Partial<IEvent> } },
  { data: Partial<IEvent> }
>): Promise<{
  response: Partial<IEvent>;
}> => {
  return api
    .post('/event', props.data)
    .then(response => path.success({ response }));
};

export const navigateToEvent = ({ props, router }) => {
  router.navigate(`/events/${props.response.id}`);
};

export const setEventDialog = setter('dialog.event');
