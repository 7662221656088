import { types } from 'mobx-state-tree';

export const Meta = types.model({
  currentPage: types.number,
  pageCount: types.number,
  perPage: types.number,
  totalCount: types.number,
});

export type IMeta = typeof Meta.Type;
