import { IconButton } from 'components/atoms';
import Facebook from 'mdi-material-ui/Facebook';
import Instagram from 'mdi-material-ui/Instagram';
import Youtube from 'mdi-material-ui/Youtube';
import * as React from 'react';

import { Link } from '../types';

enum SocialNetwork {
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
}

interface SocialLinksProps {
  links: Link[];
}

type Props = SocialLinksProps;

const SocialLinks = (props: Props) => {
  const { links } = props;
  // catamorphism
  const icon = (name: string) => {
    switch (name) {
      case SocialNetwork.INSTAGRAM:
        return Instagram;
      case SocialNetwork.YOUTUBE:
        return Youtube;
      case SocialNetwork.FACEBOOK:
        return Facebook;
      default:
        throw new Error('Unkown icon name');
    }
  };

  const style = { color: '#F4F1ED' };

  return (
    <>
      {links.map((link, index) => {
        const Icon = icon(link.name);

        return (
          <a
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            href={link.href}
          >
            <IconButton>
              <Icon style={style} />
            </IconButton>
          </a>
        );
      })}
    </>
  );
};

export default SocialLinks;
