import { handleError, handleSuccess } from '@gardenize/forms';
import { isMobile } from '../utils/device';
import { availableLanguages } from '@app/utils/languages';
// We don't catch errors here. Propagate
// them to global error handler.
export const getUser = ({ api, path }) => {
  return api.get('/user/me').then(data => path.success({ user: data }));
};

export const createFeedback = ({ api, props, path }) => {
  return api
    .post('/feedback', { ...props.data })
    .then(response => path.success({ message: response.message }))
    .catch(error => path.error({ error }));
};

export const logIn = ({ api, path, props }) => {
  const { data, bag } = props;

  return api
    .post('/user/login', {
      username: data.email,
      password: data.password,
      phone_model: `web-${navigator.userAgent}`,
    })
    .then(
      handleSuccess(bag, response =>
        path.success({ token: response.auth_key }),
      ),
    )
    .catch(handleError(bag, error => path.error({ error })));
};

export const signUp = ({ api, path, props, state }) => {
  const { data, bag } = props;
  return api
    .post('/user/signup', {
      email: data.email,
      password: data.password,
      mail_newsletter: data.newsletter,
      country: data.country,
      language: state.get('language'),
      phone_model: `web-${navigator.userAgent}`,
    })
    .then(
      handleSuccess(bag, response =>
        path.success({ token: response.auth_key }),
      ),
    )
    .catch(handleError(bag, error => path.error({ error })));
};

export const resetPassword = ({ api, props, path }) => {
  return api
    .post('/user/request-password-reset', { ...props.data })
    .then(response => path.success({ message: response.message }))
    .catch(error => path.error({ error: error.translationKey }));
};

export const logOut = ({ api, path, session }) => {
  const active = session.exists();

  // NOTE(zvrk): There is a race condition in `factories.load` due to
  // parallel execution of couple of calls. When they will `fail` in
  // non-deterministic way with `401` error code. This code will then
  // be invoked. Only first one can and should be able to call logout.
  if (!active) {
    return path.success();
  }

  return api
    .post('/user/logout')
    .then(session.clear)
    .then(() => path.success())
    .catch(error => {
      session.clear();
      return path.error({ error });
    });
};

export const getLatestNotifications = ({ api, path }) => {
  return api
    .get('/notifications/latest')
    .then(response => path.success({ notifications: response }));
};

export const setJwtFromProps = ({ state, props, session }) => {
  state.set('jwt', props.token);
  session.make(props.token);
};

export const social = network => ({ props, api, path }) => {
  return api
    .post('/user/login-sso', {
      sso_type: network === 'google' ? 'googleplus' : network,
      sso_token: props.token,
      phone_model: `web-${navigator.userAgent}`,
    })
    .then(response => path.success({ token: response.auth_key }))
    .catch(error => path.error({ error }));
};

export const redirectAfterLogin = ({ props, router }) => {
  if (isMobile()) {
    if (props.user && props.user.premium && props.user.premium.active) {
      return router.redirect('/payment/history');
    }

    return router.redirect('/payment');
  }

  return router.redirect(window.location.pathname);
};

export const redirectToPayments = ({ router }) => {
  return router.redirect('/payment');
};

export const logError = ({ props }) => {
  console.log(props.error);
};

export const changeLanguage = ({ props, i18n }) => {
  i18n.changeLanguage(props.lang);
};

export const listenLanguage = ({ state, i18n }) => {
  // NOTE(alex): need mapping from en-US -> en
  i18n.on('languageChanged', lng => {
    state.set('language', lng);
  });
};

export const connect = ({ firebase, path }) => {
  return firebase
    .connect()
    .then(token => {
      return path.success({ token });
    })
    .catch(error => {
      return path.error({ error });
    });
};

export const permission = ({ firebase, path }) => {
  return firebase
    .permission()
    .then(path.success)
    .catch(path.error);
};

export const sendToken = ({ api, props, path }) => {
  return api
    .put('/user/update-firebase-token', { firebase_token: props.token })
    .then(_response => path.success())
    .catch(error => path.error({ error }));
};

export const listen = ({ props, firebase }) => {
  firebase.listen(props.handleMessage);
};

export const initNotifications = async ({ firebase, api, props }) => {
  try {
    const hasPermission = await firebase.permission();
    if (hasPermission) {
      const firebase_token = await firebase.connect();
      await api.put('/user/update-firebase-token', {
        firebase_token,
      });
      firebase.listen(props.handleMessage);
      return;
    }
  } catch (e) {
    console.error({ e });
  }
};

export const mapLanguage = ({ state, props }): void => {
  const lang = state.get('language');
  props.data.language = availableLanguages[lang];
};

export const handleTabVisiblity = ({ visibility }) => {
  visibility.listen();
};
