import { debounce, set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';
import { state as initialState } from './state';

export const load = factories.loadApp([
  actions.getGarden,
  {
    success: [
      set(state`garden.data`, props`garden`),
      set(state`garden.ui.loading`, false),
    ],
  },
]);

export const openEventDialog = actions.setEventDialog(true);
export const closeEventDialog = actions.setEventDialog(false);

export const unload = [set(state`garden`, initialState)];

export const search = [
  set(state`garden.ui.searching`, true),
  debounce(200),
  {
    continue: [
      actions.getGarden,
      {
        success: [set(state`garden.search`, props`garden`)],
      },
    ],
    discard: [],
  },
  set(state`garden.ui.searching`, false),
];

export const enter = [set(state`garden.data`, state`garden.search`)];

export const createEvent = [
  actions.createEvent,
  {
    success: [closeEventDialog, actions.navigateToEvent],
  },
];
