import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      margin: `${theme.spacing.unit} 0`,
    },
    input: {
      fontSize: 16,
    },
  });

type Props = WithStyles<typeof styles> & TextFieldProps;

const TextInput: React.SFC<Props> = ({ classes, InputProps,inputProps, ...rest }) => {
  return (
    <TextField
      InputProps={{
        fullWidth: true,
        classes: {
          root: classes.root,
          input: classes.input,
        },
        ...InputProps,
        inputProps
      }}
      {...rest}
    />
  );
};

export default withStyles(styles)(TextInput);
export { TextFieldProps };
