import { Field, FormikHandlers } from '@gardenize/forms';
import { TextField } from '@gardenize/forms/mui';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as React from 'react';

import { Grid, TextFieldProps } from '../';

interface FieldProps {
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  value: any;
  name: string;
}

interface StandardNumberInputProps extends FieldProps {
  adornment?: string;
}
type Props = Partial<TextFieldProps> & StandardNumberInputProps;

export class StandardNumberField extends React.PureComponent<Props> {
  private adornmentProps(adornment: string): {} {
    if (!adornment) {
      return {};
    }

    return {
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">{adornment}</InputAdornment>
        ),
      },
    };
  }

  /**
   * Prevent `INVALID_CHARS` to be typed in number input
   */
  private onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    const INVALID_CHARS = ['-', '+', 'e'];

    if (INVALID_CHARS.includes(e.key)) {
      e.preventDefault();
    }
  };

  public render(): JSX.Element {
    const { adornment, ...rest } = this.props;

    return (
      <Grid item xs={12} md={6}>
        <Field
          type="number"
          fullWidth={true}
          component={TextField}
          inputProps={{
            min: 0,
          }}
          onKeyDown={this.onKeyDown}
          {...this.adornmentProps(adornment)}
          {...rest}
        />
      </Grid>
    );
  }
}
