import { IPaymentOption } from '@gardenize/models';

import { State } from './state';

const sorter = (a, b: IPaymentOption): number => {
  if (a.amount < b.amount) {
    return -1;
  }

  if (a.amount > b.amount) {
    return 1;
  }

  return 0;
};

export function options(this: State): IPaymentOption[] {
  return this.data.options.filter(
    option => option.currency === this.ui.currency,
  );
}
