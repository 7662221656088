import { Computed, ComputedValue } from '@cerebral/fluent';
import { IArea, IEvent, IMeta, IPlant } from '@gardenize/models';

import * as getters from './getters';

type File = {
  name: string;
  loading: boolean;
};

export type State = {
  ui: {
    activeImage: number;
    lightbox: boolean;
    loading: {
      page: boolean;
      areas: boolean;
      plants: boolean;
      unconnected: {
        areas: boolean;
        plants: boolean;
      };
    };
    search: {
      areas: string;
      plants: string;
    };
    panel: {
      area: boolean;
      plant: boolean;
    };
    files: File[];
    submitting: boolean;
    dialog: {
      del: {
        image: boolean;
        entity: boolean;
      };
      upload: boolean;
    };
    edit: boolean;
  };
  data: {
    item: IEvent | null;
    unconnected: {
      areas: {
        items: IArea[];
        _meta: IMeta;
      };
      plants: {
        items: IPlant[];
        _meta: IMeta;
      };
    };
    areas: {
      items: IArea[];
      _meta: IMeta;
    };
    plants: {
      items: IPlant[];
      _meta: IMeta;
    };
  };
  hasPictures: ComputedValue<boolean>;
  connectedAreas: ComputedValue<number>;
  connectedPlants: ComputedValue<number>;
  unconnectedAreas: ComputedValue<number>;
  unconnectedPlants: ComputedValue<number>;
};

export const state: State = {
  ui: {
    activeImage: 0,
    lightbox: false,
    loading: {
      page: true,
      areas: true,
      plants: true,
      unconnected: {
        areas: true,
        plants: true,
      },
    },
    search: {
      areas: '',
      plants: '',
    },
    files: [],
    submitting: false,
    panel: {
      area: false,
      plant: false,
    },
    dialog: {
      del: {
        image: false,
        entity: false,
      },
      upload: false,
    },
    edit: false,
  },
  data: {
    item: null,
    unconnected: {
      areas: {
        items: [],
        _meta: null,
      },
      plants: {
        items: [],
        _meta: null,
      },
    },
    areas: {
      items: [],
      _meta: null,
    },
    plants: {
      items: [],
      _meta: null,
    },
  },
  hasPictures: Computed(getters.hasPictures),
  connectedAreas: Computed(getters.unconnectedAreas),
  connectedPlants: Computed(getters.unconnectedPlants),
  unconnectedAreas: Computed(getters.unconnectedAreas),
  unconnectedPlants: Computed(getters.unconnectedPlants),
};
