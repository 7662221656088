import { StoreContext } from '@app/store';
import { Form, withLogin } from '@gardenize/forms';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Button, StandardTextField, SubmitButton } from 'components/atoms';
import { inject } from 'mobx-react';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';

interface LoginProps {
  toggleDialog: () => void;
}

const styles = (theme: Theme) => {
  const {
    breakpoints: { down, up, values },
    spacing: { unit },
  } = theme;

  return createStyles({
    button: {
      height: 56,
      color: theme.palette.common.white,
      width: '100%',
    },
    input: {
      width: '100%',
      margin: `${unit}px 0`,
    },
    forgotten: {
      margin: `32px 0 !important`,
      alignSelf: 'flex-start',
    },
    login: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 70,

      [down(values.md)]: {
        width: '100%',
        paddingBottom: 30,
      },
    },
  });
};

type Props = WithTranslation &
  WithStyles<typeof styles> &
  StoreContext &
  LoginProps;

class Login extends React.Component<Props> {
  public render(): JSX.Element {
    const { t, classes, signals } = this.props;

    return (
      <Form className={classes.login}>
        <StandardTextField
          long={true}
          name="email"
          label={t('profile_email')}
          autoComplete="email"
          className={classes.input}
          data-testid="login-email"
        />
        <StandardTextField
          long={true}
          name="password"
          type="password"
          label={t('login_password')}
          autoComplete="off"
          className={classes.input}
          data-testid="login-password"
        />
        <Button
          className={classes.forgotten}
          disableElevation={true}
          color="primary"
          onClick={signals.openResetPasswordDialog}
          data-testid="reset-btn"
        >
          {t('reset_pass')}
        </Button>
        <SubmitButton className={classes.button}>
          {t('button_log_in')}
        </SubmitButton>
      </Form>
    );
  }
}

const enhance = compose(
  inject('signals'),
  withTranslation(),
  withLogin,
  withStyles(styles),
);

export default enhance(Login);
