import { StandardTextField, StandardTextFieldProps } from 'components/atoms';
import * as React from 'react';

class EmailField extends React.Component<StandardTextFieldProps> {
  public render(): JSX.Element {
    const { name, ...rest } = this.props;

    return (
      <StandardTextField
        long={true}
        name={name}
        autoComplete={name}
        {...rest}
      />
    );
  }
}

export default EmailField;
