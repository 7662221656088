import { types } from "mobx-state-tree";

import { Media } from "./Media";

export const Event = types.model({
  id: types.identifierNumber,
  user_id: types.number,
  activity_id: types.maybeNull(types.number),
  quantity: types.maybeNull(types.string),
  cost: types.maybeNull(types.string),
  weight: types.maybeNull(types.string),
  // maybeNull "" | "0.0"
  time_used: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  created_at: types.maybeNull(types.Date),
  update_at: types.maybeNull(types.Date),
  reminder_timestamp: types.maybeNull(types.number),
  future_event: types.maybeNull(types.number),
  created_at_mobile: types.maybeNull(types.number),
  updated_at_mobile: types.maybeNull(types.number),
  // unix timestamp
  date_timestamp: types.maybeNull(types.number),
  media: types.maybeNull(types.array(Media)),
  // maybeNull [ "234", "432" ]
  areaIds: types.maybeNull(types.array(types.string)),
  plantIds: types.maybeNull(types.array(types.string)),
  area_id: types.maybeNull(types.number),
  plant_id: types.maybeNull(types.number),
  activity_name: types.maybeNull(types.string)
});

export type IEvent = typeof Event.Type;
