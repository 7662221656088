import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { StandardTextField, TextFieldProps } from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (_theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      width: '100%',
      position: 'relative',
    },
    field: {
      width: '100%',
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface FieldProps extends StyledComponent {
  open?: boolean;
  button?: JSX.Element;
}

export type AutoselectFieldProps = FieldProps & TextFieldProps;

type Props = WithStyles<typeof styles> & WithTranslation & AutoselectFieldProps;

class AutoselectField extends React.Component<Props> {
  private get content(): JSX.Element {
    const { open, children } = this.props;

    if (!open) {
      return null;
    }

    return children;
  }

  public render(): JSX.Element {
    const { t, i18n, tReacty, ...other } = this.props;
    const {
      rootProps,
      button,
      classes,
      className,
      style,
      ...rest
    } = other;

    return (
      <>
        <StandardTextField
          long={true}
          label={t('profile_country')}
          autoComplete="country-name"
          className={classes.field}
          {...rest}
        />
        {button}
        {this.content}
      </>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(styles),
);

export default enhance(AutoselectField);
