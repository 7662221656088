import { types } from "mobx-state-tree";

import { Notification } from "./Notification";

export const MetaNotification = types.model({
  notification: Notification,
  read: types.integer,
  received: types.integer,
  fetched: types.integer
});

export type IMetaNotification = typeof MetaNotification.Type;
