import { StoreContext } from '@app/store';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as cs from 'classnames';
import { Avatar, Badge, IconButton } from 'components/atoms';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import BellIcon from "components/atoms/icon/bell";
import CrownIcon from "components/atoms/icon/crown";
import ArrowIcon from "components/atoms/icon/arrow";

type Props = WithStyles<typeof styles> &
  RouteComponentProps<any> &
  StoreContext;
type State = {
  options: boolean;
  notifications: boolean;
  optionsRef?: HTMLElement;
  notificationsRef?: HTMLElement;
};

const styles = (theme: Theme) => {
  const icon = {
    overflow: 'visible',
    height: '1em',
    verticalAlign: '-.125em',
    strokeWidth: 2,
  };

  return createStyles({
    arrowButton: {
      height: "40px",
      width: "40px",
    },
    iconBell: {
      ...icon,
      fontSize: "24px",
    },
    crownIcon: {
      ...icon,
      fontSize: "11px",
    },
    arrowIcon: {
      ...icon,
      fontSize: "9px",
    },
    box: {
      flexBasis: 120,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: 0,
    },
    avatar: {
      borderRadius: 0,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    link: {
      textDecoration: 'none',
    },
    img: {
      width: 40,
      height: 40,
      borderRadius: '50%',
    },
    avatarBorder: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
    },
    crownButton: {
      backgroundColor: '#CC9966',
      width: theme.spacing.unit * 5,
      height: theme.spacing.unit * 5,
      '&:hover': {
        backgroundColor: '#C4894F',
      },
    },
  });
};

class AvatarBox extends React.Component<Props> {
  static displayName = 'AvatarBox';
  readonly state: State = {
    options: false,
    notifications: false,
  };

  private userMenuContainerRef: HTMLElement;
  private notificationsMenuContainerRef: HTMLElement;

  private closeUserMenu = () => {
    this.setState({
      options: false,
    });
  };

  private closeNotificationsMenu = () => {
    this.setState({
      notifications: false,
    });
  };

  private toggleUserMenu = event => {
    event.preventDefault();
    const { currentTarget } = event;

    this.setState((state: State) => ({
      options: !state.options,
      optionsRef: currentTarget,
    }));
  };

  private toggleNotificationsMenu = (
    event,
    toggle = !this.state.notifications,
  ) => {
    event.preventDefault();
    const { currentTarget } = event;
    const {
      signals: { notifications },
    } = this.props;

    if (toggle) {
      notifications.getLatest();
    }

    this.setState((_state: State) => ({
      notifications: toggle,
      notificationsRef: currentTarget,
    }));
  };

  private setUserMenuRef = (ref: HTMLElement) => {
    this.userMenuContainerRef = ref;
  };

  private setNotificationsMenuRef = (ref: HTMLElement) => {
    this.notificationsMenuContainerRef = ref;
  };

  private renderAvatar = (
    user,
    classes,
    location: Location,
    loading = false,
  ) => {
    const hasUser = Boolean(user);
    const hasPicture = hasUser && user.profile && user.profile.profileImage;
    const bordered = location.pathname.includes('profile');

    const root = cs(classes.avatar, {
      [classes.avatarBorder]: bordered,
    });

    if (!hasUser) {
      return <Avatar loading={loading} src="/assets/images/profile.svg" />;
    }

    return hasPicture ? (
      <Link to={`/profile/me`} className={classes.link}>
        <Avatar
          alt={user.email}
          loading={loading}
          src={user.profile.profileImage.small_thumb}
          classes={{ root, img: classes.img }}
        />
      </Link>
    ) : (
      <Link to={`/profile/me`} className={classes.link}>
        <Avatar alt={user.email} loading={loading} />
      </Link>
    );
  };

  private get crown(): JSX.Element {
    const {
      classes,
      store: { user },
    } = this.props;

    return (
      <Grid item={true} xs={true}>
        <Link to={'/payment/history'} className={classes.link}>
          <IconButton
            disableRipple
            aria-label="payment"
            classes={{ root: classes.crownButton }}
          >
            <CrownIcon className={classes.crownIcon} isOutlined={false} color="#ffffff" />
          </IconButton>
        </Link>
      </Grid>
    );
  }

  private badge(count, classes): JSX.Element {
    const {
      store: { notifications },
    } = this.props;

    const counter = (count: number): JSX.Element => {
      if (!count) {
        return <BellIcon className={classes.iconBell} isOutlined={false} color="#707070" />;
      }

      return (
        <Badge badgeContent={count} color="primary">
          <BellIcon className={classes.iconBell} isOutlined={false} color="#707070" />
        </Badge>
      );
    };

    return (
      <Grid item={true} xs={true}>
        <IconButton
          aria-label={`${count} pending notifications`}
          buttonRef={this.setNotificationsMenuRef}
          onMouseUp={this.toggleNotificationsMenu}
        >
          {counter(count)}
        </IconButton>
      </Grid>
    );
  }

  public render(): JSX.Element {
    const {
      classes,
      location,
      store: {
        user,
        notifications: {
          data: {
            latest: { unreceived_notifications_count: badge },
          },
        },
      },
    } = this.props;

    return (
      <>
        <Grid
          item
          container={true}
          spacing={8}
          className={classes.box}
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          {this.crown}
          {this.badge(badge, classes)}
          <Grid item={true} xs={true}>
            {this.renderAvatar(user, classes, location)}
          </Grid>
          <Grid item={true} xs={true}>
            <IconButton
              aria-label="settings-menu"
              data-testid="settings-menu"
              buttonRef={this.setUserMenuRef}
              onMouseUp={this.toggleUserMenu}
              className={classes.arrowButton}
            >
              <ArrowIcon className={classes.arrowIcon} isOpen={this.state.options} />
            </IconButton>
          </Grid>
        </Grid>
        {(this.props.children as Function)(
          this.state,
          this.userMenuContainerRef,
          this.closeUserMenu,
          this.notificationsMenuContainerRef,
          this.closeNotificationsMenu,
        )}
      </>
    );
  }
}

const enhance = compose(
  withRouter,
  withStyles(styles),
  inject('store', 'signals'),
  observer,
);

export default enhance(AvatarBox);
