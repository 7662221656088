import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Grid, Typography } from 'components/atoms';
import * as React from 'react';
import { compose } from 'recompose';

const styles = (_theme: Theme) =>
  createStyles({
    container: {
      minHeight: 300,
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface SearchResultFallbackProps extends StyledComponent {}

type Props = WithStyles<typeof styles> & SearchResultFallbackProps;

class SearchResultsFallback extends React.Component<Props> {
  public render(): JSX.Element {
    const { classes, className, style, children } = this.props;

    const container = cs(classes.container, {
      [className]: className,
    });

    return (
      <Grid
        xs
        item
        container
        classes={{ container }}
        style={style}
        justify="center"
        alignItems="center"
      >
        <Typography variant="h4">{children}</Typography>
      </Grid>
    );
  }
}

const enchance = compose(withStyles(styles));

export default enchance(SearchResultsFallback);
