import { Computed, ComputedValue } from '@cerebral/fluent';
import { INotification } from '@gardenize/models';

import * as getters from './getters';

type IMetaNotification = {
  items: INotification[];
  unread_notifications_count: number;
  unreceived_notifications_count: number;
};

export type State = {
  ui: {
    loading: boolean;
    badge: number;
  };
  data: {
    latest: IMetaNotification;
    page: IMetaNotification;
  };
  total: ComputedValue<number>;
};

export const state: State = {
  ui: {
    loading: false,
    badge: 0,
  },
  data: {
    latest: {
      items: [],
      unread_notifications_count: 0,
      unreceived_notifications_count: 0,
    },
    page: {
      items: [],
      unread_notifications_count: 0,
      unreceived_notifications_count: 0,
    },
  },
  total: Computed(getters.total),
};
