import { Module as CerebralModule } from '@cerebral/fluent';

import * as getters from './getters';
import model from './model';
import * as sequences from './sequences';
import { state, State } from './state';

export interface SettingsProps {
  language: string;
  measuring_unit: 'metric' | 'imperial';
  temperature: 'Celsius' | 'Farenheit';
  currency: 'SEK' | 'EUR';
  date_format: 'dd/MM/yyy' | 'yyyy-MM-dd';
  mail_newsletter: number;
  tips: boolean;
}

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  unload: sequences.unload,
  edit: sequences.edit,
};

export const Module = CerebralModule<State, Signals>({
  model,
  getters,
  state,
  signals,
});
