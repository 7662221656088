import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import cs from 'classnames';
import { Arrow, IconButton, IconButtonProps } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      position: 'absolute',
      right: 8,
    },
    smallButton: {
      padding: theme.spacing.unit,
      fontSize: theme.typography.pxToRem(18),
    },
  });

interface ToggleButtonProps extends IconButtonProps {
  open: boolean;
}
const ToggleButton: React.FunctionComponent<ToggleButtonProps> = ({
  open,
  ...rest
}) => {
  return (
    <IconButton {...rest}>
      <Arrow isOpen={open} />
    </IconButton>
  );
};

interface CancelButtonProps extends IconButtonProps {}

const ResetButton: React.FunctionComponent<CancelButtonProps> = props => {
  return (
    <IconButton {...props}>
      <Cancel />
    </IconButton>
  );
};

export interface ActionButtonProps extends IconButtonProps {
  open: boolean;
  reset: boolean;
  onToggle: React.MouseEventHandler;
  onReset: React.MouseEventHandler;
}
type Props = WithStyles<typeof styles> & ActionButtonProps;

const ActionButton: React.FunctionComponent<Props> = ({
  reset,
  onReset,
  onToggle,
  classes,
  className,
  open,
  ...rest
}) => {
  const root = cs(classes.smallButton, {
    [className]: className,
  });

  if (reset) {
    return <ResetButton className={root} {...rest} onClick={onReset} />;
  }

  return (
    <ToggleButton
      open={open}
      onClick={onToggle}
      className={cs(classes.button, root)}
      {...rest}
    />
  );
};

export default withStyles(styles)(ActionButton);
