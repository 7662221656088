import { IArea } from '@gardenize/models';

type Page = number;

export enum ViewMode {
  LIST,
  GRID,
}

export type State = {
  ui: {
    uploading: boolean;
    loading: boolean;
    searching: boolean;
    mode: ViewMode;
  };
  data: {
    total: number;
    items: Record<Page, IArea[]> | null;
    featured: IArea[];
  };
  search: {
    items: IArea[];
  };
};

export const state: State = {
  ui: {
    uploading: false,
    loading: true,
    searching: false,
    mode: ViewMode.GRID,
  },
  data: {
    total: 0,
    items: {},
    featured: [],
  },
  search: {
    items: [],
  },
};
