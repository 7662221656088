import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Grid } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing.unit,
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface SearchResultsTitleProps extends StyledComponent {}
type Props = WithStyles<typeof styles> & SearchResultsTitleProps;

export const SearchResultsTitle: React.SFC<Props> = ({
  classes,
  className,
  style,
  children,
}) => {
  const container = cs(classes.container, {
    [className]: className,
  });

  return (
    <Grid container alignItems="center" classes={{ container }} style={style}>
      {children}
    </Grid>
  );
};

export default withStyles(styles)(SearchResultsTitle);
