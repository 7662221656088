import { BranchContext, Context } from '@app/store/fluent';
import { Action, connector } from '@app/utils/entity';
import { ENTITIES_PER_CARD } from '@gardenize/config';
import { IArea, IEvent, IMedia, IMeta, IPlant } from '@gardenize/models';
import { toJS } from 'mobx';
import i18next from 'i18next';
import * as factories from '../../factories';
import getUnixTime from 'date-fns/getUnixTime';
const setter = (item: string) => (val: any) => ({ state }: Context): void => {
  state.set(`plant.ui.${item}`, val);
};

const metaIncr = (state, path: string): IMeta => {
  const meta: IMeta = toJS(state.get(path));
  return { ...meta, totalCount: meta.totalCount + 1 };
};

export const getPlant = ({
  api,
  props,
  state,
}: Context<
  { success: { response: IPlant } },
  { plantId: number; plant: IPlant }
>): Promise<void> => {
  return api
    .get(`/plant/${props.plantId}`)
    .then(response => state.set('plant.data.item', response));
};

export const setData = ({ state, props }: Context<{ plant: IPlant }>) => {
  state.set('plant.data.item', props.plant);
};

export const setLoading = (isLoading: boolean) => ({ state }: Context) => {
  state.set('plant.ui.loading.page', isLoading);
};

export const toggleFavouriteImage = ({
  props,
  api,
  state,
  path,
}: BranchContext<{ success: { image: IMedia } }, { id: number }>): Promise<{
  image: IMedia;
}> => {
  const media = state.get('plant.data.item.media');
  const img = media.find(m => m.id === props.id);
  return api
    .put(`/plant/update-image/${props.id}`, {
      ...img,
      favorite: img.favorite === 1 ? 0 : 1,
    })
    .then(response => path.success({ image: response.media }));
};

export const setImage = ({
  props,
  state,
}: Context<{ id: number; image: IMedia }>): void => {
  const media = state
    .get('plant.data.item.media')
    .map(m =>
      m.id === props.id
        ? { ...m, favorite: props.image.favorite }
        : { ...m, favorite: 0 },
    );
  state.set('plant.data.item.media', media);
};

export const setDeleteDialog = setter('dialog.del.image');
export const setDeleteEntityDialog = setter('dialog.del.entity');
export const setUploadDialog = setter('dialog.upload');
export const setEventDialog = setter('dialog.event');
export const setCloneDialog = setter('dialog.clone');

export const addFile = ({
  props,
  state,
}: Context<{ image: string; name: string }>): void => {
  state.push('plant.ui.files', {
    name: props.name,
    loading: true,
  });
};

type A = { success: { response: IMedia } };
type B = { file: string };

export const uploadImage = ({
  props,
  state,
  path,
  api,
}: BranchContext<A, B>): Promise<{ response: IMedia }> => {
  const id = state.get('plant.data.item.id');
  return api
    .post(`/plant/upload-image/${id}`, {
      image: props.file,
      date: getUnixTime(new Date()),
    })
    .then(response => path.success({ response: response.media }));
};

export const uploadDrawnImage = ({
  props,
  state,
  path,
  api,
}: BranchContext<A, B>): Promise<{ response: IMedia }> => {
  const id = state.get('plant.data.item.id');
  const images = state.get('plant.data.item.media');
  const { file, ...rest } = props;
  return api
    .post(`/plant/upload-image/${id}`, {
      image: file,
      drawn: 1,
      date: getUnixTime(new Date()),
      ...rest,
    })
    .then(response => {
      factories.notifications(state, i18next.t('image_uploaded'), 'success');
      return path.success({
        response: response.media,
        image: images.length,
      });
    });
};

export const setImageLoading = ({
  props,
  state,
}: Context<{ name: string }>): void => {
  const files = state.get('plant.ui.files');

  const mapper = file =>
    file.name === props.name ? { ...file, loading: false } : file;

  state.set('plant.ui.files', files.map(mapper));
};

export const setActiveImage = ({ props, state }) => {
  state.set('plant.ui.activeImage', props.idx);
};

export const deleteImage = ({
  props,
  state,
  api,
  path,
}: BranchContext<
  { success: { message: string } },
  { index: number }
>): Promise<{ message: string }> => {
  const images = state.get('plant.data.item.media');
  return api
    .delete(`/plant/remove-image/${images[props.index].id}`)
    .then(response => path.success({ message: response.message }));
};

export const deleteEntity = ({ api, state, path }) => {
  const id = state.get('plant.data.item.id');
  return api
    .delete(`/plant/${id}`)
    .then(response => path.success({ response }));
};

export const copyEntity = ({ api, state, path }) => {
  const id = state.get('plant.data.item.id');
  return api
    .post(`/plant/${id}/clone`)
    .then(response => path.success({ response }));
};

export const navigateToCopiedPlant = ({ state, router }) => {
  router.navigate(`/plants/${state.get('plant.data.item.id')}`);
};

export const update = ({
  props,
  api,
  state,
  path,
}: BranchContext<
  { success: { response: IPlant } },
  { plant: Partial<IPlant> }
>): Promise<{ response: IPlant }> => {
  const plant = state.get('plant.data.item');
  return api
    .put(`/plant/${plant.id}`, {
      ...plant,
      ...props.plant,
      ...props.plant.plant,
    })
    .then(response => path.success({ response }));
};

export const getRelatedAreas = ({
  api,
  props,
  state,
}: Context<{ plantId: number; page: number }>) => {
  const user = state.get('user.id');
  const plant = state.get('plant.data.item.id')
    ? state.get('plant.data.item.id')
    : props.plantId;
  const page = props.page ? props.page : 1;

  return api
    .get(`/garden/${user}/plants/${plant}/areas`, {
      page,
      per_page: 8,
    })
    .then(response => {
      state.set('plant.data.areas', response);
      state.set('plant.ui.loading.areas', false);
    });
};

export const getRelatedEvents = ({
  api,
  props,
  state,
}: Context<{ plantId: number; page: number }>) => {
  const user = state.get('user.id');
  const plant = props.plantId ? props.plantId : state.get('plant.data.item.id');
  const page = props.page ? props.page : 1;

  return api
    .get(`/garden/${user}/plants/${plant}/events`, {
      page,
      per_page: 8,
    })
    .then(response => {
      state.set('plant.data.events', response);
      state.set('plant.ui.loading.events', false);
    });
};

export const createEvent = ({
  props,
  state,
  api,
  path,
}: BranchContext<Response, { data: Partial<IEvent> }>): Promise<{
  response: string;
}> => {
  const plantId = state.get('plant.data.item.id');
  return api
    .post('/event', { ...props.data, plantIds: [plantId] })
    .then(response => path.success({ response }));
};

export const addEvent = ({
  props,
  state,
}: Context<{ response: string }>): void => {
  const events = toJS(state.get('plant.data.events'));

  const empty = events.items.length === 0;
  const maxEvents = events.items.length >= ENTITIES_PER_CARD;
  const zeroMeta = {
    currentPage: 1,
    pageCount: 1,
    perPage: 8,
    totalCount: 1,
  };

  // There is still place in card
  if (!maxEvents) {
    state.set('plant.data.events', {
      items: [props.response, ...events.items],
      _meta: empty ? zeroMeta : metaIncr(state, 'plant.data.events._meta'),
    });
    return;
  }

  // remove last item from card
  state.set('plant.data.events', {
    items: [props.response, ...events.items.slice(0, -1)],
    _meta: metaIncr(state, 'plant.data.events._meta'),
  });
};

export const getUnconnectedAreas = ({
  props,
  state,
  api,
}: Context<{ page: number; query }>): Promise<void> => {
  const user = state.get('user.id');
  const plant = state.get('plant.data.item.id');
  // get initial results
  const page = props.page ? props.page : 1;
  const per_page = 6; // as per design

  const params = {
    page,
    per_page,
  };

  const search = state.get('plant.ui.search');

  if (search) {
    params.name = search;
  }

  return api
    .get(`/garden/${user}/plants/${plant}/unrelated-areas`, params)
    .then(response => {
      state.set('plant.data.unconnected', response);
      state.set('plant.ui.loading.unconnected', false);
    });
};

export const dropPanelImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<
  { success: { response: IPlant } },
  { id: number }
>): Promise<{}> => {
  const plant = state.get('plant.data.item');
  const ids = plant.areaIds ? plant.areaIds : [];

  const areaIds = [String(props.id), ...ids];

  return api
    .put(`/plant/${plant.id}`, { ...plant, areaIds })
    .then(response => path.success({ response }));
};

export const dropCardImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<{ success: { response: IPlant } }, { id: number }>): Promise<{
  response: IPlant;
}> => {
  const plant = state.get('plant.data.item');
  const areaIds = plant.areaIds.filter(aid => +aid !== props.id);

  // NOTE(zvrk): to unlink entities you need to send -1; Go figure ¯\_(ツ)_/¯
  const newArea = { ...plant, areaIds: areaIds.length ? areaIds : -1 };

  return api
    .put(`/plant/${plant.id}`, newArea)
    .then(response => path.success({ response }));
};

export const addPanelHead = ({ props, state }) => {
  const areas = toJS(state.get('plant.data.areas.items'));
  const uareas = toJS(state.get('plant.data.unconnected.items'));
  const area = uareas.find((a: IArea) => a.id === props.id);

  const connect = connector<IArea>(Action.CONNECT, areas);
  const disconnect = connector<IArea>(Action.DISCONNECT, uareas);

  state.set('plant.data.unconnected.items', disconnect(area));
  state.set('plant.data.areas', {
    items: connect(area),
    _meta: metaIncr(state, 'plant.data.areas._meta'),
  });
};

export const addCardHead = ({ props, state }) => {
  const areas = toJS(state.get('plant.data.areas.items'));
  const uareas = toJS(state.get('plant.data.unconnected.items'));

  const area = areas.find((a: IArea) => a.id === props.id);

  const connect = connector<IArea>(Action.CONNECT, uareas, 6);
  const disconnect = connector<IArea>(Action.DISCONNECT, areas);

  state.set('plant.data.areas.items', disconnect(area));
  state.set('plant.data.unconnected', {
    items: connect(area),
    _meta: metaIncr(state, 'plant.data.unconnected._meta'),
  });
};

export const updatePhoto = ({ props, api, state, path }) => {
  const media = state.get('plant.data.item.media');
  const img = media[props.idx];

  return api
    .put(`/plant/update-image/${img.id}`, {
      ...img,
      ...props.data,
      date: props.date,
    })
    .then(response => path.success({ media: response.media }));
};

export const setPhotoByIndex = ({ props, state }) => {
  const media: IMedia[] = state
    .get('plant.data.item.media')
    .map((m, idx) => (idx === props.idx ? props.media : m));

  state.set('plant.data.item.media', media);
};
