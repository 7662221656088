import { StoreContext } from '@app/store';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Button, Modal, StandardCard } from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

const styles = () =>
  createStyles({
    container: {
      width: '30%',
      position: 'relative',
      top: '25%',
      margin: '0 auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    button: {
      position: 'unset'
    },
    message: {
      fontFamily: 'Inter'
    }
  });

export interface InitiateDeleteAccountDialogProps {
  open: boolean;
  onClose?: (a: any[] | any) => any;
  onSubmit: (values: any[] | any) => any;
  logOut: () => void
}

type Props = WithStyles<typeof styles> &
  WithTranslation &
  StoreContext &
  InitiateDeleteAccountDialogProps;

class InitiateDeleteAccountDialog extends React.Component<Props> {

  private get content(): JSX.Element {
    const { t, classes, onClose } = this.props;

    return (
      <div className={classes.content}>
        <p className={classes.message}>{t('delete_account_initiation_message')}</p>
        <Link to='/garden'>
        <Button onClick={onClose} color="primary" className={classes.button} >
          {t('ok')}
        </Button>
        </Link>
      </div>
    );
  }

  public render(): JSX.Element {
    const { open, classes, onClose } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StandardCard
          className={classes.container}
        >
          {this.content}
        </StandardCard>
      </Modal>
    );
  }
}

const enchance = compose(
  withTranslation(),
  withStyles(styles),
);

export default enchance(InitiateDeleteAccountDialog);