import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Grid, GridProps } from 'components/atoms';
import { FeedbackDialog, InitiateDeleteAccountDialog, PaymentErrorDialog } from 'components/molecules';
import { Footer, Header } from 'components/organisms';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { compose } from 'recompose';

const BACKGROUND = '/assets/images/background-paper.png';

const styles = (_theme: Theme) =>
  createStyles({
    global: {
      minHeight: '100vh',
      background: `url(${BACKGROUND})`,
      // background: `#F4F1ED`,
    },
    content: {
      flex: 1,
    },
  });

const ContentContainer: React.SFC<Partial<GridProps>> = ({
  children,
  ...rest
}) => {
  return (
    <Grid item container component="main" {...rest}>
      {children}
    </Grid>
  );
};

type Props = WithStyles<typeof styles>;

class Application extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      children,
      classes,
      store: { ui },
      signals: { closeFeedbackDialog, createFeedback, closePaymentErrorDialog, closeAccountDeletionDialog,logOut },
    } = this.props;

    return (
      <Grid
        container
        direction="column"
        classes={{ container: classes.global }}
      >
        <Header />
        <FeedbackDialog
          open={ui.feedbackDialog}
          onClose={closeFeedbackDialog}
          onSubmit={createFeedback}
        />
        <InitiateDeleteAccountDialog
          open={ui.accountDeletionDialog}
          onClose={closeAccountDeletionDialog}
          logOut={logOut}
        />
        <PaymentErrorDialog
          open={ui.paymentErrorDialog}
          onClose={closePaymentErrorDialog}
        />
        <ContentContainer classes={{ container: classes.content }}>
          {children}
        </ContentContainer>
        <Footer />
      </Grid>
    );
  }
}

const enhance = compose(
  inject('store', 'signals'),
  withStyles(styles),
  observer,
);

export default enhance(Application);
