import * as yup from "yup";

export const schema = yup.object().shape({
  source: yup.string().required(),
  title: yup.string().required("feedback_message_title"),
  message: yup.string().required("feedback_message_required"),
  email: yup
    .string()
    .email()
    .required("be_error_signup_email_required")
});
