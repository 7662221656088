import { Notification } from '@gardenize/models';
import { types } from 'mobx-state-tree';

// Notification with metadata
const MetaNotification = types.model({
  items: types.array(
    types.model({
      notification: Notification,
      read: types.integer,
      received: types.integer,
      fetched: types.integer,
    }),
  ),
  unread_notifications_count: types.integer,
  unreceived_notifications_count: types.integer,
});

export type IMetaNotifications = typeof MetaNotification.Type;

export default {
  ui: types.model({
    loading: types.boolean,
    badge: types.number,
  }),
  data: types.model({
    latest: MetaNotification,
    page: MetaNotification,
  }),
};
