import { tags as tagColors } from '@gardenize/config';

import { hasher } from './murmurhash';

/**
 * Simple NON flexible moduo based consistant hashring.
 * Given a input `string` returns a `Color` from a ring.
 *
 * Same input yields same color.
 * NOTE(alex): add better type definition from return value;
 */
export const hashring = (name: string): string => {
  const NODES = tagColors.length;
  return tagColors[hasher(name) % NODES];
};
