import { imgSrc } from '@app/utils/image';
import { ItemTypes } from '@gardenize/config';
import { IEvent } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { EditOverlay, EventOverlay } from 'components/molecules';
import * as React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { compose } from 'recompose';

import { Image } from './';
import { ImageProps } from './image';
import TypeTag from './type-tag';

const styles = (_theme: Theme) =>
  createStyles({
    dragContainer: {
      width: '100%',
      height: '100%',
    },
    targerChildOverlay: {
      '& &$hoverEditOverlay': {
        opacity: 0,
      },
      '&:hover &$hoverEditOverlay': {
        opacity: 1,
      },
      '&:hover &$hoverInfoOverlay': {
        opacity: 0,
      },
    },

    hoverEditOverlay: {},
    hoverInfoOverlay: {},
  });

interface Draggable {
  draggable?: boolean;
}

interface DragProps {
  connectDragPreview?: any;
  connectDragSource?: any;
  isDragging?: boolean;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface EventImageProps extends Partial<ImageProps>, Draggable {
  event: IEvent;
  onHover?: JSX.Element;
}
type Props = WithStyles<typeof styles> &
  EventImageProps &
  DragProps &
  StyledComponent;

class EventImage extends React.Component<Props> {
  public componentDidMount(): void {
    const img = document.createElement('img');
    img.src = imgSrc(this.props.event);
    img.onload = () => this.props.connectDragPreview(img);
  }

  private get overlay(): JSX.Element {
    const { event, onHover, classes } = this.props;
    const editClasses = cs(
      classes.targerChildOverlay,
      classes.hoverEditOverlay,
    );

    const plantClasses = cs(
      classes.targerChildOverlay,
      classes.hoverInfoOverlay,
    );

    if (onHover) {
      return (
        <>
          {onHover}
          {event.activity_name && (
            <TypeTag className={plantClasses} tag={event.activity_name} />
          )}
          <EditOverlay className={editClasses} link={`/events/${event.id}`} />
        </>
      );
    }

    return (
      <>
        <EventOverlay className={plantClasses} event={event} />
        {event.activity_name && (
          <TypeTag className={plantClasses} tag={event.activity_name} />
        )}
        <EditOverlay className={editClasses} link={`/events/${event.id}`} />
      </>
    );
  }

  public render(): JSX.Element {
    const {
      event,
      classes,
      connectDragSource,
      connectDragPreview,
      isDragging,
      onHover,
      ...rest
    } = this.props;

    return connectDragSource(
      <div className={classes.dragContainer}>
        <Image
          className={classes.targerChildOverlay}
          media={event.media}
          {...rest}
        >
          {this.overlay}
        </Image>
      </div>,
    );
  }
}

const cardSource: DragSourceSpec<Props, { id: number }> = {
  beginDrag(props): { id: number } {
    return { id: props.event.id };
  },
  canDrag(props: EventImageProps): boolean {
    return Boolean(props.draggable);
  },
};

const collect: DragSourceCollector<DragProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const dragger = DragSource<EventImageProps, DragProps>(
  ItemTypes.CARDIMG,
  cardSource,
  collect,
);

const enchance = compose(
  dragger,
  withStyles(styles),
);

export default enchance(EventImage);
