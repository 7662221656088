import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { AppBar, GridProps } from 'components/atoms';
import { Container } from 'components/templates';
import * as React from 'react';

export interface HeaderContainerProps extends GridProps {}

type Props = WithStyles<typeof styles> & HeaderContainerProps;

const styles = (theme: Theme) => {
  const {
    breakpoints: { down, up, values },
    spacing: { unit },
  } = theme;

  return createStyles({
    appbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100vw',
      [up(values.md)]: {
        paddingLeft: theme.spacing.unit * 2,
      },
    },
  });
};

class HeaderContainer extends React.Component<Props> {
  static displayName = 'Header.Container';

  public render(): JSX.Element {
    const { classes, children, ...rest } = this.props;

    return (
      <AppBar
        color="default"
        position="sticky"
        classes={{ root: classes.appbar }}
      >
        <Container wide={true} wrap="nowrap" justify="space-between" {...rest}>
          {children}
        </Container>
      </AppBar>
    );
  }
}

export default withStyles(styles)(HeaderContainer);
