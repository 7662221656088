import { State } from './state';

export function totalUpcoming(this: State): number {
  const meta = this.data.upcoming._meta;

  return meta ? meta.totalCount : 0;
}

export function totalPast(this: State): number {
  const meta = this.data.past._meta;

  return meta ? meta.totalCount : 0;
}
