import { Computed, ComputedValue } from '@cerebral/fluent';
import { ISettings } from '@gardenize/models';

import * as getters from './getters';

export type State = {
  ui: {
    loading: boolean;
  };
  data: ISettings | null;
  measurement: getters.Data;
  weight: getters.Data;
  dateFormat: string;
  currency: ComputedValue<string>;
};

export const state: State = {
  ui: {
    loading: true,
  },
  data: null,
  dateFormat: Computed(getters.dateFormat),
  currency: Computed(getters.currency),
  measurement: Computed(getters.measurement),
  weight: Computed(getters.weight),
};
