import {
  AreaDetail,
  Areas,
  CookiePolicy, DeleteAccount, EventDetail,
  Events,
  Garden,
  Login, Notifications,
  Payment,
  PaymentHistory,
  PlantDetail,
  Plants,
  Profile, Register, Settings
} from 'components/pages';
import { NotFound, ServerError, Unauthorized } from 'components/pages/error';
import * as React from 'react';
import { Redirect } from 'react-router-dom';

const Redirector = (to: string) => () => <Redirect to={to} />;

export const ROUTES = {
  PUBLIC: [
    {
      path: '/',
      exact: true,
      component: Redirector('/login'),
    },
    {
      path: '/login',
      exact: false,
      component: Login,
    },
    {
      path: '/register',
      exact: true,
      component: Register,
    },
    {
      path: '/cookie-policy',
      exact: false,
      component: CookiePolicy,
    },
  ],
  PROTECTED: [
    {
      path: '/garden',
      component: Garden,
    },
    {
      path: '/plants',
      component: Plants,
    },
    {
      path: '/areas',
      component: Areas,
    },
    {
      path: '/events',
      component: Events,
    },
    {
      path: '/plants/:id',
      component: PlantDetail,
    },
    {
      path: '/areas/:id',
      component: AreaDetail,
    },
    {
      path: '/events/:id',
      component: EventDetail,
    },
    {
      path: '/profile/me',
      component: Profile,
    },
    {
      path: '/profile/settings',
      component: Settings,
    },
    {
      path: '/payment',
      component: Payment,
    },
    {
      path: '/payment/history',
      component: PaymentHistory,
    },
    {
      path: '/notifications',
      component: Notifications,
    },
    {
      path:'/delete-account',
      component: DeleteAccount
    },
    {
      path: '/error/unauthorized',
      component: Unauthorized,
    },
    {
      path: '/error/server',
      component: ServerError,
    },
    {
      path: '/profile/:userId',
      component: NotFound,
    },
    {
      path: '/profile/:userId/plants',
      component: NotFound,
    },
    {
      path: '/profile/:userId/areas',
      component: NotFound,
    },
    {
      path: '/profile/:userId/events',
      component: NotFound,
    },
    {
      path: '/profile/:userId/plants/:plantId',
      component: NotFound,
    },
    {
      path: '/profile/:userId/areas/:areaId',
      component: NotFound,
    },
    {
      path: '/profile/:userId/events/:eventId',
      component: NotFound,
    },
    
  ],
  NOT_FOUND: NotFound,
};
