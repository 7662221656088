import { SignalsContext } from '@app/store';
import history from '@app/utils/history';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import SettingsIcon from "components/atoms/icon/settings";
import ExitIcon from "components/atoms/icon/exit";

import {
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Popper,
  Typography,
} from 'components/atoms';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (theme: Theme) => {
  const icon = {
    overflow: 'visible',
    height: '1em',
    verticalAlign: '-.125em',
    strokeWidth: 2,
  };

  return createStyles({
    iconSettings: {
      ...icon,
      fontSize: "22px",
    },
    iconExit: {
      ...icon,
      fontSize: "18px",
    },
    iconExitWrap: {
      marginLeft: "3px",
    },
    container: {
      position: 'relative',
      top: theme.spacing.unit,
      width: 300,
    },
  });
};

interface OptionsPopoverProps {
  anchor: HTMLElement;
  open: boolean;
  close: () => void;
}
type Props = WithStyles<typeof styles> &
  WithTranslation &
  SignalsContext &
  OptionsPopoverProps;

class OptionsPopover extends React.Component<Props> {
  private handleItemClick = (item: 'settings' | 'logout') => () => {
    const {
      close,
      signals: { logOut },
    } = this.props;

    switch (item) {
      case 'settings': {
        close();
        history.push(`/profile/settings`);
        return;
      }
      case 'logout': {
        close();
        logOut();
        return;
      }
      default: {
        throw new Error('OptionsPopover::Impossible state');
      }
    }
  };

  public render(): JSX.Element {
    const { t, anchor, open, close, classes } = this.props;

    return (
      <Popper
        id="options-popper"
        placement="bottom-end"
        open={open}
        anchorEl={anchor}
        transition
        style={{ zIndex: 1500 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <ClickAwayListener onClickAway={close}>
              <Paper elevation={5} classes={{ root: classes.container }}>
                <List>
                  <ListItem
                    button={true}
                    onClick={this.handleItemClick('settings')}
                  >
                    <ListItemIcon>
                      <SettingsIcon className={classes.iconSettings} />
                    </ListItemIcon>
                    <Typography>{t('settings')}</Typography>
                  </ListItem>
                  <ListItem
                    button={true}
                    onClick={this.handleItemClick('logout')}
                  >
                    <ListItemIcon className={classes.iconExitWrap}>
                      <ExitIcon className={classes.iconExit} />
                    </ListItemIcon>
                    <Typography>{t('logout')}</Typography>
                  </ListItem>
                </List>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(styles),
  inject('store', 'signals'),
  observer,
);

export default enhance(OptionsPopover);
