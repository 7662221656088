import { Module as CerebralModule } from '@cerebral/fluent';

import * as getters from './getters';
import model from './model';
import * as sequences from './sequences';
import { state, State } from './state';

export type Signals = { [key in keyof typeof signals]: typeof signals[key] };

export type State = State;

const signals = {
  load: sequences.load,
  edit: sequences.edit,
  unload: sequences.unload,

  update: sequences.update,
  updatePhoto: sequences.updatePhoto,

  deleteEntity: sequences.deleteEntity,
  deleteImage: sequences.deleteImage,
  uploadImage: sequences.uploadImage,
  uploadDrawnImage: sequences.uploadDrawnImage,
  setActiveImage: sequences.setActiveImage,

  relatedAreas: sequences.fetchAreas,
  relatedPlants: sequences.fetchPlants,

  toggleFavouriteImage: sequences.toggleFavouriteImage,
  openDeleteDialog: sequences.openDeleteImageDialog,
  closeDeleteDialog: sequences.closeDeleteImageDialog,
  openDeleteEntityDialog: sequences.openDeleteEntityDialog,
  closeDeleteEntityDialog: sequences.closeDeleteEntityDialog,
  openUploadDialog: sequences.openUploadDialog,
  closeUploadDialog: sequences.closeUploadDialog,

  toggleAreaPanel: sequences.toggleAreaPanel,
  togglePlantPanel: sequences.togglePlantPanel,
  unconnectedAreas: sequences.fetchUnconnectedAreas,
  unconnectedPlants: sequences.fetchUnconnectedPlants,
  searchUnconnectedAreas: sequences.searchUnconnectedAreas,
  searchUnconnectedPlants: sequences.searchUnconnectedPlants,
  setLightbox: sequences.setLightbox,

  dropAreaCardImg: sequences.dropAreaCardImg,
  dropAreaPanelImg: sequences.dropAreaPanelImg,

  dropPlantCardImg: sequences.dropPlantCardImg,
  dropPlantPanelImg: sequences.dropPlantPanelImg,
};

export const Module = CerebralModule<State, Signals>({
  model,
  state,
  signals,
  getters,
});
