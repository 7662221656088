import * as React from "react";
import MuiSelect, {
  SelectProps as MuiSelectProps
} from "@material-ui/core/Select";
import { FieldProps } from "formik";
import { Omit } from "./types";

export interface SelectProps
  extends FieldProps,
    Omit<MuiSelectProps, "value"> {}

export const fieldToSelect = ({
  field,
  form: { isSubmitting },
  disabled = false,
  onBlur,
  ...props
}: SelectProps): MuiSelectProps => {
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>): void => {
    field.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  return {
    disabled: isSubmitting || disabled,
    onBlur: handleBlur,
    ...props,
    ...field
  };
};

export const Select: React.ComponentType<SelectProps> = (
  props: SelectProps
) => <MuiSelect {...fieldToSelect(props)} />;

Select.displayName = "FormikMaterialUISelect";
