import { set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';

export const load = [
  set(state`activityTypes.ui.loading`, true),
  actions.getActivityTypes,
  {
    success: [set(state`activityTypes.data`, props`activityTypes`)],
    error: [factories.addNotification('error', 'Couldnt fetch plant')],
  },
  set(state`activityTypes.ui.loading`, false),
];

export const create = [
  actions.createActivityType,
  {
    success: [actions.addActivityTypeItem],
    error: [factories.addNotification('error', 'Couldnt save type')],
  },
];

export const update = [
  actions.updateActivityType,
  {
    success: [actions.updateActivityList],
    error: [factories.addNotification('error', 'Couldnt update type')],
  },
];

export const remove = [
  actions.removeActivityType,
  {
    success: [actions.removeActivityTypeById],
    error: [factories.addNotification('error', 'Couldnt delete type')],
  },
];
