import { google, google as GOOGLE } from '@gardenize/config';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Button } from 'components/atoms';
import Google from 'mdi-material-ui/Google';
import * as React from 'react';
import { useGoogleLogin } from '@react-oauth/google';

const styles = (theme: Theme) => {
  const {
    breakpoints: { down, up, values },
    spacing: { unit },
  } = theme;

  return createStyles({
    button: {
      width: '100%',
      height: 56,
      cursor: 'pointer',
      color: theme.palette.text.primary,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '15px',
      textAlign: 'center',
      textTransform: 'uppercase',
      marginBottom: 10,
      backgroundColor: theme.palette.common.white,
    },
  });
};

interface Clickable {
  onClick?: (e: any) => void;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface GButtonProps extends Clickable, StyledComponent {}

interface GoogleButtonProps extends Clickable {
  onSuccess: (
    reponse: any,
  ) => void;
  onError: (args: any[]) => void;
}
type Props = WithStyles<typeof styles> & GoogleButtonProps;


const GoogleButton: React.FunctionComponent<Props> = props => {

  const login = useGoogleLogin({
    onSuccess: props.onSuccess,
  });
  return (
    <Button
      variant="outlined"
      style={props.style}
      onClick={() => login()}
      classes={{ root: props.classes.button }}
      data-testid="google-btn"
    >
      <Google style={{ marginRight: 8, color: GOOGLE.COLOR }} />
      Google
    </Button>

  );
}

export default withStyles(styles)(GoogleButton);
