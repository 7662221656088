import { Module as CerebralModule } from '@cerebral/fluent';

import { AuthenticationError, HttpError, InternalServerError } from './errors';
import * as factories from './factories';
import { isLoggedIn } from './getters';
import model from './model';
import * as ActivityTypes from './modules/activity-types';
import * as Area from './modules/area';
import * as AreaTypes from './modules/area-types';
import * as Areas from './modules/areas';
import * as Event from './modules/event';
import * as Events from './modules/events';
import * as Garden from './modules/garden';
import * as Notifications from './modules/notifications';
import * as Payment from './modules/payment';
import * as PaymentHistory from './modules/payment-history';
import * as Plant from './modules/plant';
import * as PlantTypes from './modules/plant-types';
import * as Plants from './modules/plants';
import * as Profile from './modules/profile';
import * as Push from './modules/push';
import * as Settings from './modules/settings';
import ApiProvider from './providers/Api';
import FirebaseProvider from './providers/Firebase';
import HttpProvider from './providers/Http';
import IntlProvider from './providers/i18n';
import RouterProvider from './providers/Router';
import SessionProvider from './providers/Session';
import StorageProvider from './providers/Storage';
import VisiblityProvider from './providers/Visibility';
import * as sequences from './sequences';
import { GlobalState, state } from './state';

export type SignalsContext = {
  signals?: Signals;
};

export type StateContext = {
  store?: State;
};

export type StoreContext = SignalsContext & StateContext;

export type Signals = { [key in keyof typeof signals]: typeof signals[key] } & {
  garden: Garden.Signals;
  area: Area.Signals;
  areas: Areas.Signals;
  areaTypes: AreaTypes.Signals;
  activityTypes: ActivityTypes.Signals;
  event: Event.Signals;
  events: Events.Signals;
  plant: Plant.Signals;
  plants: Plants.Signals;
  plantTypes: PlantTypes.Signals;
  payment: Payment.Signals;
  paymentHistory: PaymentHistory.Signals;
  profile: Profile.Signals;
  settings: Settings.Signals;
  userNotifications: Push.Signals;
  notifications: Notifications.Signals;
};

export type State = GlobalState & {
  garden: Garden.State;
  area: Area.State;
  areas: Areas.State;
  areaTypes: AreaTypes.State;
  activityTypes: ActivityTypes.State;
  event: Event.State;
  events: Events.State;
  plant: Plant.State;
  plants: Plants.State;
  plantTypes: PlantTypes.State;
  payment: Payment.State;
  paymentHistory: PaymentHistory.State;
  profile: Profile.State;
  settings: Settings.State;
  userNotifications: Push.State;
  notifications: Notifications.State;
};

const signals = {
  openFeedbackDialog: sequences.openFeedbackDialog,
  closeFeedbackDialog: sequences.closeFeedbackDialog,
  closePaymentErrorDialog: sequences.closePaymentErrorDialog,
  createFeedback: sequences.createFeedback,
  openResetPasswordDialog: sequences.openResetPasswordDialog,
  closeResetPasswordDialog: sequences.closeResetPasswordDialog,
  latestNotifications: factories.getLatestNotifications,
  logIn: sequences.signIn,
  signUp: sequences.signUp,
  logOut: sequences.logOut,
  facebook: sequences.facebook,
  google: sequences.google,
  apple: sequences.apple,
  socialError: sequences.socialError,
  resetPassword: sequences.resetPassword,
  changeLanguage: sequences.changeLanguage,
  loginLoad: sequences.loginLoad,
  loadDeleteAccount: sequences.loadDeleteAccount,
  createDeleteAccountFeedback: sequences.createDeleteAccountFeedback,
  openAccountDeletionDialog: sequences.openAccountDeletionDialog,
  closeAccountDeletionDialog: sequences.closeAccountDeletionDialog
};

export default CerebralModule<GlobalState, Signals>({
  model,
  state,
  signals,
  getters: {
    isLoggedIn,
  },
  catch: [
    [AuthenticationError, sequences.logOut],
    [HttpError, sequences.handleHttpError],
    [InternalServerError, factories.redirector('/error/server')],
    [
      Error,
      ctx => console.error(ctx.props.error),
      factories.redirector('/error/internal'),
    ],
  ],
  providers: {
    http: HttpProvider,
    api: ApiProvider,
    router: RouterProvider,
    storage: StorageProvider,
    i18n: IntlProvider,
    firebase: FirebaseProvider,
    session: SessionProvider,
    visibility: VisiblityProvider,
  },
  modules: {
    push: Push.Module,
    settings: Settings.Module,
    profile: Profile.Module,
    plants: Plants.Module,
    plant: Plant.Module,
    plantTypes: PlantTypes.Module,
    event: Event.Module,
    payment: Payment.Module,
    paymentHistory: PaymentHistory.Module,
    events: Events.Module,
    area: Area.Module,
    areas: Areas.Module,
    areaTypes: AreaTypes.Module,
    activityTypes: ActivityTypes.Module,
    garden: Garden.Module,
    notifications: Notifications.Module,
  },
});
