import * as React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";
import { Omit } from "./types";
import { WithTranslation, withTranslation } from "react-i18next";

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "error" | "name" | "onChange" | "value"> {}

export const fieldToTextField = ({
  field,
  form,
  variant,
  onBlur,
  onChange,
  disabled = false,
  ...other
}: TextFieldProps & WithTranslation): MuiTextFieldProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const { t, i18n, tReady, ...props } = other;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  const handleBlur = (e: React.ChangeEvent<HTMLElement>): void => {
    field.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLElement>): void => {
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };
  return {
    FormHelperTextProps: {
      style: {
        position: "absolute",
        bottom: "-0.9rem"
      }
    },
    ...props,
    ...field,
    onBlur: handleBlur,
    onChange:handleChange,
    // Hack to work around type issue
    // See: https://github.com/Microsoft/TypeScript/issues/28791
    variant: variant as any,
    error: showError,
    helperText: showError ? t(fieldError) : props.helperText,
    disabled: isSubmitting || disabled
  };
};

export const TranslatableTextField: React.ComponentType<
  TextFieldProps & WithTranslation
> = ({ children, ...props }: TextFieldProps & WithTranslation) => (
  <MuiTextField {...fieldToTextField(props)} children={children} />
);

export const TextField = withTranslation()(TranslatableTextField);

TextField.displayName = "FormikMaterialUITextField";
