import * as React from 'react';
import ContentLoader from 'react-content-loader';

export const HeaderLoader = props => (
  <ContentLoader
    height={40}
    width={160}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="40" height="40" />
    <rect x="50" y="0" rx="3" ry="3" width="160" height="40" />
  </ContentLoader>
);
