import { StoreContext } from '@app/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppSnackbar } from 'components/atoms';
import { SEO } from 'components/molecules';
import { PageError, UnsupportedScreenSize } from 'components/pages';
import { Shell } from 'components/templates';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { ROUTES } from './routes';

type Props = StoreContext;

// NOTE(zvrk): react-i18next was crashing
// application during loading. This is quick
// and dirty fix.
// In long term every translatable string or
// component that contains it should define
// fallback state when translations are being
// loaded.
const Noop = <></>;

const MOBILE_AVAILABLE_ROUTES = [
  '/login',
  '/register',
  '/payment',
  '/payment/history',
];

class App extends React.Component<Props> {
  // META components
  private get meta(): JSX.Element {
    return (
      <>
        <SEO />
        <CssBaseline />
        {MOBILE_AVAILABLE_ROUTES.includes(
          this.props.location.pathname,
        ) ? null : (
          <UnsupportedScreenSize />
        )}
      </>
    );
  }

  // AUTH protected shell
  private get protected(): JSX.Element {
    return (
      <Shell>
        <PageError>
          <Switch>
            {ROUTES.PROTECTED.map((route, idx) => (
              <Route
                key={idx}
                exact={true}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route component={ROUTES.NOT_FOUND} />
          </Switch>
        </PageError>
      </Shell>
    );
  }

  // PUBLIC pages
  private get public(): JSX.Element[] {
    return ROUTES.PUBLIC.map((route, idx) => (
      <Route
        key={idx}
        exact={route.exact}
        path={route.path}
        component={route.component}
      />
    ));
  }

  // NOTE(zvrk): not called atm, broken for
  // some reason
  private get snacks(): JSX.Element {
    const {
      store: { snacks },
    } = this.props;

    if (!snacks) {
      return null;
    }

    return snacks.map(
      (snack: { id: number; variant: string; title: string }) => (
        <AppSnackbar
          key={snack.id}
          variant={snack.variant}
          message={snack.title}
        />
      ),
    );
  }

  public render(): JSX.Element {
    return (
      <React.Suspense fallback={Noop}>
        {this.snacks}
        {this.meta}
        <Switch>
          {this.public}
          {this.protected}
        </Switch>
      </React.Suspense>
    );
  }
}

const enhance = compose(
  DragDropContext(HTML5Backend),
  // NOTE(zvrk): although we are not using `withRouter`
  // functions in component, this needs to stay here,
  // so that the component can register route transitions.
  withRouter,
  inject('store'),
  observer,
);

export default enhance(App);
