import * as cs from 'classnames';
import { Avatar, ListItemAvatar } from 'components/atoms';
import * as React from 'react';

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}
interface SearchResultsAvatarProps extends StyledComponent {
  src: string;
}

export const SearchResultsAvatar: React.SFC<SearchResultsAvatarProps> = ({
  src,
  className,
  style,
  children,
}) => {
  const root = cs({
    [className]: className,
  });

  return (
    <ListItemAvatar classes={{ root }} style={style}>
      <Avatar src={src}>{children}</Avatar>
    </ListItemAvatar>
  );
};
