import { createBrowserHistory } from 'history';

import logger from './logger';

const log = logger('history');

const history = createBrowserHistory();

history.listen(location => {
  log.info(location.pathname);
});

export default history;
