import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from 'components/atoms';
import { observer } from 'mobx-react';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';

const ListItemLoader = props => (
  <ContentLoader
    height={40}
    width={200}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="200" height="40" />
  </ContentLoader>
);

const HeaderLoader = props => (
  <ContentLoader
    height={40}
    width={160}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="40" height="40" />
    <rect x="50" y="0" rx="3" ry="3" width="160" height="40" />
  </ContentLoader>
);

interface Entity {
  id: number;
  name: string;
}

const styles = (theme: Theme) =>
  createStyles({
    titleContainer: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
    title: {
      paddingLeft: theme.spacing.unit,
    },
    link: {
      textDecoration: 'none',
    },
    headerLoader: {
      width: 135,
      height: 30,
    },
    itemLoader: {
      width: 150,
      height: 30,
    },
  });

interface EntityListProps {
  title: string;
  icon: JSX.Element;
  loading: boolean;
  type: 'plants' | 'areas' | 'events';
  list: Entity[];
}

type Props = WithStyles<typeof styles> & EntityListProps;

@observer
class EntityList extends React.Component<Props> {
  render() {
    const { type, loading, list, icon, title, classes } = this.props;

    return (
      <>
        <Grid container classes={{ container: classes.titleContainer }}>
          {loading ? (
            <HeaderLoader className={classes.headerLoader} />
          ) : (
            <>
              {icon}
              <Typography variant="title" classes={{ root: classes.title }}>
                {title}
              </Typography>
            </>
          )}
        </Grid>
        <List>
          {loading
            ? [0, 1, 2, 3, 4].map(item => (
                <ListItem key={item}>
                  <ListItemLoader className={classes.itemLoader} />
                </ListItem>
              ))
            : list.map(item => (
                <Link
                  key={item.id}
                  to={`${type}/${item.id}`}
                  className={classes.link}
                >
                  <ListItem>
                    <ListItemText> {item.name} </ListItemText>
                  </ListItem>
                </Link>
              ))}
        </List>
      </>
    );
  }
}

export default withStyles(styles)(EntityList);
