import * as factories from '@app/store/factories';
import { sequence, sequenceWithProps } from '@app/store/fluent';
import { merge, set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import { LocationProps } from './';
import * as actions from './actions';
import { state as initialState } from './state';

export const load = factories.loadApp([
  actions.getProfile,
  {
    success: [
      merge(state`profile.data`, props`profile`),
      set(state`profile.ui.loading`, false),
    ],
    error: [],
  },
]);

export const unload = [set(state`profile`, initialState)];

export const setLocation = sequenceWithProps<LocationProps>(s =>
  s.action(({ state, props }) => {
    state.set('profile.data.latitude', props.latitude);
    state.set('profile.data.longitude', props.longitude);
    state.set('user.profile.latitude', props.latitude);
    state.set('user.profile.longitude', props.longitude);
  }),
);

export const uploadCover = [
  set(state`profile.ui.uploading`, true),
  actions.uploadCover,
  {
    success: [
      set(state`profile.ui.uploading`, false),
      set(state`profile.data.coverImage`, props`media`),
      set(state`user.profile.coverImage`, props`media`),
      factories.addNotification('success', props`msg`),
    ],
    error: [factories.addNotification('error', 'Failed upload')],
  },
];

export const uploadAvatar = [
  actions.uploadAvatar,
  {
    success: [
      set(state`profile.data.profileImage`, props`media`),
      set(state`user.profile.profileImage`, props`media`),
      factories.addNotification('success', props`msg`),
    ],
    error: [factories.addNotification('error', 'Failed upload')],
  },
];

export const edit = sequence(s =>
  s.action(({ state }) =>
    state.set('profile.ui.edit', !state.get('plants.ui.edit')),
  ),
);

export const updateUserState = [set(state`profile.data`, props`data`)];

export const updateUser = [
  actions.updateUser,
  {
    success: [
      actions.updateUserSuccess,
      merge(state`profile.data`, props`profile`),
    ],
  },
];

export const updatePassword = [
  actions.updatePassword,
  {
    success: [],
    error: [
      factories.addNotification('error', 'be_error_wrong_old_password', true),
    ],
  },
];

export const updateCountry = [
  actions.updateCountry,
  {
    success: [merge(state`user`, props`response`)],
  },
];
export const updateLanguage = [
  actions.updateLanguage,
  {
    success: [
      set(state`user.profile`, props`user`),
      set(state`profile.data`, props`user`),
    ],
  },
];
