import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import {
  Grid,
  StandardSwitch,
  StandardSwitchProps,
  Typography,
} from 'components/atoms';
import * as React from 'react';

import { LabelLoader, SwitchLoader } from './loaders';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      ...theme.mixins.gutters(),
    },
  });

const Loader: React.SFC = () => {
  return (
    <>
      <LabelLoader style={{ width: 200, height: 50 }} />
      <SwitchLoader style={{ width: 40, height: 30 }} />
    </>
  );
};

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface LoadableComponent {
  loading?: boolean;
}

export interface LoadableSwitchProps
  extends StyledComponent,
    LoadableComponent,
    StandardSwitchProps {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked) => void;
}

type Props = WithStyles<typeof styles> & LoadableSwitchProps;

class LoadableSwitch extends React.Component<Props> {
  private get content(): JSX.Element {
    const { classes, loading, label, className, style, ...rest } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <Typography>
          {label}
        </Typography>
        <StandardSwitch {...rest} />
      </>
    );
  }

  public render(): JSX.Element {
    const { classes, className, style } = this.props;

    const container = cs(classes.container, {
      [className]: className,
    });

    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        classes={{ container }}
        style={style}
      >
        {this.content}
      </Grid>
    );
  }
}

export default withStyles(styles)(LoadableSwitch);
