import { Area, Event, Plant } from '@gardenize/models';
import { types } from 'mobx-state-tree';

const Standard = types.model({
  plants: types.optional(types.array(Plant), []),
  areas: types.optional(types.array(Area), []),
  events: types.optional(types.array(Event), []),
  count_plants: types.number,
  count_areas: types.number,
  count_events: types.number,
});

export default {
  ui: types.model({
    loading: types.boolean,
    searching: types.boolean,
    dialog: types.model({
      event: types.boolean,
    }),
  }),
  data: Standard,
  search: Standard,
};
