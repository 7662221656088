import { withFormik } from "formik";
import { domain } from "@gardenize/config";
import pick from "lodash-es/pick";
import { schema } from "./validators";
import { Props, Values } from "./types";

const initial: Values = {
  language: "English",
  measuring_unit: "metric",
  temperature: "celsius",
  date_format: "dd/MM/yyyy",
  currency: "SEK",
  mail_newsletter: false
};

/**
 * NOTE(zvrk): currently WEB supports only couple of currencies.
 * {@see domain.currency}. Mobile has hundreds of currencies, we
 * handle this by checking if currency is supported on WEB, if not
 * set it to `EUR`
 */
const transfromCurrency = (settings: Values): Values => {
  if (domain.currency.includes(settings.currency)) {
    return settings;
  }

  const DEFAULT_CURRENCY = "EUR";

  return { ...settings, currency: DEFAULT_CURRENCY };
};

export const withSettings = withFormik<Props, Values>({
  enableReinitialize: true,
  validationSchema: schema,
  validateOnBlur: false,
  handleSubmit: (values: Values, { props: { onSubmit }, ...rest }) => {
    onSubmit({ data: values, bag: rest });
  },
  mapPropsToValues: (props): Values => {
    const { settings, user } = props;

    if (!settings.data || !user.profile) {
      return initial;
    }

    const language = user.profile.language;
    const filteredData = pick(settings.data, Object.keys(initial));
    return { ...initial, ...transfromCurrency(filteredData), language };
  }
});
