import IosStore from '@app/assets/images/app_store.svg';
import playStore from '@app/assets/images/google-play-badge.png';
import { navigation } from '@gardenize/config';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import * as cs from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    playStore: {
      width: 150,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface StoreLinksProps extends StyledComponent {}

type Props = StoreLinksProps & WithStyles<typeof styles>;

const PlayStore = (props: StyledComponent) => {
  const { className, style } = props;
  return (
    <img
      src={playStore}
      className={className}
      style={style}
      alt="Play Store Icon"
    />
  );
};

const StoreLinks = (props: Props) => {
  const { className, classes, style } = props;

  const root = cs(classes.wrapper, {
    [className]: className,
  });

  return (
    <div className={root} style={style}>
      <a href={navigation.store.android}>
        <PlayStore className={classes.playStore} />
      </a>
      <a href={navigation.store.ios}>
        <IosStore style={{ width: 147 }} />
      </a>
    </div>
  );
};

export default withStyles(styles)(StoreLinks);
