import { Grid, GridProps } from '@app/components/atoms';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import * as React from 'react';

const styles = (_theme: Theme) => {
  return createStyles({
    wide: {
      maxWidth: 1260,
    },
    small: {
      maxWidth: 960,
    },
    container: {
      width: '100%',
    },
  });
};

export enum Direction {
  COLUMN = 'column',
  ROW = 'row',
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface ContainerProps extends GridProps, StyledComponent {
  wide?: boolean;
}

type Props = WithStyles<typeof styles> & ContainerProps;

class Container extends React.PureComponent<Props> {
  render() {
    const { wide, children, style, className, classes, ...rest } = this.props;

    const container = cs(classes.container, {
      [className]: className,
      [classes.wide]: wide,
      [classes.small]: !wide,
    });

    return (
      <Grid container classes={{ container }} style={style} {...rest}>
        {children}
      </Grid>
    );
  }
}

export default withStyles(styles)(Container);
