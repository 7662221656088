import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Typography } from 'components/atoms';
import { observer } from 'mobx-react';
import * as React from 'react';
import { compose } from 'recompose';
import { IOverlay } from './types';

const BACKGROUND = '/assets/images/background-paper.png';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 90,
      padding: theme.spacing.unit / 2,
      width: '100%',
      background: `url(${BACKGROUND}) center center no-repeat`,
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    title: {
      fontFamily: 'Courier Prime',
      fontStyle: 'normal',
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 20,
      textTransform: 'uppercase',
    },
    subtitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: theme.palette.text.primary,
      fontSize: 14,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 300,
    },
    info: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface OverlayProps extends StyledComponent {
  data: IOverlay;
}

type Props = WithStyles<typeof styles> & OverlayProps;

class Overlay extends React.Component<Props> {
  private get subtitle(): JSX.Element | null {
    const { data, classes } = this.props;

    if (!data.subtitle) {
      return null;
    }

    return (
      <Typography classes={{ root: classes.subtitle }}>
        {data.subtitle}
      </Typography>
    );
  }

  private get info(): JSX.Element | null {
    const { data, classes } = this.props;
    if (!data.info) {
      return null;
    }

    return (
      <Typography variant="subtitle1" classes={{ root: classes.info }}>
        {data.info}
      </Typography>
    );
  }

  public render(): JSX.Element {
    const { data, classes, className } = this.props;

    const root = cs(classes.container, {
      [className]: className,
    });

    return (
      <div className={root}>
        <Typography classes={{ root: classes.title }}>{data.title}</Typography>
        {this.subtitle}
        {this.info}
      </div>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  observer,
);

export default enhance(Overlay);
