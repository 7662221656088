import { types } from "mobx-state-tree";

export const CouponDetails = types.model({
  amount: types.number,
  currency: types.string,
  discount: types.number,
  discounted_price: types.number,
  interval: types.string,
  name: types.string,
  percent_off: types.number,
  plan_id: types.string
});

export type ICouponDetails = typeof CouponDetails.Type;
