import { parallel } from 'cerebral';
import { debounce, set, toggle, when } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';
import * as globalActions from '../../actions';
import { state as initialState } from './state';

export const getUpcomingEvents = [
  actions.getUpcomingEvents,
  {
    success: [set(state`events.data.upcoming`, props`upcoming`)],
    error: [],
  },
];

export const getPastEvents = [
  actions.getPastEvents,
  {
    success: [set(state`events.data.past`, props`past`)],
    error: [],
  },
];

export const getEventsPerYear = [
  actions.getEventsPerYear,
  {
    success: [set(state`events.data.events`, props`years`)],
    error: [],
  },
];

const init = parallel([getUpcomingEvents, getPastEvents, getEventsPerYear]);
export const load = factories.loadApp([
  when(props`q`),
  {
    true: [set(state`events.ui.query`, props`q`)],
    false: [set(state`events.ui.query`, '')],
  },
  init,
  set(state`events.ui.loading`, false),
]);

export const unload = [set(state`events`, initialState)];

export const getAllEvents = parallel([getUpcomingEvents, getPastEvents]);

export const changeYear = [
  set(state`events.ui.selected.year`, props`year`),
  set(state`events.ui.selected.months`, []),
  getAllEvents,
];

export const changeMonths = [
  set(state`events.ui.selected.months`, props`months`),
  set(state`events.ui.page.upcoming`, 1),
  set(state`events.ui.page.past`, 1),
  getAllEvents,
];

export const openEventDialog = [actions.setEventDialog(true)];
export const closeEventDialog = [actions.setEventDialog(false)];

export const createEvent = [
  when(state`user.premium.active`),
  {
    true: [
      actions.createEvent,
      {
        success: [closeEventDialog, getAllEvents],
      },
    ],
    false: [globalActions.redirectToPayments],
  },
];

export const create = [
  when(state`user.premium.active`),
  {
    true: [
      actions.create,
      {
        success: [actions.navigateToEvent],
      },
    ],
    false: [globalActions.redirectToPayments],
  },
];

export const search = [
  toggle(state`events.ui.searching`),
  debounce(300),
  {
    continue: [
      parallel([
        [
          actions.getUpcomingEvents,
          {
            success: [set(state`events.search.upcoming`, props`upcoming`)],
            error: [],
          },
        ],
        [
          actions.getPastEvents,
          {
            success: [set(state`events.search.past`, props`past`)],
            error: [],
          },
        ],
      ]),
    ],
    discard: [],
  },
  toggle(state`events.ui.searching`),
];

export const enter = [actions.enter, getEventsPerYear];

export const paginateUpcoming = [
  set(state`events.ui.page.upcoming`, props`upcoming.page`),
  getUpcomingEvents,
];

export const paginatePast = [
  set(state`events.ui.page.past`, props`past.page`),
  getPastEvents,
];
