import { types } from "mobx-state-tree";

const Recurring = types.model({
  interval: types.enumeration(["year", "month", "three_months"])
});

export const PaymentOption = types.model({
  id: types.string,
  object: "price",
  active: types.boolean,
  amount: types.number,
  created: types.number,
  currency: types.enumeration(["sek", "usd", "eur", "gbp", "nok", "aud", "cad"]),
  type: types.enumeration(["recurring"]),
  recurring: types.maybeNull(Recurring),
  product: types.string
});

export type IPaymentOption = typeof PaymentOption.Type;
