import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Grid } from 'components/atoms';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
    },
  });

type Props = WithStyles<typeof styles>;

class DetailsHeader extends React.Component<Props> {
  render() {
    const { classes, children } = this.props;

    return (
      <Grid item container classes={{ container: classes.container }}>
        {children}
      </Grid>
    );
  }
}

export default withStyles(styles)(DetailsHeader);
