import {
  Computed,
  ComputedValue,
  Module as CerebralModule,
} from '@cerebral/fluent';
import { IProfile } from '@gardenize/models';

import { location } from './getters';

export type State = {
  ui: {
    loading: boolean;
    uploading: boolean;
    edit: boolean;
    dialog: boolean;
    img: File | null;
  };
  data: IProfile | null;
  location: ComputedValue<[number, number]>;
};

export const state: State = {
  ui: {
    loading: true,
    uploading: false,
    edit: false,
    dialog: false,
    img: null,
  },
  data: null,
  location: Computed(location),
};
