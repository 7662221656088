import { Grid, GridProps } from 'components/atoms';
import * as React from 'react';

interface FooterSectionProps extends Partial<GridProps> {}

type Props = FooterSectionProps;

const FooterSection: React.StatelessComponent<Props> = (props: Props) => {
  const { children, md ,...rest } = props;
  return (
    <Grid
      container
      item
      sm={12}
      md={md || 6}
      component="section"
      alignItems="center"
      justify="flex-start"
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default FooterSection;
