import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';

import AppSnackbarContent from './app-snack-content';
import { Snackbar, SnackbarProps } from './snackbar';

const styles = (theme: Theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

interface AppSnackbar extends SnackbarProps {}
type Props = WithStyles<typeof styles> & AppSnackbar;

class AppSnackbar extends React.Component<Props> {
  readonly state = {
    open: true,
  };

  private handleClose = (_event: React.MouseEvent<HTMLDivElement>): void => {
    this.setState({ open: false });
  };

  public render(): JSX.Element {
    const { variant, message } = this.props;

    const AUTO_HIDE_DEFAULT = 2000;

    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={AUTO_HIDE_DEFAULT}
          onClose={this.handleClose}
        >
          <AppSnackbarContent
            message={message}
            variant={variant}
            onClose={this.handleClose}
          />
        </Snackbar>
      </>
    );
  }
}

export default withStyles(styles)(AppSnackbar);
