import * as React from 'react';

const Search = ({ className, color = "#42281F", isOutlined = true  }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="search-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={isOutlined ? "none" : color}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    id="search-svg"
  >
    <title id="search-icon">Search</title>
    <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" />
    <path d="M19.0004 18.9999L14.6504 14.6499" />
  </svg>
);

export default Search;
