import * as React from 'react';

const Crown = ({ className, color = "#42281F", isOutlined = true  }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="crown-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18"
    fill={isOutlined ? "none" : color}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    id="crown-svg"
  >
    <title id="crown-icon">Crown</title>
    <path d="M19 1V17H1V1L6 6.6L10 1.1L13.9 6.6L19 1Z" stroke={color} />
  </svg>
);

export default Crown;
