import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Grid, Paper } from 'components/atoms';
import { observer } from 'mobx-react';
import * as React from 'react';
import { compose } from 'recompose';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      width: '97%',
      zIndex: 100,
      margin: `0 ${theme.spacing.unit * 2}px`,
    },
  });

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface SearchDropdownProps extends StyledComponent {}

type Props = WithStyles<typeof styles> & SearchDropdownProps;

@observer
class SearchDropdown extends React.Component<Props> {
  render() {
    const { classes, className, style, children } = this.props;

    const root = cs(classes.container, {
      [className]: className,
    });

    return (
      <Paper classes={{ root }} style={style}>
        <Grid container>{children}</Grid>
      </Paper>
    );
  }
}

const enchance = compose(withStyles(styles));

export default enchance(SearchDropdown);
