import loadable from 'loadable-components';

export const NotFound = loadable(() =>
  import(/* webpackChunkName: "not-found" */ './not-found/notfound'),
);

export const ServerError = loadable(() =>
  import(/* webpackChunkName: "internal-error" */ './internal/server'),
);

export const InternalError = loadable(() =>
  import(/* webpackChunkName: "internal-error" */ './internal/internal'),
);

export const Unauthorized = loadable(() =>
  import(/* webpackChunkName: "unauthorized" */ './unauthorized/unauthorized'),
);

export const PageError = loadable(() =>
  import(/* webpackChunkName: "page-error" */ './page/boundry'),
);
