import { set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';

export const load = factories.loadApp([
  set(state`areaTypes.ui.loading`, true),
  actions.getAreaTypes,
  {
    success: [set(state`areaTypes.data`, props`areaTypes`)],
    error: [factories.addNotification('error', 'Couldnt fetch plant')],
  },
  set(state`areaTypes.ui.loading`, false),
]);

export const create = [
  actions.createAreaType,
  {
    success: [actions.addAreaTypeItem],
    error: [factories.addNotification('error', 'Couldnt save type')],
  },
];

export const update = [
  actions.updateAreaType,
  {
    success: [actions.updateAreaList],
    error: [factories.addNotification('error', 'Couldnt update type')],
  },
];

export const remove = [
  actions.removeAreaType,
  {
    success: [actions.removeAreaTypeById],
    error: [factories.addNotification('error', 'Couldnt delete type')],
  },
];
