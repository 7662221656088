import { IArea, IEvent, IPlant } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { Grid, List, ListItem, ListItemText } from 'components/atoms';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { HeaderLoader, ListItemLoader, SearchResultsAvatar } from './';

export const SearchResultsItem: React.SFC<{
  to: string;
  text: string;
  secondary?: string;
  img: string;
}> = ({ children, to, text, secondary, img, ...rest }) => {
  const link = iprops => <Link to={to} {...iprops} />;

  return (
    <li>
      <ListItem button component={link} {...rest}>
        <SearchResultsAvatar src={img}>{children}</SearchResultsAvatar>
        <ListItemText primary={text} secondary={secondary} />
      </ListItem>
    </li>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    headerLoader: {
      width: 135,
      height: 30,
    },
    itemLoader: {
      width: 150,
      height: 30,
    },
    titleContainer: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
  });

interface Loadable {
  loading?: boolean;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

type Collection = IEvent[] | IArea[] | IPlant[];

interface SearchResultProps extends StyledComponent, Loadable {
  collection: Collection;
  title: JSX.Element;
}

type Props = WithStyles<typeof styles> & SearchResultProps;

class SearchResults extends React.Component<Props> {
  private get header(): JSX.Element {
    const { classes, loading, title } = this.props;
    return loading ? <HeaderLoader className={classes.headerLoader} /> : title;
  }

  private get list(): JSX.Element[] {
    const { classes, children, loading, collection } = this.props;

    const toChild = (item: IEvent | IArea | IPlant, idx) =>
      (children as Function)({ item, key: idx });

    return loading
      ? [0, 1, 2, 3, 4].map(item => (
          <ListItem key={item}>
            <ListItemLoader className={classes.itemLoader} />
          </ListItem>
        ))
      : collection.map(toChild);
  }

  public render(): JSX.Element {
    const { classes, className, style, loading, collection } = this.props;

    if (!loading && !collection.length) {
      return null;
    }

    const container = cs({
      [className]: className,
    });

    return (
      <Grid item xs classes={{ item: container }} style={style}>
        <Grid container classes={{ container: classes.titleContainer }}>
          {this.header}
        </Grid>
        <List>{this.list}</List>
      </Grid>
    );
  }
}

const enchance = compose(withStyles(styles));

export default enchance(SearchResults);
