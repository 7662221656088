import { navigation } from '@gardenize/config';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Hidden,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
} from 'components/atoms';
import { inject } from 'mobx-react';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import {
  AvatarBox,
  HeaderContainer,
  HeaderContainerProps,
  Logo,
  Navigation,
  NotificationsPopover,
  OptionsPopover,
} from './elements';

type State = {
  dialog: any;
};

const HOME = '/garden';

const BurgerButton: React.SFC<IconButtonProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    handleClose();
    props.logout.action();
  };

  const style = { margin: '10px  20px' };
  return (
    <>
      <IconButton
        style={{ ...style, ...props.style }}
        onClick={handleClick}
        {...props}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={logout}>{props.logout.label}</MenuItem>
      </Menu>
    </>
  );
};

class Header extends React.Component<HeaderContainerProps> {
  public render(): JSX.Element {
    const {
      t,
      signals: { logOut },
    } = this.props;

    const logout = {
      action: logOut,
      label: t('logout'),
    };

    return (
      <HeaderContainer component="nav" {...this.props}>
        <Hidden mdUp>
          <BurgerButton logout={logout} />
          <Logo to={'/payment/history'} />
        </Hidden>
        <Hidden smDown>
          <Logo to={HOME} />
          <Navigation links={navigation.header} />
        </Hidden>
        <Hidden smDown>
          <AvatarBox>
            {(
              state,
              refUserMenu,
              closeUserMenu,
              refNotificationsMenu,
              closeNotificationsMenu,
            ) => {
              return (
                <>
                  {state.notifications && (
                    <NotificationsPopover
                      anchor={refNotificationsMenu}
                      open={state.notifications}
                      close={closeNotificationsMenu}
                    />
                  )}
                  {state.options && (
                    <OptionsPopover
                      anchor={refUserMenu}
                      open={state.options}
                      close={closeUserMenu}
                    />
                  )}
                </>
              );
            }}
          </AvatarBox>
        </Hidden>
      </HeaderContainer>
    );
  }
}

const enchance = compose(inject('signals'), withTranslation());

export default enchance(Header);
