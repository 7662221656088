import { Theme, withStyles, WithStyles } from '@material-ui/core';
import cs from 'classnames';
import {
  Card,
  CardContent,
  CardHeader,
  CardHeaderProps,
} from 'components/atoms';
import * as React from 'react';

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

export interface StandardCardProps extends StyledComponent, CardHeaderProps {
  header?: boolean;
}

type Props = WithStyles<typeof styles> & StandardCardProps;

const styles = (theme: Theme) => {
  const {
    breakpoints: { up, values },
    spacing: { unit },
  } = theme;

  return {
    card: {
      borderRadius: "2px",
      margin: `0 auto ${unit * 3}px`,
      width: '95%',
      [up(values.lg)]: {
        maxWidth: 960,
        width: '100%',
      },
    },
    action: {
      margin: '0px !important',
    },
    title: {
      ...theme.typography.headline,
      fontFamily: 'Courier Prime',
      textTransform: 'uppercase',
    }
  };
};

class StandardCard extends React.PureComponent<Props> {
  static defaultProps = {
    header: true,
  };

  private get header(): JSX.Element {
    const { children, classes, className, style, header, ...rest } = this.props;

    if (!header) {
      return null;
    }

    return (
      <CardHeader
        component="header"
        classes={{ action: classes.action, title: classes.title }}
        titleTypographyProps={{
          component: 'h2',
        }}
        {...rest}
      />
    );
  }

  public render(): JSX.Element {
    const { children, classes, className, style } = this.props;

    const root = cs(classes.card, {
      [className]: className,
    });

    return (
      <Card component="section" classes={{ root }} style={style}>
        {this.header}
        <CardContent>{children}</CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(StandardCard);
