import ProfileFallback from '@app/assets/images/profile.svg';
import { SignalsContext } from '@app/store';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import * as cs from 'classnames';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from 'components/atoms';
import has from 'lodash-es/has';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { NoNotifications, NotificationsList, SeeAll } from './elements';

const PRIMARY_RGB = `57,180,74`;

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      top: theme.spacing.unit,
      width: 300,
    },
    unread: {
      backgroundColor: `rgba(${PRIMARY_RGB}, .05)`,
    },
    list: {
      padding: 0,
    },
    fallback: {
      height: 100,
    },
    fallbackText: {
      width: '100%',
    },
    more: {
      textAlign: 'center',
    },
  });

interface NavigationPopoverProps {
  anchor: HTMLElement;
  open: boolean;
  close: () => void;
}
type Props = WithStyles<typeof styles> &
  WithTranslation &
  SignalsContext &
  NavigationPopoverProps;

class NavigationPopover extends React.Component<Props> {
  static displayName = 'App.Header.Popover.Navigation';

  private get showContent(): boolean {
    const {
      store: { notifications },
    } = this.props;

    return notifications.total;
  }

  private get content(): JSX.Element | JSX.Element[] {
    const {
      close,
      store: { notifications },
    } = this.props;

    if (this.showContent) {
      return (
        <>
          <NotificationsList
            close={close}
            notifications={notifications.data.latest.items}
          />
          <SeeAll close={close} />
        </>
      );
    }

    return (
      <>
        <NoNotifications />
        <SeeAll close={close} />
      </>
    );
  }

  public render(): JSX.Element {
    const { anchor, open, close, classes } = this.props;

    return (
      <Popper
        id="notifications-popper"
        placement="bottom-end"
        open={open}
        anchorEl={anchor}
        transition
        style={{ zIndex: 1500 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <ClickAwayListener onClickAway={close}>
              <Paper elevation={5} classes={{ root: classes.container }}>
                <Grid container>{this.content}</Grid>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    );
  }
}

interface LinkListItem extends ListItemProps {
  to: string;
}

const LinkListItem: React.SFC<LinkListItem> = ({ to, children, ...rest }) => {
  const Linker = props => <Link to={to} {...props} />;
  return (
    <ListItem component={Linker} {...rest}>
      {children}
    </ListItem>
  );
};

const enhance = compose(
  withTranslation(),
  withStyles(styles),
  inject('store'),
  observer,
);

export default enhance(NavigationPopover);
