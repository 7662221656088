import * as yup from "yup";

export const schema = yup.object().shape({
  description: yup
    .string()
    .max(255, "max_char")
    .nullable(true),
  webpage: yup
    .string()
    .max(255, "max_char")
    .nullable(true),
  global_hardiness_zone: yup
    .mixed()
    .oneOf([
      null,
      "1a",
      "1b",
      "2a",
      "2b",
      "3a",
      "3b",
      "4a",
      "4b",
      "5a",
      "5b",
      "6a",
      "6b",
      "7a",
      "7b",
      "8a",
      "8b",
      "9a",
      "9b",
      "10a",
      "10b",
      "11a",
      "11b",
      "12a",
      "12b",
      "13a",
      "13b"
    ])
    .nullable(true),
  local_hardiness_zone: yup
    .string()
    .max(10, "max_char")
    .nullable(true),
  email: yup
    .string()
    .email()
    .nullable(true),
  phone_number: yup.string().nullable(true),
  address: yup.string().nullable(true),
  location_visibility: yup.boolean().nullable(true),
  visibility: yup.boolean().nullable(true),
  first_name: yup
    .string()
    .max(50, "max_char")
    .nullable(true),
  country: yup
    .string()
    .nullable(true)
    .required("country_required"),
  copy_data_settings: yup.boolean().nullable(true),
  old_password: yup.string(),
  new_password: yup.string(),
  confirm_password: yup
    .string()
    // NOTE(zvrk): `password_dont_matach` was already in Loco. `facepalm`
    .oneOf([yup.ref("new_password")], "password_dont_matach")
});
