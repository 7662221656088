import { types } from 'mobx-state-tree';

import { Media } from './Media';

export const Notification = types.model({
  notification_id: types.number,
  type: types.string,
  // category: types.enumeration(['standard', 'link']),
  category: types.string,
  title: types.string,
  body_text: types.string,
  single_link: types.string,
  created_at: types.maybeNull(types.Date),
  valid_until: types.maybeNull(types.Date),
  // NOTE(backo): other props
  user_id: types.maybeNull(types.number),
  // NOTE(backo): "links" is legacy prop, not used
  links: types.array(types.string),
  unfetched_notifications_count: types.maybeNull(types.number),
  unfetched_feeds_count: types.maybeNull(types.number),
  unfetched_follow_requests: types.maybeNull(types.number),
  createdBy: types.model({
    username: types.string,
    first_name: types.string,
    last_name: types.string,
    media: types.maybeNull(Media),
  }),
});

export type INotification = typeof Notification.Type;
