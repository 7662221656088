import { StateContext } from '@app/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { compose } from 'recompose';

type Props = Partial<HelmetProps> & StateContext;

class SEO extends React.Component<Props> {
  public render(): JSX.Element {
    const { store, children } = this.props;

    return (
      <Helmet titleTemplate="Gardenize | %s" defaultTitle="Gardenize">
        <html lang={store.language} dir="ltr" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Title" />
        <meta
          property="og:image"
          content="https://d465skycssy7i.cloudfront.net/assets/images/logo.svg"
        />
        <meta property="og:description" content="Overwite" />
        <meta property="og:site_name" content="Gardenize" />
        <meta property="og:locale" content={store.language} />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@gardenize" />
        <meta name="twitter:creator" content="@individual_account" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content="Plants Example" />
        <meta name="twitter:description" content="Some description" />
        <meta
          name="twitter:image"
          content="https://d465skycssy7i.cloudfront.net/assets/images/logo.svg"
        />
        <link rel="icon" href="assets/images/app_icon-32x32.png" sizes="32x32" />
        <link rel="icon" href="assets/images/app_icon-192x192.png" sizes="192x192" />
        {children}
      </Helmet>
    );
  }
}

const enhance = compose(
  inject('store', 'signals'),
  observer,
);

export default enhance(SEO);
