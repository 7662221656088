import { Field } from '@gardenize/forms';
import { Select, SelectProps } from '@gardenize/forms/mui';
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as React from 'react';

import { Grid } from '../';

interface FieldProps {
  value?: any;
  name: string;
}

export interface StandardSelectFieldProps extends FieldProps, SelectProps {
  adornment?: string;
  label?: string;
  long?: boolean;
  inputProps?: React.CSSProperties;
}
type Props = Partial<SelectProps> & StandardSelectFieldProps;

export class StandardSelectField extends React.Component<Props> {
  private adornmentProps(adornment: string): {} {
    if (!adornment) {
      return {};
    }

    return {
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">{adornment}</InputAdornment>
        ),
      },
    };
  }

  public render(): JSX.Element {
    const {
      adornment,
      onBlur,
      id,
      label,
      name,
      children,
      long,
      ...rest
    } = this.props;

    return (
      <Grid item xs={12} md={long ? 12 : 6} container>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor={name}>{label}</InputLabel>
          <Field
            name={name}
            component={Select}
            fullWidth={true}
            inputProps={{
              onBlur,
              name,
              id: name,
            }}
            {...this.adornmentProps(adornment)}
            {...rest}
          >
            {children}
          </Field>
        </FormControl>
      </Grid>
    );
  }
}
