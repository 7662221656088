import { StateContext } from '@app/store';
import { IEvent } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import format from 'date-fns/format';
import { TFunction } from 'i18next';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { Overlay } from './';
import { IOverlay } from './types';

const fromTimestamp = (timestamp: number): Date => new Date(timestamp * 1000);

const eventsAdapter = (
  event: IEvent | undefined,
  dateFormat = 'dd/MM/yyyy',
  t: TFunction,
): IOverlay => {
  if (!event) {
    return;
  }

  return {
    title: format(fromTimestamp(event.date_timestamp), dateFormat),
    subtitle: `${event.plantIds ? event.plantIds.length : 0} ${t('plants')}`,
    info: `${event.areaIds ? event.areaIds.length : 0} ${t('my_areas')}`,
  };
};

const styles = (_theme: Theme) =>
  createStyles({
    hoverInfoOverlay: {},
  });

interface EventOverlayProps {
  event: IEvent;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

type Props = WithStyles<typeof styles> &
  StateContext &
  EventOverlayProps &
  WithTranslation &
  StyledComponent;

class EventOverlay extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      t,
      event,
      store: { settings },
      className,
      classes,
    } = this.props;

    const root = cs(classes.hoverInfoOverlay, {
      [className]: className,
    });

    return (
      <Overlay
        className={root}
        data={eventsAdapter(event, settings.dateFormat, t)}
      />
    );
  }
}

const enhance = compose(
  withTranslation(),
  inject('store'),
  observer,
  withStyles(styles),
);

export default enhance(EventOverlay);
