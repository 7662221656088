import apple from "./configs/apple.json";
import google from "./configs/google.json";
import facebook from "./configs/facebook.json";
import domain from "./configs/domain.json";
import languages from "./configs/languages.json";
import legend from "./configs/legend.json";
import navigation from "./configs/navigation.json";
import tags from "./configs/tags.json";
import mobileRouteMap from "./configs/mobile.route.json";
import colors from "./configs/colorpicker.json";
import features from "./configs/features.json";
import stripe from "./configs/stripe.json";
import feedback from "./configs/feedback.json";

// NOTE(zvrk): this is a work around the API, since BE doesn't have
// `get all` and do default pagination to 20.
export const MAX_NO_TYPES = 9999;

// Default redirect after login
export const DEFAULT_REDIRECT = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirectTo = urlParams.get("redirectTo");

  if (redirectTo) {
    return decodeURIComponent(redirectTo);
  }

  return "/garden";
};

// Token key for storage
export const TOKEN_NAMESPACE = "token";

// Supported image types
export const IMAGE_TYPES = [".png", ".jpeg", ".jpg"];

// No of entities per card in Details screen
export const ENTITIES_PER_CARD = 8;
export const ENTITIES_PER_SEARCH = 5;

// Seed for color hashing function
export const SEED = 123;

export const ItemTypes = {
  CARDIMG: "image",
  PANELIMG: "panel",
  NONE: "none"
};

export interface IDomainConfig {
  key: string;
  value: string;
}

export interface IColor {
  value: string;
  img: string;
}

// NOTE(zvrk): BEWARE, we have a fallthrough switch case here.
// This was done as easiest solution to the fact that `i18next`
// when queried about language returns `en` | `sv` or similar,
// while we are using full names.
// Consider normalizing those two in the future.
export const TERMS_AND_CONDITIONS = (lang: "en-US" | "sv-SE" | "en" | "sv") => {
  switch (lang) {
    case "en":
    case "en-US": {
      return "https://www.gardenize.com/terms_and_conditions_en/";
    }
    case "sv":
    case "sv-SE": {
      return "https://www.gardenize.com/sv/anvandarvillkor_se/";
    }
    default: {
      return "https://www.gardenize.com/terms_and_conditions_en/";
    }
  }
};

export {
  apple,
  google,
  facebook,
  domain,
  languages,
  legend,
  navigation,
  tags,
  mobileRouteMap,
  colors,
  features,
  stripe,
  feedback
};
