import compose from 'ramda/src/compose';
import tap from 'ramda/src/tap';

const stopSubmitting = bag => () => {
  bag.setSubmitting(false);
};

const setErrors = bag => error => {
  const { result } = error.response;

  const errs = result.reduce((acc, curr) => {
    acc[curr.field] = curr.message;
    return acc;
  }, {});

  bag.setErrors(errs);
  bag.setSubmitting(false);
};

const handleError = (bag, fn) =>
  compose(
    fn,
    tap(setErrors(bag)),
  );

const handleSuccess = (bag, fn) => {
  return compose(
    fn,
    tap(stopSubmitting(bag)),
  );
};

export const getSettings = ({ api, path }) => {
  return api
    .get('/user-settings')
    .then(response => path.success({ settings: response }));
};

export const updateSettings = ({ api, props, path, state }) => {
  const { bag, data } = props;
  const settings = { ...state.get('settings.data'), ...data };

  return api
    .put('/user-settings', settings)
    .then(handleSuccess(bag, response => path.success({ response })))
    .catch(handleError(bag, error => path.error({ error })));
};
