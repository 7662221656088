import { TOKEN_NAMESPACE } from '@gardenize/config';
import { Provider } from 'cerebral';

type Token = string;

interface ISession {
  exists(): boolean;
  token(): Token;
  make(t: Token): void;
  clear(): void;
}

const Service: ISession = {
  token(): string {
    return localStorage.getItem(TOKEN_NAMESPACE);
  },

  make(t: Token): void {
    localStorage.setItem(TOKEN_NAMESPACE, t);
  },

  exists(): boolean {
    return !!localStorage.getItem(TOKEN_NAMESPACE);
  },

  clear(): void {
    localStorage.clear();
  },
};

export type Type = keyof ISession;
export default Provider(Service);
