import { BranchContext, Context } from '@app/store/fluent';
import { Action, connector } from '@app/utils/entity';
import { ENTITIES_PER_CARD } from '@gardenize/config';
import { IArea, IEvent, IMedia, IMeta, IPlant } from '@gardenize/models';
import { toJS } from 'mobx';
import getUnixTime from 'date-fns/getUnixTime';
const setter = (item: string) => (val: any) => ({ state }: Context): void => {
  state.set(`event.ui.${item}`, val);
};

const metaIncr = (state, path: string): IMeta => {
  const meta: IMeta = toJS(state.get(path));
  return { ...meta, totalCount: meta.totalCount + 1 };
};

export const getEvent = ({
  api,
  props,
  path,
}: BranchContext<
  { success: { response: IEvent } },
  { eventId: number; plant: IPlant }
>): Promise<{ response: IEvent }> => {
  return api
    .get(`/event/${props.eventId}`)
    .then(response => path.success({ response }));
};

export const updatePhoto = ({ props, api, state, path }) => {
  const media = state.get('event.data.item.media');
  const img = media[props.idx];

  return api
    .put(`/event/update-image/${img.id}`, {
      ...img,
      ...props.data,
      date: props.date,
    })
    .then(response => path.success({ media: response.media }));
};

export const setPhotoByIndex = ({ props, state }) => {
  const media: IMedia[] = state
    .get('event.data.item.media')
    .map((m, idx) => (idx === props.idx ? props.media : m));

  state.set('event.data.item.media', media);
};

export const toggleFavouriteImage = ({
  props,
  api,
  state,
  path,
}: BranchContext<{ success: { image: IMedia } }, { id: number }>): Promise<{
  image: IMedia;
}> => {
  const media = state.get('event.data.item.media');
  const img = media.find(m => m.id === props.id);
  return api
    .put(`/event/update-image/${props.id}`, {
      ...img,
      favorite: img.favorite === 1 ? 0 : 1,
    })
    .then(response => path.success({ image: response.media }));
};

export const setImage = ({
  props,
  state,
}: Context<{ id: number; image: IMedia }>): void => {
  const media = state
    .get('event.data.item.media')
    .map(m =>
      m.id === props.id
        ? { ...m, favorite: props.image.favorite }
        : { ...m, favorite: 0 },
    );
  state.set('event.data.item.media', media);
};

export const setDeleteImageDialog = setter('dialog.del.image');
export const setDeleteEntityDialog = setter('dialog.del.entity');
export const setUploadDialog = setter('dialog.upload');
export const setEventDialog = setter('eventDialog');

export const addFile = ({
  props,
  state,
}: Context<{ image: string; name: string }>): void => {
  state.push('event.ui.files', {
    name: props.name,
    loading: true,
  });
};

export const uploadImage = ({
  props,
  state,
  path,
  api,
}: BranchContext<
  { success: { response: IMedia } },
  { file: string }
>): Promise<{ response: IMedia }> => {
  const id = state.get('event.data.item.id');
  return api
    .post(`/event/upload-image/${id}`, {
      image: props.file,
      date: getUnixTime(new Date()),
    })
    .then(response => path.success({ response: response.media }));
};

export const uploadDrawnImage = ({
  props,
  state,
  path,
  api,
}: BranchContext<A, B>): Promise<{ response: IMedia }> => {
  const id = state.get('event.data.item.id');
  const images = state.get('event.data.item.media');
  const { file, ...rest } = props;
  return api
    .post(`/event/upload-image/${id}`, {
      image: file,
      drawn: 1,
      date: getUnixTime(new Date()),
      ...rest,
    })
    .then(response =>
      path.success({ response: response.media, image: images.length }),
    );
};

export const setActiveImage = ({ props, state }) => {
  state.set('event.ui.activeImage', props.idx);
};

export const setImageLoading = ({
  props,
  state,
}: Context<{ name: string }>): void => {
  const files = state.get('event.ui.files');

  const mapper = file =>
    file.name === props.name ? { ...file, loading: false } : file;

  state.set('event.ui.files', files.map(mapper));
};

export const deleteImage = ({
  props,
  state,
  api,
  path,
}: BranchContext<
  { success: { message: string } },
  { index: number }
>): Promise<{ message: string }> => {
  const images = state.get('event.data.item.media');
  return api
    .delete(`/event/remove-image/${images[props.index].id}`)
    .then(response => path.success({ message: response.message }));
};

export const update = ({
  props,
  api,
  state,
  path,
}: BranchContext<
  { success: { response: IEvent } },
  { event: Partial<IEvent> }
>): Promise<{ response: IEvent }> => {
  const event = state.get('event.data.item');

  return api
    .put(`/event/${event.id}`, { ...event, ...props.event })
    .then(response => path.success({ response }));
};

export const getRelatedAreas = ({
  api,
  props,
  state,
  path,
}: BranchContext<
  { success: { response: IArea } },
  { eventId: number; page: number }
>) => {
  const user = state.get('user.id');
  const event = state.get('event.data.item.id') || props.eventId;
  const page = props.page ? props.page : 1;

  return api
    .get(`/garden/${user}/events/${event}/areas`, {
      page,
      per_page: 8,
    })
    .then(response => path.success({ response }));
};

export const getRelatedPlants = ({
  api,
  props,
  state,
  path,
}: BranchContext<
  { success: { response: IPlant } },
  { eventId: number; page: number }
>) => {
  const user = state.get('user.id');
  const event = state.get('event.data.item.id') || props.eventId;
  const page = props.page ? props.page : 1;

  return api
    .get(`/garden/${user}/events/${event}/plants`, {
      page,
      per_page: 8,
    })
    .then(response => path.success({ response }));
};

export const addEvent = ({
  props,
  state,
}: Context<{ response: string }>): void => {
  const events = toJS(state.get('plant.data.events'));

  events.items.length >= ENTITIES_PER_CARD
    ? state.set('plant.data.events', {
        items: [props.response, ...events.items.slice(0, -1)],
        _meta: { ...events._meta, totalCount: events._meta.totalCount + 1 },
      })
    : state.push('plant.data.events.items', props.response);
};

export const getUnconnectedAreas = ({
  props,
  state,
  api,
}: Context<{ page: number; query }>): Promise<void> => {
  const user = state.get('user.id');
  const event = state.get('event.data.item.id');
  // get initial results
  const page = props.page ? props.page : 1;
  const per_page = 6; // as per design

  const params = {
    page,
    per_page,
  };

  const search = state.get('event.ui.search.areas');

  if (search) {
    params.name = search;
  }

  return api
    .get(`/garden/${user}/events/${event}/unrelated-areas`, params)
    .then(response => {
      state.set('event.data.unconnected.areas', response);
      state.set('event.ui.loading.unconnected.areas', false);
    });
};

export const getUnconnectedPlants = ({
  props,
  state,
  api,
}: Context<{ page: number; query }>): Promise<void> => {
  const user = state.get('user.id');
  const event = state.get('event.data.item.id');
  // get initial results
  const page = props.page ? props.page : 1;
  const per_page = 6; // as per design

  const params = {
    page,
    per_page,
  };

  const search = state.get('event.ui.search.plants');

  if (search) {
    params.name = search;
  }

  return api
    .get(`/garden/${user}/events/${event}/unrelated-plants`, params)
    .then(response => {
      state.set('event.data.unconnected.plants', response);
      state.set('event.ui.loading.unconnected.plants', false);
    });
};

export const dropAreaPanelImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<
  { success: { response: IPlant } },
  { id: number }
>): Promise<{}> => {
  const event = state.get('event.data.item');
  const ids = event.areaIds ? event.areaIds : [];

  const areaIds = [String(props.id), ...ids];

  return api
    .put(`/event/${event.id}`, { ...event, areaIds })
    .then(response => path.success({ response }));
};

export const dropPlantPanelImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<
  { success: { response: IPlant } },
  { id: number }
>): Promise<{}> => {
  const event = state.get('event.data.item');
  const ids = event.plantIds ? event.plantIds : [];

  const plantIds = [String(props.id), ...ids];

  return api
    .put(`/event/${event.id}`, { ...event, plantIds })
    .then(response => path.success({ response }));
};

export const disconnectArea = ({
  props,
  state,
  path,
  api,
}: BranchContext<{ success: { response: IPlant } }, { id: number }>): Promise<{
  response: IPlant;
}> => {
  const event = state.get('plant.data.item');
  const areaIds = event.areaIds.filter(aid => +aid !== props.id);

  // NOTE(zvrk): to unlink entities you need to send -1; Go figure ¯\_(ツ)_/¯
  const newEvent = { ...event, areaIds: areaIds.length ? areaIds : -1 };

  return api
    .put(`/event/${event.id}`, newEvent)
    .then(response => path.success({ response }));
};

export const dropAreaCardImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<{ success: { response: IArea } }, { id: number }>): Promise<{
  response: IArea;
}> => {
  const event = state.get('event.data.item');
  const areaIds = event.areaIds.filter(pid => +pid !== props.id);

  // NOTE(zvrk): to unlink entities you need to send -1; Go figure ¯\_(ツ)_/¯
  const newEvent = { ...event, areaIds: areaIds.length ? areaIds : -1 };

  return api
    .put(`/event/${event.id}`, newEvent)
    .then(response => path.success({ response }));
};

export const dropPlantCardImg = ({
  props,
  state,
  path,
  api,
}: BranchContext<{ success: { response: IArea } }, { id: number }>): Promise<{
  response: IArea;
}> => {
  const event = state.get('event.data.item');
  const plantIds = event.plantIds.filter(pid => +pid !== props.id);

  // NOTE(zvrk): to unlink entities you need to send -1; Go figure ¯\_(ツ)_/¯
  const newEvent = { ...event, plantIds: plantIds.length ? plantIds : -1 };

  return api
    .put(`/event/${event.id}`, newEvent)
    .then(response => path.success({ response }));
};

export const addAreaPanelHead = ({ props, state }) => {
  const areas = toJS(state.get('event.data.areas.items'));
  const uareas = toJS(state.get('event.data.unconnected.areas.items'));

  const connect = connector<IArea>(Action.CONNECT, areas);
  const disconnect = connector<IArea>(Action.DISCONNECT, uareas);

  const area = uareas.find(a => a.id === props.id);

  state.set('event.data.unconnected.areas.items', disconnect(area));
  state.set('event.data.areas', {
    items: connect(area),
    _meta: metaIncr(state, 'event.data.areas._meta'),
  });
};

export const addAreaCardHead = ({ props, state }) => {
  const areas = toJS(state.get('event.data.areas.items'));
  const uareas = toJS(state.get('event.data.unconnected.areas.items'));
  const area = areas.find(a => a.id === props.id);

  const connect = connector<IArea>(Action.CONNECT, uareas, 6);
  const disconnect = connector<IArea>(Action.DISCONNECT, areas);

  state.set('event.data.areas.items', disconnect(area));
  state.set('event.data.unconnected.areas', {
    items: connect(area),
    _meta: metaIncr(state, 'event.data.unconnected.areas._meta'),
  });
};

export const addPlantPanelHead = ({ props, state }) => {
  const plants = toJS(state.get('event.data.plants.items'));
  const uplants = toJS(state.get('event.data.unconnected.plants.items'));

  const connect = connector<IPlant>(Action.CONNECT, plants);
  const disconnect = connector<IPlant>(Action.DISCONNECT, uplants);

  const plant = uplants.find(a => a.id === props.id);

  state.set('event.data.unconnected.plants.items', disconnect(plant));
  // NOTE(alex): add meta data
  state.set('event.data.plants', {
    items: connect(plant),
    _meta: metaIncr(state, 'event.data.plants._meta'),
  });
};

export const addPlantCardHead = ({ props, state }) => {
  const plants = toJS(state.get('event.data.plants.items'));
  const uplants = toJS(state.get('event.data.unconnected.plants.items'));
  const plant = plants.find(a => a.id === props.id);

  const connect = connector<IArea>(Action.CONNECT, uplants, 6);
  const disconnect = connector<IArea>(Action.DISCONNECT, plants);

  state.set('event.data.plants.items', disconnect(plant));
  state.set('event.data.unconnected.plants', {
    items: connect(plant),
    _meta: metaIncr(state, 'event.data.unconnected.plants._meta'),
  });
};

export const deleteEntity = ({ api, state, path }) => {
  const id = state.get('event.data.item.id');
  return api
    .delete(`/event/${id}`)
    .then(response => path.success({ response }));
};
