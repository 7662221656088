import * as React from 'react';

export interface ArrowProps extends React.HTMLAttributes<SVGElement> {
  isOpen: boolean;
}

export class Arrow extends React.PureComponent<ArrowProps> {
  public render(): JSX.Element {
    const { isOpen, ...rest } = this.props;

    return (
      <svg
        viewBox="0 0 20 20"
        preserveAspectRatio="none"
        width={16}
        height={16}
        fill="transparent"
        stroke="#979797"
        strokeWidth="1.1px"
        transform={isOpen ? 'rotate(180)' : null}
        {...rest}
      >
        <path d="M1,6 L10,15 L19,6" />
      </svg>
    );
  }
}
