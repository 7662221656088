import {
  ConnectFactory,
  IBranchContext,
  IContext,
  SequenceFactory,
  SequenceWithPropsFactory,
} from '@cerebral/fluent';
import HttpProvider from '@cerebral/http';

import { Signals, State } from './';
import { Type as ApiProvider } from './providers/Api';
import { Type as FirebaseProvider } from './providers/Firebase';
import { Type as IntlProvider } from './providers/i18n';
import { Type as RouterProvider } from './providers/Router';
import { Type as StorageProvider } from './providers/Storage';

type IPath = string;

interface IContextState {
  concat: (path: IPath, data: any[]) => void;
  push: (path: IPath, data: any) => void;
  pop: (path: IPath) => void;
  get: (path: IPath) => any;
  set: (path: IPath, data: any) => void;
  unset: (path: IPath) => void;
  increment: (path: IPath) => void;
  shift: (path: IPath, data: any) => void;
  unshift: (path: IPath, data: any) => void;
}

interface Providers {
  api: ApiProvider;
  state: IContextState;
  router: RouterProvider;
  storage: StorageProvider;
  firebase: FirebaseProvider;
  i18n: IntlProvider;
  http: typeof HttpProvider;
}

export type Context<Props = {}, TOutput = {}> = IContext<Props & TOutput> &
  Providers;

export type BranchContext<Paths, Props = {}, TOutput = {}> = IBranchContext<
  Paths,
  Props & TOutput
> &
  Providers;

export const sequence = SequenceFactory<Context>();

export const sequenceWithProps = SequenceWithPropsFactory<Context>();
