import { create as createArea } from '@app/store/modules/areas/sequences';
import { createEvent } from '@app/store/modules/events/sequences';
import { create as createPlant } from '@app/store/modules/plants/sequences';

import * as actions from './actions';

const handler = {
  link: [actions.openInNewWindow],
  standard: [
    actions.checkStandardLink,
    {
      createPlant: [createPlant],
      createArea: [createArea],
      createEvent: [createEvent],
      default: [actions.openInApp],
    },
  ],
};

const fg = handler => [actions.setBadge, actions.show, handler];

const bg = handler => [actions.setBadge, actions.background, handler];

export const click = [actions.click, handler];
export const foreground = fg(handler);
export const background = bg(handler);
