import GardenizeLogo from '@app/components/atoms/icon/logo';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface LogoProps {
  /**
   * Where to link logo click
   */
  to: string;
}
type Props = WithStyles<typeof styles> & LogoProps;

const styles = (theme: Theme) => {
  const {
    breakpoints: { values, down },
  } = theme;

  return createStyles({
    link: {
      flexBasis: 120,
      width: '100%',
      [down(values.md)]: {
        paddingRight: theme.spacing.unit * 1.5,
        marginRight: theme.spacing.unit * 2.5,
      },
    },
    img: {
      cursor: 'pointer',
      overflow: 'visible',
      height: '1em',
      verticalAlign: '-.125em',
      color: "#42281F",
      marginTop: "8px",
      fontSize: "44px",
    }
  });
};

class Logo extends React.PureComponent<Props> {
  static displayName = 'App.Header.Logo';

  static defaultProps = {
    to: '/garden',
  };

  public render(): JSX.Element {
    const { classes, to } = this.props;

    return (
      <Link to={to} className={classes.link}>
        <GardenizeLogo className={classes.img} />
      </Link>
    );
  }
}

export default withStyles(styles)(Logo);
