import { StateContext } from '@app/store';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {
  DatePickerInlineProps,
  InlineDatePicker,
} from 'material-ui-pickers/DatePicker';
import CalendarIcon from 'mdi-material-ui/Calendar';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { compose } from 'recompose';

import { Grid } from '../';

interface StandardDatepickerProps extends DatePickerInlineProps {
  long?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    icons: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
      position: 'absolute',
      top: theme.spacing.unit * 3.5,
      right: theme.spacing.unit * 2,
      pointerEvents: 'none',
      color: 'rgba(0,0,0, .5)',
    },
    gridWrapper: {
      position: 'relative',
    },
  });

type Props = StateContext & StandardDatepickerProps & WithStyles<typeof styles>;
export class StandardDatepicker extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      store: { settings },
      classes,
      long,
    } = this.props;

    return (
      <Grid item xs={12} md={long ? 12 : 6} className={classes.gridWrapper}>
        <InlineDatePicker
          onlyCalendar
          format={settings.dateFormat}
          fullWidth={true}
          leftArrowIcon={<ChevronLeft />}
          rightArrowIcon={<ChevronRight />}
          {...this.props}
        />
        <CalendarIcon className={classes.icons} />
      </Grid>
    );
  }
}

const enhance = compose(
  inject('store'),
  withStyles(styles),
  observer,
);

export default enhance(StandardDatepicker);
