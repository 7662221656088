import { Computed, ComputedValue } from '@cerebral/fluent';
import { IEvent, IMeta } from '@gardenize/models';

import * as getters from './getters';

export type State = {
  ui: {
    loading: boolean;
    searching: boolean;
    query: string;
    dialogs: {
      event: boolean;
    };
    selected: {
      months: number[];
      year: number;
    };
    page: {
      upcoming: number;
      past: number;
    };
  };
  data: {
    upcoming: {
      items: IEvent[] | null;
      _meta: IMeta | null;
    };
    past: {
      items: IEvent[] | null;
      _meta: IMeta | null;
    };
    events: { [year: number]: number[] };
  };
  search: {
    upcoming: {
      items: IEvent[] | null;
      _meta: IMeta | null;
    };
    past: {
      items: IEvent[] | null;
      _meta: IMeta | null;
    };
  };
  totalUpcoming: ComputedValue<number>;
  totalPast: ComputedValue<number>;
};

export const state: State = {
  ui: {
    loading: true,
    searching: false,
    query: '',
    dialogs: {
      event: false,
    },
    selected: {
      months: [],
      year: new Date().getFullYear(),
    },
    page: {
      upcoming: 1,
      past: 1,
    },
  },
  data: {
    upcoming: {
      items: [],
      _meta: null,
    },
    past: {
      items: [],
      _meta: null,
    },
    events: {},
  },
  search: {
    upcoming: {
      items: [],
      _meta: null,
    },
    past: {
      items: [],
      _meta: null,
    },
  },
  totalUpcoming: Computed(getters.totalUpcoming),
  totalPast: Computed(getters.totalPast),
};
