import * as React from 'react';

const Exit = ({ className, color = "#42281F", isOutlined = true  }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="exit-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={isOutlined ? "none" : color}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    id="exit-svg"
  >
    <title id="exit-icon">Logout</title>
    <path d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7" stroke={color} />
    <path d="M14 15L19 10L14 5" stroke={color} />
    <path d="M19 10H7" stroke={color} />
  </svg>
);

export default Exit;
