import { StandardTextField, StandardTextFieldProps } from 'components/atoms';
import * as React from 'react';

class PasswordField extends React.Component<StandardTextFieldProps> {
  public render(): JSX.Element {
    const { name, ...rest } = this.props

    return (
      <StandardTextField
        long={true}
        name={name}
        type="password"
        autoComplete={name}
        {...rest}
      />
    );
  }
}

export default PasswordField;
