import { types } from "mobx-state-tree";

export const timestamp = {
  created_at: types.maybeNull(types.number),
  updated_at: types.maybeNull(types.number)
};

export const timestamp_mobile = {
  created_at_mobile: types.maybeNull(types.number),
  updated_at_mobile: types.maybeNull(types.number)
};

export const flag = types.refinement(
  "flag",
  types.number,
  n => n === 0 || n === 1
);
