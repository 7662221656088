import { set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';

export const load = factories.loadApp([
  set(state`plantTypes.ui.loading`, true),
  actions.getPlantTypes,
  {
    success: [set(state`plantTypes.data`, props`plantTypes`)],
    error: [factories.addNotification('error', 'Couldnt fetch plant')],
  },
  set(state`plantTypes.ui.loading`, false),
]);

export const create = [
  actions.createPlantType,
  {
    success: [actions.addPlantTypeItem],
    error: [factories.addNotification('error', 'Couldnt save type')],
  },
];

export const update = [
  actions.updatePlantType,
  {
    success: [actions.updatePlantTypesList],
    error: [factories.addNotification('error', 'Couldnt update type')],
  },
];

export const remove = [
  actions.removePlantType,
  {
    success: [actions.removePlantTypeById],
    error: [factories.addNotification('error', 'Couldnt delete type')],
  },
];
