import { INotification } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import { List, ListItem, ListItemProps, Typography } from 'components/atoms';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import NotificationItem from './notification-item';

const styles = (theme: Theme) =>
  createStyles({
    list: {
      width: '100%',
    },
  });

interface INotifiable {
  notification: INotification;
}

interface NavigationListProps {
  notifications: INotification[];
  close: () => void;
}

type Props = WithStyles<typeof styles> & WithTranslation & NavigationListProps;

class NotificationsList extends React.Component<Props> {
  private toggleReceivedStatus = (idx: number) => (
    _e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const {
      signals: { push },
      close,
    } = this.props;

    // notifications.updateStatus({ idx });
    push.click({ idx });
    close();
  };

  private getId(notification: INotification): number {
    return notification.notification_id;
  }

  public render(): JSX.Element {
    const { t, classes, notifications } = this.props;

    const content = notifications.map((datum, idx) => (
      <NotificationItem
        data={datum}
        key={this.getId(datum.notification)}
        onClick={this.toggleReceivedStatus(idx)}
      />
    ));

    return <List classes={{ root: classes.list }}>{content}</List>;
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(styles),
  inject('store', 'signals'),
  observer,
);

export default enhance(NotificationsList);
