import { debounce, set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as factories from '../../factories';

import * as actions from './actions';
import * as paymentHisotryActions from '../payment-history/actions';

// prettier-ignore
export const load = factories.loadApp(
  [
    actions.getOptions,
    {
      success: [
        set(state`payment.data.options`, props`items`),
        set(state`payment.ui.loading`, false),
      ]
    }
  ]
);

export const unload = [set(state`payment.ui.loading`, true)];

export const setPlan = [set(state`payment.ui.plan`, props`plan`)];

export const setPlanPeriod = [set(state`payment.ui.planPeriod`, props`planPeriod`)];

export const setCurrency = [
  set(state`payment.ui.coupon`, ''),
  set(state`payment.ui.couponDetails`, []),
  set(state`payment.ui.shouldValidateCoupon`, false),
  set(state`payment.ui.currency`, props`currency`),
];

export const setCoupon = [
  set(state`payment.ui.coupon`, props`coupon`),
  set(state`payment.ui.couponDetails`, []),
  set(state`payment.ui.shouldValidateCoupon`, props`shouldValidateCoupon`),
  set(state`payment.ui.error`, ''),
];

export const validateCoupon = [
  actions.validate,
  {
    success: [
      set(state`payment.ui.couponDetails`, props`couponDetails`),
      set(state`payment.ui.shouldValidateCoupon`, false),
      set(state`payment.ui.error`, ''),
    ],
    error: [set(state`payment.ui.error`, props`err`)],
  },
];

export const charge = [
  actions.charge,
  {
    success: [
      set(state`user`, props`response.user`),
      factories.redirector('/garden'),
      factories.addNotification('success', 'subscribe_success', true),
    ],
    error: [
      set(state`ui.paymentError`, props`err`),
      set(state`ui.paymentErrorDialog`, true),
    ],
  },
];

export const changePaymentCard = [
  set(state`paymentHistory.ui.loading`, true),
  actions.changePaymentCard,
  {
    success: [
      set(state`user`, props`response.user`),
      paymentHisotryActions.getPaymentHistory,
      {
        success: [
          set(state`paymentHistory.data.items`, props`invoices`),
          set(state`paymentHistory.data.active`, props`active`),
          set(state`paymentHistory.data.card`, props`card`),
          set(state`paymentHistory.ui.loading`, false),
          factories.addNotification('success', 'payment_card_updated', true),
        ],
      },
    ],
    error: [
      set(state`ui.paymentError`, props`err`),
      set(state`ui.paymentErrorDialog`, true),
    ],
  },
];
