import { imgSrc } from '@app/utils/image';
import { INotification } from '@gardenize/models';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as cs from 'classnames';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
} from 'components/atoms';
import has from 'lodash-es/has';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

const styles = (theme: Theme) => {
  const PRIMARY_RGB = `57,180,74`;

  return createStyles({
    unread: {
      backgroundColor: `rgba(${PRIMARY_RGB}, .05)`,
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });
};

interface NotificationItemProps extends ListItemProps {
  data: INotification;
}

class NotificationItem extends React.Component<NotificationItemProps> {
  private get avatar(): JSX.Element {
    const { data } = this.props;

    return <Avatar src={imgSrc(data.notification.createdBy)} />;
  }

  private get primary(): string {
    const { data } = this.props;

    return data.notification.title;
  }

  private get secondary(): string {
    const { data } = this.props;

    return data.notification.body_text;
  }

  public render(): JSX.Element {
    const { data, classes, ...rest } = this.props;

    const root = cs({
      [classes.unread]: !data.read,
    });

    return (
      <ListItem component="li" button={true} classes={{ root }} {...rest}>
        <ListItemAvatar>{this.avatar}</ListItemAvatar>
        <ListItemText
          classes={{ primary: classes.ellipsis, secondary: classes.ellipsis }}
          primary={this.primary}
          secondary={this.secondary}
        />
      </ListItem>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  observer,
);
export default enhance(NotificationItem);
