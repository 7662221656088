import { Area, Event, Meta, Plant } from '@gardenize/models';
import { types } from 'mobx-state-tree';

const File = types.model({
  name: types.string,
  loading: types.boolean,
});

export default {
  ui: types.model({
    activeImage: types.number,
    lightbox: types.boolean,
    search: types.maybeNull(types.string),
    loading: types.model({
      page: types.boolean,
      areas: types.boolean,
      events: types.boolean,
      unconnected: types.boolean,
    }),
    files: types.array(File),
    submitting: types.boolean,
    panel: types.boolean,
    dialog: types.model({
      del: types.model({
        image: types.boolean,
        entity: types.boolean,
      }),
      upload: types.boolean,
      event: types.boolean,
      clone: types.boolean,
    }),
    edit: types.boolean,
  }),
  data: types.model({
    item: types.maybeNull(Plant),
    unconnected: types.model({
      items: types.optional(types.array(Area), []),
      _meta: types.maybeNull(Meta),
    }),
    areas: types.model({
      items: types.optional(types.array(Area), []),
      _meta: types.maybeNull(Meta),
    }),
    events: types.model({
      items: types.optional(types.array(Event), []),
      _meta: types.maybeNull(Meta),
    }),
  }),
};
