import { Provider } from 'cerebral';

interface IVisibilityService {
  listen(): void;
}

const Service: IVisibilityService = {
  listen(this: { context: any }): void {
    const blurHandler = this.context.controller.getSignal(
      'latestNotifications',
    );

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        blurHandler();
      }
    });
  },
};

export type Type = keyof IVisibilityService;
export default Provider(Service);
