import amber from '@material-ui/core/colors/amber';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import cs from 'classnames';
import { IconButton } from 'components/atoms/buttons';
import { PaperProps } from 'components/atoms/paper';
import * as React from 'react';

import { SnackbarContent } from './snackbar';

const IconMap = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = (theme: Theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    flexWrap: 'nowrap'
  }
});

interface AppSnackbarContentProps extends PaperProps {
  variant: string;
  message: string;
  onClose: React.MouseEventHandler;
}

type Props = WithStyles<typeof styles> & AppSnackbarContentProps;

const AppSnackbarContent: React.FunctionComponent<Props> = props => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = IconMap[variant];

  const actions = ({ classes, onClose } = props) => {
    return [
      <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon className={classes.icon} />
      </IconButton>,
    ];
  };

  return (
    <SnackbarContent
      className={cs(classes[variant], classes.root,className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={cs(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={actions(props)}
      {...other}
    />
  );
};

export default withStyles(styles)(AppSnackbarContent);
