import { StoreContext } from '@app/store';
import { feedback } from '@gardenize/config';
import {
  FormikProps,
  IFeedbackValues as Values,
  withFeedback,
} from '@gardenize/forms';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import {
  Button,
  Grid,
  MenuItem,
  Modal,
  StandardCard,
  StandardSelectField,
  StandardTextField,
  Typography,
} from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      position: 'relative',
      top: '25%',
      margin: '0 auto',
    },
    closeButton: {
      marginRight: theme.spacing.unit * 3,
    },
  });

export interface FeedbackDialogProps {
  open: boolean;
  onClose?: (a: any[] | any) => any;
  onSubmit: (values: any[] | any) => any;
}

type Props = WithStyles<typeof styles> &
  WithTranslation &
  FormikProps<Values> &
  StoreContext &
  FeedbackDialogProps;

class FeedbackDialog extends React.Component<Props> {
  private handleSubmit = (_e): void => {
    this.props.handleSubmit();
  };

  private get content(): JSX.Element {
    const { t } = this.props;

    return (
      <Grid container direction="row" spacing={16}>
        <StandardSelectField
          long={true}
          name="source"
          label={t('feedback_type')}
        >
          {feedback.map((item, idx) => (
            <MenuItem key={idx} value={item.value}>
              {t(item.key)}
            </MenuItem>
          ))}
        </StandardSelectField>
        <StandardTextField
          multiline
          name="title"
          long={true}
          label={t('title_feedback')}
          rowsMax={4}
          margin="normal"
          inputProps={{ maxLength: 64 }}
        />
        <StandardTextField
          multiline
          name="message"
          long={true}
          label={t('message')}
          rowsMax={4}
          margin="normal"
          inputProps={{ maxLength: 512 }}
        />
        <StandardTextField
          multiline
          name="email"
          long={true}
          label={t('profile_email')}
          rowsMax={4}
          margin="normal"
          inputProps={{ maxLength: 64 }}
        />
      </Grid>
    );
  }

  private get actions(): JSX.Element {
    const { t, classes, onClose } = this.props;

    return (
      <>
        <Button className={classes.closeButton} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={this.handleSubmit}>
          {t('done')}
        </Button>
      </>
    );
  }

  public render(): JSX.Element {
    const { t, open, classes, onClose } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StandardCard
          className={classes.container}
          title={<Typography variant="h5"> {t('title_feedback')} </Typography>}
          action={this.actions}
        >
          {this.content}
        </StandardCard>
      </Modal>
    );
  }
}

const enchance = compose(
  withFeedback,
  withTranslation(),
  withStyles(styles),
);

export default enchance(FeedbackDialog);
