import * as qs from 'qs';

interface IParams {
  lastId: string;
  limit: number;
}

export const getPaymentHistory = ({
  api,
  path,
  props = {
    lastId: '',
    limit: 10,
  },
}): Promise<void> => {
  const params: IParams = {
    lastId: props.lastId,
    limit: props.limit,
  };

  return api
    .get(`/payment/stripe-payment-history`, { ...params })
    .then(response => {
      const active =
        response.active_subscription &&
        response.active_subscription.subscription_id
          ? response.active_subscription
          : null;
      return path.success({
        invoices: response.invoices,
        active,
        card: response.card,
      });
    });
};

export const cancelSubscription = ({ props, path, api }) => {
  const { id: subscription_id } = props;

  return api
    .post('/payment/stripe-subscription-cancel', { subscription_id })
    .then(response => path.success())
    .catch(err => path.error({ err }));
};
