import * as yup from "yup";

export const schema = yup.object().shape({
  language: yup.string(),
  measuring_unit: yup.string(),
  temperature: yup.string(),
  date_format: yup.string(),
  currency: yup.string(),
  mail_newsletter: yup.boolean()
});
