import { withFormik } from "formik";
import pick from "lodash-es/pick";

import { schema } from "./validators";
import { Props, Values, IStore } from "./types";

const initial: Values = {
  height_from: 0,
  height: 0,
  width_from: 0,
  width_to: 0,
  seeding_depth: 0,
  seeding_distance: 0,
  scent: "",
  color: "",
  ph: "",
  soil: "",
  water: "",
  light: "",
  lifetime: "",
  way_of_seeding: ""
};

export const withAdditionalInfo = withFormik<Props & IStore, Values>({
  validationSchema: schema,
  handleSubmit: () => {},
  enableReinitialize: true,
  mapPropsToValues: ({ plant, store: { settings } }): Values => {
    if (!plant) {
      return initial;
    }

    const converter = settings.measurement.converter.to;

    return {
      ...initial,
      ...pick(plant, Object.keys(initial)),
      water: plant.amount_of_water || "",
      height_from: converter(+plant.height_from || 0),
      height: converter(+plant.height || 0),
      width_from: converter(plant.width_from || 0),
      width_to: converter(plant.width_to || 0),
      seeding_depth: converter(+plant.seeding_depth || 0),
      seeding_distance: converter(+plant.seeding_distance || 0),
      scent: plant.scent ? "Yes" : "No"
    };
  }
});
