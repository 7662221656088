import Avatar, { AvatarProps as AProps } from '@material-ui/core/Avatar';
import * as React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = props => (
  <ContentLoader
    height={40}
    width={40}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
);

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

export interface AvatarProps extends AProps, StyledComponent {
  loading?: boolean;
}

class LoadableAvatar extends React.Component<AvatarProps> {
  static displayName = 'LoadableAvatar';

  public render(): JSX.Element {
    const { loading, children, ...rest } = this.props;
    const { src, alt } = rest;

    if (loading) {
      return <Loader style={{ width: 40, height: 40 }} />;
    }

    // fallback
    if (!src && !children) {
      return (
        <Avatar data-testid="avatar" src="/assets/images/profile_image.svg"> {alt ? alt.slice(0, 1) : 'JD'}</Avatar>
      );
    }

    if (children) {
      return (
        <Avatar {...rest} data-testid="avatar">
          {this.props.children}
        </Avatar>
      );
    }

    return <Avatar {...rest} data-testid="avatar" />;
  }
}

export default LoadableAvatar;
