import GardenizeLogo from '@app/components/atoms/icon/logo';
import { StoreContext } from '@app/store';
import { navigation } from '@gardenize/config';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Button, Grid } from 'components/atoms';
import { StoreLinks } from 'components/molecules';
import { Container } from 'components/templates';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { FooterSection, InfoLinks, SocialLinks } from './elements';

const styles = (theme: Theme) => {
  const {
    breakpoints: { down, up, values },
    spacing: { unit },
  } = theme;

  return createStyles({
    container: {
      flex: 'none',
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      minHeight: 65,
      [down(values.md)]: {
        marginTop: theme.spacing.unit * 2,
      },
      [up(values.md)]: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
      },
    },
    logo: {
      cursor: 'pointer',
      overflow: 'visible',
      height: '1em',
      verticalAlign: '-.125em',
      color: "#F4F1ED",
      fontSize: "44px",
      marginRight: theme.spacing.unit,
    },
    logoLink: {
      [down(values.md)]: {
        width: '100%',
        textAlign: 'center',
      },
    },
    store: {
      marginRight: theme.spacing.unit,
      '& a:first-child': {
        paddingRight: theme.spacing.unit,
      },
      [down(values.sm)]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    button: {
      borderRadius: "4px",
      color: theme.palette.common.white,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
    },
    section: {
      [down(values.md)]: {
        paddingTop: theme.spacing.unit,
        justifyContent: 'center',
      },
    },
  });
};

type Props = WithTranslation & WithStyles<typeof styles> & StoreContext & RouteComponentProps;

class Footer extends React.Component<Props> {
  private handleOpenFeedback = (
    _e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const {
      signals: { openFeedbackDialog },
    } = this.props;

    openFeedbackDialog();
  };

  private get button(): JSX.Element {
    const { t, classes } = this.props;

    return (
      <>
        <Button
          classes={{ root: classes.button }}
          onClick={this.handleOpenFeedback}
        >
          {t('title_feedback')}
        </Button>
        <Button
          classes={{ root: classes.button }}
          onClick={() => {
            this.props.history.push('/delete-account')
          }}>
          {t('help_delete_account')}
        </Button>
      </>
    );
  }

  public render(): JSX.Element {
    const { classes } = this.props;

    return (
      <Grid
        container
        component="footer"
        justify="center"
        classes={{ container: classes.container }}
      >
        <Container
          wide={true}
          direction="row"
          direction-xs="column"
          justify="center"
        >
          <FooterSection classes={{ container: classes.section }} md={7}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={navigation.footer.logo}
              className={classes.logoLink}
            >
              <GardenizeLogo className={classes.logo} color="#FFFFFF" />
            </a>
            <InfoLinks links={navigation.footer.pages} />
            {this.button}
          </FooterSection>
          <FooterSection
            justify="flex-end"
            classes={{ container: classes.section }}
            md={5}
          >
            <StoreLinks className={classes.store} />
            <SocialLinks links={navigation.footer.social} />
          </FooterSection>
        </Container>
      </Grid>
    );
  }
}

const enchance = compose(
  inject('signals', 'store'),
  withTranslation(),
  withStyles(styles),
  observer,
  withRouter
);

export default enchance(Footer);
