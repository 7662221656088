import * as yup from "yup";

export const schema = yup.object().shape({
  cost: yup.string(),
  size: yup.number(),
  quantity: yup.string(),
  photo_date: yup.date().nullable(true),
  date_timestamp: yup.date().nullable(true),
  reminder_timestamp: yup.date().nullable(true),
  activity_id: yup.number(),
  weight: yup.string(),
  reminder: yup.boolean(),
  note: yup.string().nullable(true),
  time_used: yup.number()
});
