import { Area, Event, Meta, Plant } from '@gardenize/models';
import { types } from 'mobx-state-tree';

const File = types.model({
  name: types.string,
  loading: types.boolean,
});

const Collection = model =>
  types.model({
    items: types.optional(types.array(model), []),
    _meta: types.maybeNull(Meta),
  });

export default {
  ui: types.model({
    activeImage: types.number,
    lightbox: types.boolean,
    loading: types.model({
      page: types.boolean,
      areas: types.boolean,
      plants: types.boolean,
      unconnected: types.model({
        areas: types.boolean,
        plants: types.boolean,
      }),
    }),
    search: types.model({
      areas: types.string,
      plants: types.string,
    }),
    files: types.array(File),
    submitting: types.boolean,
    panel: types.model({
      area: types.boolean,
      plant: types.boolean,
    }),
    dialog: types.model({
      del: types.model({
        image: types.boolean,
        entity: types.boolean,
      }),
      upload: types.boolean,
    }),
    edit: types.boolean,
  }),
  data: types.model({
    item: types.maybeNull(Event),
    unconnected: types.model({
      areas: Collection(Area),
      plants: Collection(Plant),
    }),
    areas: Collection(Area),
    plants: Collection(Plant),
  }),
};
