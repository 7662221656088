import { State } from './state';

export function hasPictures(this: State): boolean {
  const event = this.data.item;

  return event ? event.media.length > 0 : false;
}

export function connectedAreas(this: State): number {
  const areas = this.data.areas._meta;
  return areas ? areas.totalCount : 0;
}

export function connectedPlants(this: State): number {
  const plants = this.data.plants._meta;
  return plants ? plants.totalCount : 0;
}

export function unconnectedAreas(this: State): number {
  const areas = this.data.unconnected.areas._meta;
  return areas ? areas.totalCount : 0;
}

export function unconnectedPlants(this: State): number {
  const plants = this.data.unconnected.plants._meta;
  return plants ? plants.totalCount : 0;
}
