import * as factories from '@app/store/factories';
import { parallel, sequence } from 'cerebral';
import { sequence as areaSequence } from '@app/store/fluent';
import {
  debounce,
  push,
  set,
  splice,
  toggle,
  wait,
  when,
  unshift
} from 'cerebral/operators';
import { props, state } from 'cerebral/tags';

import * as actions from './actions';
import { state as initialState } from './state';

type AreaId = number;
type Page = number;

interface Query {
  id: AreaId;
  page: Page;
}

const init = parallel([
  actions.getArea,
  actions.getRelatedPlants,
  actions.getRelatedEvents,
]);

export const fetchPlants = sequence<Query>([
  toggle(state`area.ui.loading.plants`),
  actions.getRelatedPlants,
]);

export const fetchEvents = sequence<Query>([
  toggle(state`area.ui.loading.events`),
  actions.getRelatedEvents,
]);

export const fetchUnrelated = sequence<{ page: number; query: string }>([
  actions.getUnconnectedPlants,
]);

export const setSearch = set(state`area.ui.search`, props`query`);

export const searchUnrelated = sequence<{ query?: string; page?: number }>([
  set(state`area.ui.loading.unconnected`, true),
  setSearch,
  debounce(300),
  {
    continue: [fetchUnrelated],
    discard: [],
  },
]);

export const load = factories.loadApp([
  init,
  set(state`area.ui.loading.page`, false),
]);

export const update = [
  set(state`area.ui.submitting`, true),
  actions.updateArea,
  {
    success: [
      // NOTE(zvrk): see `plant.update` for explanation
      when(state`area.ui.loading.page`),
      {
        true: [],
        false: [
          set(state`area.data.item`, props`response`),
          set(state`area.ui.submitting`, false),
        ],
      },
    ],
  },
];

export const updatePhoto = [
  set(state`area.ui.submitting`, true),
  actions.updatePhoto,
  {
    success: [actions.setPhotoByIndex, set(state`area.ui.submitting`, false)],
  },
];

export const togglePanel = [
  when(state`area.ui.panel`),
  {
    true: [
      toggle(state`area.ui.panel`),
      wait(300),
      set(state`area.ui.loading.unconnected`, true),
    ],
    false: [toggle(state`area.ui.panel`), actions.getUnconnectedPlants],
  },
];

export const dropCardImg = sequence<{ id: number }>('drop ccard', [
  actions.dropCardImg,
  {
    success: [
      set(state`area.data.item`, props`response`),
      actions.addCardHead,
      fetchPlants,
    ],
  },
]);

export const dropPanelImg = [
  actions.dropPanelImg,
  {
    success: [
      set(state`area.data.item`, props`response`),
      actions.addPanelHead,
      fetchUnrelated,
    ],
  },
];

export const unload = [set(state`area`, initialState)];

export const openDeleteDialog = actions.setDeleteDialog(true);
export const closeDeleteDialog = actions.setDeleteDialog(false);

export const openDeleteEntityDialog = actions.setDeleteEntity(true);
export const closeDeleteEntityDialog = actions.setDeleteEntity(false);

export const openUploadDialog = actions.setUploadDialog(true);
export const closeUploadDialog = [
  actions.setUploadDialog(false),
  set(state`area.ui.files`, []),
];

export const openEventDialog = actions.setEventDialog(true);
export const closeEventDialog = actions.setEventDialog(false);

export const deleteEntity = [
  actions.deleteEntity,
  {
    success: [factories.goBack],
  },
];

export const deleteImage = [
  actions.deleteAreaImage,
  {
    success: [
      splice(state`area.data.item.media`, props`index`, 1),
      closeDeleteDialog,
    ],
  },
];

export const createEvent = [
  actions.createEvent,
  {
    success: [closeEventDialog, actions.addEvent],
  },
];

export const toggleFavouriteImage = [
  actions.toggleFavouriteImage,
  {
    success: [actions.setImage],
  },
];

export const uploadImage = [
  actions.addFile,
  actions.uploadImage,
  {
    success: [
      unshift(state`area.data.item.media`, props`response`),
      actions.setImageLoading,
    ],
  },
];

export const uploadDrawnImage = [
  actions.addFile,
  actions.uploadDrawnImage,
  {
    success: [
      push(state`area.data.item.media`, props`response`),
      actions.setImageLoading,
      set(state`area.ui.activeImage`, props`image`),
    ],
  },
];

export const setActiveImage = [actions.setActiveImage];

export const setLightbox = [set(state`area.ui.lightbox`, props`lightbox`)];

export const edit = areaSequence(s =>
  s.action(({ state }) =>
    state.set('area.ui.edit', !state.get('area.ui.edit')),
  ),
);