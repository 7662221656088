import { IUser } from '@gardenize/models';

export type ISnack = {
  id: number;
  title: string;
  variant: 'info' | 'error';
};

export type GlobalState = {
  ui: {
    feedbackDialog: boolean;
    paymentErrorDialog: boolean;
    paymentError: any;
    resetPasswordDialog: boolean;
    accountDeletionDialog: boolean;
  };
  jwt: string | null;
  user: IUser | null;
  isAuthenticating: boolean;
  isRegistering: boolean;
  language: string;
  snacks: ISnack[];
};

export const state: GlobalState = {
  ui: {
    feedbackDialog: false,
    paymentErrorDialog: false,
    paymentError: null,
    resetPasswordDialog: false,
    accountDeletionDialog: false
  },
  jwt: null,
  user: null,
  isAuthenticating: false,
  isRegistering: false,
  language: 'en-US',
  snacks: [],
};
