import { withFormik } from "formik";

import { schema } from "./validators";
import { Props, Values } from "./types";

const initial: Values = {
  email: "",
  password: "",
  repeat_password: "",
  country: "",
  toc: false,
  newspaper: true
};

export const withRegister = withFormik<Props, Values>({
  validationSchema: schema,
  validateOnBlur: false,
  handleSubmit: (values: Values, { props: { onSubmit }, ...rest }) => {
    onSubmit({ data: values, bag: rest });
  },
  mapPropsToValues: (_props: Props): Values => {
    return initial;
  }
});
