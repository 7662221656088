import * as React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const Style = {
  HEIGHT: 20,
  WIDTH: 500,

  COLOR: {
    PRIMARY: '#f3f3f3',
    SECONDARY: '#ecebeb',
  },
};

export const SelectLoader: React.FunctionComponent<
  IContentLoaderProps
> = props => {
  return (
    <ContentLoader
      speed={2}
      height={Style.HEIGHT}
      width={Style.WIDTH}
      primaryColor={Style.COLOR.PRIMARY}
      secondaryColor={Style.COLOR.SECONDARY}
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="300" height="30" />
    </ContentLoader>
  );
};
