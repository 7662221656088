import { AutoSizer, List } from '@gardenize/infinite';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import cs from 'classnames';
import {
  LinearProgress,
  MenuItem,
  TextField,
  TextFieldProps,
} from 'components/atoms';
import Downshift, { GetItemPropsOptions } from 'downshift';
import toLower from 'ramda/src/toLower';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const Menu = React.lazy(() =>
  import(/* webpackChunkName: 'profile_country-menu' */ './menu'),
);
import ActionButton, { ActionButtonProps } from './action-button';

const COUNTRY_PATH = '/assets/countries/svg/';

type ShortCode = string;
type CountryName = string;

type CountryInfo = {
  codes: ShortCode[];
  names: Record<ShortCode, CountryName>;
};

type DownshiftedItem<T = any> = {
  selectedItem: T;
  highlightedIndex: number;
  getItemProps: (options: GetItemPropsOptions<T>) => any;
};

const ItemLoader = props => (
  <ContentLoader
    height={40}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
);

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      position: 'relative',
      width: '100%',
    },
    arrow: {
      position: 'absolute',
      right: 0,
      bottom: theme.spacing.unit / 2,
    },
    field: {
      width: '100%',
    },
    paper: {
      position: 'absolute',
      willChange: 'transform',
      transform: 'translateZ(0)',
      maxHeight: 200,
      overflowY: 'auto',
      zIndex: 1,
      left: 0,
      right: 0,
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
  });
interface State {
  isOpen: boolean;
}

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

export interface AutoselectProps extends StyledComponent, TextFieldProps {
  Component?: React.ComponentType;
  ButtonProps?: ActionButtonProps;
  onChange?: (item: string) => void;
}

type Props = AutoselectProps & WithStyles<typeof styles>;

export class Autoselect extends React.PureComponent<Props, State> {
  static defaultProps = {
    Component: TextField,
  };

  readonly state: State = {
    isOpen: false,
  };

  private handleClick = (_e: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  /**
   * Propagate country choice if `onChange` is defined
   */
  private handleChange = (country: string): void => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(country);
    }
  };

  private onResetHandler = (resetter: (a: object, cb?: () => void) => void) => (
    _e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const { onChange } = this.props;

    const DEFAULT_INPUT = '';

    if (onChange) {
      resetter({ inputValue: DEFAULT_INPUT }, onChange(''));
    }

    resetter({ inputValue: DEFAULT_INPUT });
  };

  private rowRenderer = (meta: DownshiftedItem<CountryName> & CountryInfo) => ({
    index,
    isScrolling,
    key,
    style,
  }): JSX.Element => {
    const { codes, names, getItemProps, highlightedIndex } = meta;
    const current = codes[index];
    const normalized = toLower(current);
    const name = names[current];
    const url = `${COUNTRY_PATH}${normalized}.svg`;

    if (isScrolling) {
      return (
        <ItemLoader key={key} style={{ ...style, padding: '0 40px 0 10px' }} />
      );
    }

    const ss = {
      ...style,
      paddingTop: 0,
      paddingBottom: 0,
    };

    if (highlightedIndex === index) {
      ss.backgroundColor = 'lightgray';
    }

    return (
      <MenuItem
        key={key}
        style={ss}
        {...getItemProps({
          index,
          item: name,
        })}
      >
        <img
          src={url}
          style={{ width: '2rem', height: '2rem', marginRight: 10 }}
        />
        {name}
      </MenuItem>
    );
  };

  private noRowsRenderer = (): JSX.Element => {
    return <div> No items found</div>;
  };

  private items = ({
    selectedItem,
    highlightedIndex,
    getItemProps,
  }: DownshiftedItem<CountryName>) => (info: CountryInfo): JSX.Element => {
    const { codes, names } = info;

    const ROW_HEIGHT = 40;
    const MENU_HEIGHT = ROW_HEIGHT * 5;
    const OVERSCAN_ROWS = 5;
    const calcHeight = (length: number) =>
      length > 5 ? MENU_HEIGHT : length * ROW_HEIGHT;

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            height={calcHeight(codes.length)}
            width={width}
            rowCount={codes.length}
            rowHeight={ROW_HEIGHT}
            rowRenderer={this.rowRenderer({
              selectedItem,
              highlightedIndex,
              getItemProps,
              codes,
              names,
            })}
            overscanRowCount={OVERSCAN_ROWS}
            noRowsRenderer={this.noRowsRenderer}
          />
        )}
      </AutoSizer>
    );
  };

  private get shouldShowReset(): boolean {
    const { value } = this.props;

    return !!value;
  }

  public render(): JSX.Element {
    const { t, i18n, tReady, ...other } = this.props;
    const {
      Component,
      ButtonProps,
      classes,
      className,
      value,
      InputProps,
      ...rest
    } = other;

    const field = cs(classes.field, {
      [className]: className,
    });

    return (
      <Downshift onSelect={this.handleChange} initialInputValue={value}>
        {({
          isOpen,
          reset,
          selectedItem,
          highlightedIndex,
          getInputProps,
          getMenuProps,
          getItemProps,
          getToggleButtonProps,
          inputValue,
        }) => (
          <div className={classes.container}>
            <Component
              value={value}
              className={field}
              InputProps={{ ...InputProps, ...getInputProps() }}
              {...rest}
            />
            <ActionButton
              open={isOpen}
              reset={this.shouldShowReset}
              onReset={this.onResetHandler(reset)}
              onToggle={this.handleClick}
              className={classes.arrow}
              {...ButtonProps}
              {...getToggleButtonProps()}
            />
            {isOpen && (
              <React.Suspense fallback={<LinearProgress />}>
                <Menu
                  input={inputValue}
                  style={{ position: 'absolute' }}
                  className={classes.paper}
                  {...getMenuProps()}
                >
                  {this.items({
                    selectedItem,
                    highlightedIndex,
                    getItemProps,
                  })}
                </Menu>
              </React.Suspense>
            )}
          </div>
        )}
      </Downshift>
    );
  }
}
const enchance = compose(
  withStyles(styles),
  withTranslation(),
);
export default enchance(Autoselect);
