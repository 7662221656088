import { withFormik } from "formik";

import { schema } from "./validators";
import { Props, Values } from "./types";

const initial: Values = {
  email: ""
};

export const withResetPassword = withFormik<Props, Values>({
  validationSchema: schema,
  handleSubmit: (
    values: Values,
    { resetForm, setSubmitting, props: { onSubmit } }
  ) => {
    const data = {
      email: String(values.email)
    };

    onSubmit({ data });
    setSubmitting(false);
    resetForm();
  },
  mapPropsToValues: () => {
    return initial;
  }
});
