import { types } from "mobx-state-tree";

import { flag, timestamp } from "./common";
import { Media } from "./Media";
import { Supplier } from "./Supplier";
import { GlobalType } from "./GlobalType";
import { PlantType } from "./PlantType";

export const Plant = types.model({
  ...timestamp,
  id: types.number,
  user_id: types.number,
  plant_type_id: types.maybeNull(types.number),
  name: types.string,
  color: types.maybeNull(types.string),
  // maybeNull string '0.0'
  height: types.maybeNull(types.string),
  rating: types.maybeNull(types.string),
  latin_name: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  status: flag,
  created_at_mobile: types.maybeNull(types.number),
  updated_at_mobile: types.maybeNull(types.number),
  scent: types.number,
  // maybeNull slash | 0.0
  ph: types.maybeNull(types.string),
  soil: types.maybeNull(types.string),
  light: types.maybeNull(types.string),
  amount_of_water: types.maybeNull(types.string),
  // maybeNull '0.0'
  seeding_depth: types.maybeNull(types.string),
  seeding_distance: types.maybeNull(types.string),
  // slash or month
  seeding_time: types.maybeNull(types.string),
  harvest_time: types.maybeNull(types.string),
  blooming_time: types.maybeNull(types.string),
  trimming_time: types.maybeNull(types.string),
  global_plant_id: types.maybeNull(types.number),
  global_type_id: types.maybeNull(types.number),
  // maybeNull string '0.0'
  width_from: types.maybeNull(types.number),
  width_to: types.maybeNull(types.number),
  height_from: types.maybeNull(types.string),
  supplier_note: types.maybeNull(types.string),
  way_of_seeding: types.maybeNull(types.string),
  producer: types.maybeNull(types.string),
  lifetime: types.maybeNull(types.string),
  is_new_plant_flag: flag,
  variety: types.maybeNull(types.string),
  date_timestamp: types.maybeNull(types.number),
  media: types.maybeNull(types.array(Media)),
  eventIds: types.maybeNull(types.array(types.string)),
  // "2342"
  areaIds: types.maybeNull(types.array(types.string)),
  area_id: types.maybeNull(types.number),
  // ?
  supplier: types.maybeNull(Supplier),
  globalType: types.maybeNull(GlobalType),
  // maybeNull types.string ( months CSV )
  seedingTimeArray: types.optional(types.array(types.string), []),
  trimmingTimeArray: types.optional(types.array(types.string), []),
  bloomingTimeArray: types.optional(types.array(types.string), []),
  harvestTimeArray: types.optional(types.array(types.string), []),
  // broccoly, tomato etc.
  plantSort: types.maybeNull(types.string),
  plantType: types.maybeNull(PlantType),
  is_new_plant: flag,
  plant_type_name: types.string,
  copy_data_settings: types.enumeration(["forbidden", "allowed"])
});

export type IPlant = typeof Plant.Type;
