import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Tab, Tabs, Toolbar } from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

interface LinkItem {
  name: string;
  href: string;
  external?: boolean;
  lang?: string;
}

interface NavigationProps {
  /**
   * Array of links containing informations
   * for navigation
   */
  links: LinkItem[];
}

type Props = RouteComponentProps<any> &
  WithStyles<typeof styles> &
  NavigationProps;

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    tabs: {
      flex: 1,
      height: theme.spacing.unit * 8,
    },
  });

class Navigation extends React.Component<Props> {
  static displayName = 'HeaderNavigation';

  private handleTabChange = (_event, value) => {
    // workaround for material ui tabs and special behavior for tabs linking to external source
    if (value.includes('https')) return;
    this.props.history.push(value);
  };

  /**
   * Check if current location matches any of link.
   * If not don't hightlight any of the tabs.
   */
  private get notFound(): boolean {
    const { links } = this.props;
    return !links.map(l => l.href).find(l => l === location.pathname);
  }

  private onTabClick = (link: string, external: boolean) => (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const MIDDLE_BUTTON = 1;

    if (external || e.button === MIDDLE_BUTTON) {
      window.open(link, '_blank', 'noopener');
    }
  };

  private parseExternalLink = (links: string, lang: string) => {
    // hack, main nav links are a JSON config, so having multiple links for one entry required some workaround
    const parsedLinks = JSON.parse(links);

    // English acts as a fallback language
    return parsedLinks[lang] || parsedLinks['en'];
  };

  public render(): JSX.Element {
    const { t, i18n, classes, links } = this.props;
    const currentLanguage = i18n.language.split('-')[0];
    return (
      <Toolbar classes={{ root: classes.toolbar }}>
        <Tabs
          value={this.notFound ? false : location.pathname}
          onChange={this.handleTabChange}
          classes={{
            flexContainer: classes.tabs,
          }}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          {links.map((n, idx) => {
            const href = n.external
              ? this.parseExternalLink(n.href, currentLanguage)
              : n.href;

            return (
              <Tab
                key={idx}
                value={href}
                label={t(n.name)}
                onMouseUp={this.onTabClick(href, n.external)}
                data-testid="tab"
              />
            );
          })}
        </Tabs>
      </Toolbar>
    );
  }
}

const enhance = compose(withTranslation(), withRouter, withStyles(styles));

export default enhance(Navigation);
