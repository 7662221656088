import * as cs from 'classnames';
import { Social } from 'components/molecules';
import * as React from 'react';

interface GoogleProps {
  onGoogleSuccess?: any;
  onGoogleError?: any;
}

interface FacebookProps {
  onFacebookSuccess?: any;
  onFacebookError?: any;
}
interface AppleProps {
  onAppleSuccess?: any;
  onAppleError?: any;
}

interface StyledComponent {
  className?: string;
  styles?: React.CSSProperties;
}

interface Props extends GoogleProps, FacebookProps, StyledComponent {}

export class SocialButtons extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      className,
      onGoogleSuccess,
      onGoogleError,
      onFacebookSuccess,
      onFacebookError,
      onAppleSuccess,
      onAppleError,
    } = this.props;

    const container = cs({
      [className]: className,
    });

    return (
      <>
        <Social.Facebook
          onLogin={onFacebookSuccess}
          onError={onFacebookError}
        />
        <Social.Google onSuccess={onGoogleSuccess} onError={onGoogleError} />
        <Social.Apple onSuccess={onAppleSuccess} onError={onAppleError} />
      </>
    );
  }
}
