import { Computed, ComputedValue } from '@cerebral/fluent';
import { IArea, IEvent, IMeta, IPlant } from '@gardenize/models';

import * as getters from './getters';

type File = {
  name: string;
  loading: boolean;
};

export type State = {
  ui: {
    activeImage: number;
    lightbox: boolean;
    search: string;
    loading: {
      page: boolean;
      plants: boolean;
      events: boolean;
      unconnected: boolean;
    };
    files: File[];
    submitting: boolean;
    panel: boolean;
    dialog: {
      del: {
        image: boolean;
        entity: boolean;
      };
      upload: boolean;
      event: boolean;
    };
  edit: boolean;
  };
  data: {
    item: IArea | null;
    unconnected: {
      items: IPlant[];
      _meta: IMeta;
    };
    plants: {
      items: IPlant[];
      _meta: IMeta;
    };
    events: {
      items: IEvent[];
      _meta: IMeta;
    };
  };
  totalUnconnected: ComputedValue<number>;
  numOfunconnectedPages: ComputedValue<number>;
  totalConnectedPlants: ComputedValue<number>;
  totalConnectedEvents: ComputedValue<number>;
};

export const state: State = {
  ui: {
    activeImage: 0,
    lightbox: false,
    search: '',
    loading: {
      page: true,
      plants: false,
      events: false,
      unconnected: true,
    },
    files: [],
    submitting: false,
    panel: false,
    dialog: {
      del: {
        image: false,
        entity: false,
      },
      upload: false,
      event: false,
    },
  edit: false
  },
  data: {
    item: null,
    unconnected: {
      items: [],
      _meta: null,
    },
    plants: {
      items: [],
      _meta: null,
    },
    events: {
      items: [],
      _meta: null,
    },
  },
  totalUnconnected: Computed(getters.totalUnconnected),
  numOfunconnectedPages: Computed(getters.numOfunconnectedPages),
  totalConnectedPlants: Computed(getters.totalConnectedPlants),
  totalConnectedEvents: Computed(getters.totalConnectedEvents),
};
