import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import * as cs from 'classnames';
import { Button, Typography } from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { Link } from '../types';

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface InfoLinksProps extends StyledComponent {
  links: Link[];
}

const styles = (theme: Theme) =>
  createStyles({
    section: {
      marginLeft: theme.spacing.unit * 2.5,
    },
    button: {
      color: theme.palette.common.white,
      padding: `0 ${theme.spacing.unit}px`,
    },
  });

type Props = WithStyles<typeof styles> & WithTranslation & InfoLinksProps;

const InfoLinks = (props: Props): JSX.Element => {
  const { t, links, classes, className } = props;

  const root = cs(classes.section, {
    [className]: className,
  });

  return (
    <div className={root}>
      {links.map((page, index) => (
        <Button
          key={index}
          href={page.href}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.button}
        >
          {t(page.name)}
        </Button>
      ))}
    </div>
  );
};

const enhance = compose(
  withTranslation(),
  withStyles(styles),
);

export default enhance(InfoLinks);
