import { languages } from '@gardenize/config';
import { Provider } from 'cerebral';
import i18n from 'i18next';

interface IIntlService {
  changeLanguage: (lang: keyof languages) => void;
  language: () => string;
  on: (ev: string, cb) => void;
  trans: (key: string) => string;
}

const Service: IIntlService = {
  changeLanguage(lang: keyof languages): void {
    i18n.changeLanguage(lang as string);
  },

  language(): string {
    return i18n.language;
  },

  on(ev: string, cb): void {
    i18n.on(ev, cb);
  },

  trans(key: string): string {
    return i18n.t(key);
  },
};

export type Type = keyof IIntlService;
export default Provider(Service);
