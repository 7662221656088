import { StateContext } from '@app/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

const Auth = (Component: React.ComponentType) => {
  interface AuthProps {}
  type Props = StateContext & AuthProps;

  class Auth extends React.Component<Props> {
    static displayName = Component.displayName || Component.name || 'Auth';

    public render(): JSX.Element {
      const {
        store: { isLoggedIn },
      } = this.props;

      if (!isLoggedIn) {
        return (
          <Redirect
            to={`/login?redirectTo=${encodeURIComponent(
              window.location.pathname,
            )}`}
          />
        );
      }

      return <Component {...this.props} />;
    }
  }

  const enhance = compose(inject('store'), observer);

  return enhance(Auth);
};

export { Auth };
