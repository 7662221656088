import { languages } from '@gardenize/config';
const __DEV__ = process.env.NODE_ENV === 'development';

export const availableLanguages = languages;

export const availableLanguagesOptions: string[] = () => {
  return Object.entries(languages)
    .map(([lang, langName]) => {
      if (__DEV__) {
        return langName;
      } else {
        return langName !== 'dev' && langName;
      }
    })
    .filter(Boolean);
};

export const findLanguage = selectedLanguage => {
  const language = Object.entries(languages).find(
    ([_, langName]) => langName === selectedLanguage,
  );

  if (language) return language[0];

  return null;
};
