import { withFormik } from "formik";

import { IStore, Props, Values } from "./types";
import { schema } from "./validators";

const initial: Values = {
  source: "delete",
  title: '',
  message: "",
  email: ""
};

export const withDelete = withFormik<Props & IStore, Values>({
  validationSchema: schema,
  enableReinitialize: true,
  handleSubmit: (
    values: Values,
    { resetForm, setSubmitting, props: { onSubmit } }
  ) => {
    const data = {
      source: String(values.source),
      title: String(values.title),
      message: String(values.message),
      email: String(values.email)
    };

    onSubmit({ data });
    setSubmitting(false);
    resetForm();
  },
  mapPropsToValues: (props) => {
    const { user, subject } = props;

    if (user && user.email) {
      return { ...initial, email: user.email, title: subject }
    }
    return { ...initial, title: subject };
  }
});
