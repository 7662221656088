import { facebook as FACEBOOK } from '@gardenize/config';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Button } from 'components/atoms';
import Facebook from 'mdi-material-ui/Facebook';
import * as React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const styles = (theme: Theme) => {
  const {
    breakpoints: { down, up, values },
    spacing: { unit },
  } = theme;

  return createStyles({
    button: {
      width: '100%',
      height: 56,
      cursor: 'pointer',
      color: theme.palette.text.primary,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '15px',
      textAlign: 'center',
      textTransform: 'uppercase',
      marginBottom: 10,
      backgroundColor: theme.palette.common.white,
    },
  });
};

interface StyledComponent {
  className?: string;
  style?: React.CSSProperties;
}

interface FacebookButtonProps {
  /** successful login callback */
  onLogin: (args: any[]) => void;

  /** un-successful login callback */
  onError: (args: any[]) => void;
}

type Props = WithStyles<typeof styles> & FacebookButtonProps;

class FacebookButton extends React.Component<Props> {
  private button = (props): JSX.Element => {
    const { classes } = this.props;

    return <FBButton className={classes.button} {...props} />;
  };

  public render(): JSX.Element {
    const { onLogin } = this.props;

    return (
      <FacebookLogin
        appId={FACEBOOK.ID}
        autoload={true}
        fields={FACEBOOK.FIELDS}
        callback={onLogin}
        render={this.button}
      />
    );
  }
}

interface FBButtonProps extends StyledComponent {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const FBButton: React.SFC<FBButtonProps> = ({ onClick, className, style }) => {
  return (
    <Button
      variant="outlined"
      style={style}
      classes={{ root: className }}
      onClick={onClick}
      data-testid="facebook-btn"
    >
      <Facebook style={{ marginRight: 8, color: FACEBOOK.COLOR }} />
      Facebook
    </Button>
  );
};

export default withStyles(styles)(FacebookButton);
