import { Area } from '@gardenize/models';
import { types } from 'mobx-state-tree';

export default {
  ui: types.model({
    uploading: types.boolean,
    loading: types.boolean,
    searching: types.boolean,
    mode: types.number,
  }),
  data: types.model({
    total: types.number,
    items: types.optional(types.map(types.array(Area)), {}),
    featured: types.optional(types.array(Area), []),
  }),
  search: types.model({
    items: types.optional(types.array(Area), []),
  }),
};
