import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import * as cs from 'classnames';
import { ListItem, Typography } from 'components/atoms';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const PRIMARY_RGB = `57,180,74`;

const styles = (theme: Theme) =>
  createStyles({
    fallback: {
      height: 100,
    },
    fallbackText: {
      width: '100%',
    },
  });

interface NoNotifications {}

type Props = WithStyles<typeof styles> & WithTranslation & NoNotifications;

class NoNotifications extends React.Component<Props> {
  public render(): JSX.Element {
    const { t, classes } = this.props;

    return (
      <ListItem
        alignItems="center"
        divider={true}
        classes={{ root: classes.fallback }}
      >
        <Typography align="center" classes={{ root: classes.fallbackText }}>
          {t('no_new_notifications')}
        </Typography>
      </ListItem>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(styles),
);

export default enhance(NoNotifications);
