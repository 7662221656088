type Page = number;
type Index = number;

enum ViewMode {
  LIST,
  GRID
}

type Pred = (page: Page) => boolean;
type Input = { start: number; stop: number };

const PAGE = 20;
const GRID_PAGE = 4;
const FEATURED_OFFSET = 5;

const page = (index: Index): Page => {
  return ~~(index / 20);
};

const index = (page: Page, index: Index): Index => {
  return index - page * 20;
};

export const mapper = (mode: ViewMode) => {
  const roundDown = (val: number) => ~~val;

  // NOTE(zvrk): /api/${entity}/grid is not zero based
  // (0th and 1st page return same results). We have to
  // adapt.
  const addOne = (val: number) => val + 1;

  const paginate = index =>
    mode === ViewMode.GRID
      ? addOne(roundDown((index * GRID_PAGE - 1) / PAGE))
      : addOne(roundDown((index - 1) / PAGE));

  const page = (exist: Pred, { start, stop }: Input): Page => {
    const startPage = paginate(start);

    return exist(startPage) ? startPage : paginate(stop);
  };

  return {
    page
  };
};
