import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Arrow } from 'components/atoms';
import * as React from 'react';

import ControllerButton from './button';

const styles = (theme: Theme) =>
  createStyles({
    arrow: {
      right: 0,
      position: 'absolute',
      top: -theme.spacing.unit,
    },
  });

interface AutoselectButton {
  open?: boolean;
}
type Props = WithStyles<typeof styles> & AutoselectButton;

const AutoselectButton: React.SFC<Props> = ({
  open,
  children,
  classes,
  ...rest
}) => {
  return (
    <ControllerButton {...rest}>
      <Arrow isOpen={open} className={classes.arrow} />
    </ControllerButton>
  );
};

export default withStyles(styles)(AutoselectButton);
